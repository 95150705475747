import React from 'react'
import { Switch } from 'antd'
import { AppState } from 'common/models'
import { useDispatch, useSelector } from 'react-redux'
import setDnsSec from './actions/setDnsSec'

interface Props {
    zone: string
    serviceId: number
    disabled: boolean
}

const DnsZoneSecSwitch = (props: Props) => {
    const dispatch = useDispatch()
    const {dnsZone} = useSelector((state: AppState) => state.dnsservice)

    const onDnsSecChange = (checked: boolean, e) => {
        e.stopPropagation()
        dispatch(
            setDnsSec({
                service_id: props.serviceId,
                domain: props.zone,
                dnssec: checked ? 1 : 0,
            })
        )
    }

    return (
        <Switch size="small"
            className='dnsSecSwitch'
            checkedChildren='ON'
            unCheckedChildren='OFF'
            defaultChecked={dnsZone && dnsZone.dnssec}
            onClick={onDnsSecChange}
                disabled={props.disabled}
        />
    )
}

export default DnsZoneSecSwitch
