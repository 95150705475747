import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {Card, Form, Input, Button, message, Row, Col, Radio, Tag, InputRef, Result} from 'antd'
import {
	UserOutlined,
	LockOutlined,
	LoginOutlined,
	CheckCircleOutlined,
	EyeTwoTone,
	EyeInvisibleOutlined, UnlockOutlined, MailOutlined
} from '@ant-design/icons'
import {Store} from 'antd/lib/form/interface'
import {useTranslation} from 'react-i18next'
import {AppState} from 'common/models'
import userLogin from 'pages/login/actions/login'
import {LoadingIndicator} from 'components'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import './LoginPage.scss'
import {StaticContext, RouteComponentProps, useHistory} from 'react-router'
import getSelf from "./actions/getSelf";
import getMenuRoles from "./actions/getMenuRoles";
import getCustomerSelf from "./actions/getCustomerSelf";
import getSettingsSelf from "./actions/getSettingsSelf";
import getCountries from "../../components/SelectCountries/actions/getCountries";
import getCurrencies from "../../components/SelectCurrencies/actions/getCurrencies";
import getAllUsers from "../user/actions/getAllUsers";
import LoggedUser, {
	ROLE_ACCESS_MANAGER, ROLE_BILLING_EDITOR, ROLE_BILLING_VIEWER,
	ROLE_COMPANY_MANAGER,
	ROLE_CUSTOMER_MANAGER,
	ROLE_CUSTOMER_SERVICE_EDITOR, ROLE_USER_MANAGER, ROLE_ZCOM_USER_MANAGER
} from "../../common/LoggedUser";
import getServiceTypes from "../billing/servicetype/actions/getServiceTypes";
import getProducts from "../billing/product/actions/getProducts";
import getTaxes from "../billing/tax/actions/getTaxes";
import logout from "./actions/logout";
import SendConfirm from "./actions/sendConfirm";
import AuthConfirm from "./actions/authConfirm";
import {_validatePhone} from "../../common/fce";
import {Method2FA} from "./models";
import setPass from "./actions/setPassword";
import {passwordRequest} from "../user/actions/passwordRequest";
import tableCompanies from "./actions/tableCompanies";
import tableCustomers from "./actions/tableCustomers";


type Props = RouteComponentProps<{}, StaticContext, {from: string}>
const LoginStates = {
	LOGIN_PAGE: 0,
	CHOOSE_METHOD: 1,
	SEND_SMS: 2,
	CONFIRM_CODE: 3,
	LOADING_APP: 4,
	SET_IDENT: 5,
	SET_PASS: 6,
	REQUEST_PASS: 7,
	SUCCESS: 8
}

export const AUTH_GA_NAME = 'google_authenticator'
export const AUTH_SMS_NAME = 'sms'

const LoginPage = (props: Props) => {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const phoneRef: React.Ref<InputRef> = React.createRef()
	const codeRef: React.Ref<InputRef> = React.createRef()

	const [username, setUsername] = useState<string>()
	const [password, setPassword] = useState<string>()
	const {auth} = useSelector((state: AppState) => state)
	const {authenticated, token, reason, status, methods, message: auth_message, self, self_customer, menu_roles, self_settings, isLoading} = useSelector((state: AppState) => state.auth)
	const {companies, customers} = useSelector((state: AppState) => state.auth.tables)
	const {users} = useSelector((state: AppState) => state.user)
	const {fontSize} = useSelector((state: AppState) => state.font)
	const {countries} = useSelector((state: AppState) => state.countries)
	const {currencies} = useSelector((state: AppState) => state.currencies)
	const {products} = useSelector((state: AppState) => state.product)
	const {taxes} = useSelector((state: AppState) => state.tax)
	const {servicetypes} = useSelector((state: AppState) => state.servicetype)
	const location = props.location.state

	const [pageNum, setPageNum] = useState<number>(0)
	const [optionsAllAuthentications, setOptionsAllAuthentications] = useState<{ label: string | ReactNode, value: string }[]>([])
	const [selectedAuthKey, setSelectedAuthKey] = useState<string | undefined>('')
	const [selectedMethod, setSelectedMethod] = useState<string | undefined>('')
	const [isIdentVerified, setIdentVerified] = useState<boolean>(true)
	const [isStarted, setStarted] = useState<boolean>(false)

	const [image2FA, setImage2FA] = useState<string | undefined>('')

	const [code, setCode] = useState<string | undefined>('')
	const [phone, setPhone] = useState<string | undefined>('')
	const [isSuccess, setIsSuccess] = useState<boolean>(false)
	const [sentCode, setSentCode] = useState<boolean>(false)
	const [passRequestSent, setPassRequestSent] = useState<boolean>(false)
	const [isResetPassCalled, setResetPassCalled] = useState<boolean>(false)

	const [serverMessage, setServerMessage] = useState<string>('')
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [successMessage, setSuccessMessage] = useState<string>('')
	const [requestId, setRequestId] = useState<string>('')

	const [callStateDict, setCallStateDict] = useState<{ [key: string]: number }>({})
	//const callStateDict: { [key: string]: number } = {};
	const [uniqueRefTables, setUniqueRefTables] = useState<string[]>([])
	const isMounted = useRef(true)

	// logger
	const debug = true
	const logger = (msg) => { if (debug) {console.log('LoginPage: ' + msg)} }

	useEffect(() => {
		// Set isMounted to true when the component is mounted
		isMounted.current = true;

		// Cleanup function to set isMounted to false when the component unmounts
		return () => { isMounted.current = false; };
	}, [])

	useEffect(() => {
		// check auth_2fa
		logger('useEffect[]: reason = '+reason)
		switch (reason) {
			case 'auth_2fa':
				if (!handle2faMethodsOrExit()) {
					// exit
					return
				}
				return
			case 'set-password':
				logger('methods: '+ JSON.stringify(methods))
				if (!methods || methods.length === 0) {
					console.error('set-password - missing methods x')
					message.error(t('Force2FA.missing_methods'))
					transitionTo(LoginStates.LOGIN_PAGE)
					return
				}
				const method = methods[0]
				setRequestId(method.request)
				transitionTo(LoginStates.SET_PASS)
				return
			case 'reset-password':
				logger('methods: '+ JSON.stringify(methods))
				if (!methods || methods.length === 0) {
					console.error('reset-password - missing methods x')
					message.error(t('Force2FA.missing_methods'))
					transitionTo(LoginStates.LOGIN_PAGE)
					return
				}
				const m = methods[0]
				if (m.method === AUTH_SMS_NAME) {
					console.error('2FA - sms')
					setRequestId(m.request)
					handle2faMethodsOrExit()
					return
				}
				setRequestId(m.request)
				transitionTo(LoginStates.SET_PASS)
				return
			case 'change-password':
				if (!methods || methods.length === 0) {
					console.error('change-password - missing methods x')
					message.error(t('Force2FA.missing_methods'))
					transitionTo(LoginStates.LOGIN_PAGE)
					return
				}

				setSelectedAuthKey(undefined)
				if (!handle2faMethodsOrExit()) {
					// exit
					return
				}

				return
			default:
				transitionTo(LoginStates.LOGIN_PAGE)

		}

	}, [status, reason, methods])

	const handle2faMethodsOrExit = (): boolean => {
		logger('handle2faMethodsOrExit..')
		setRequestId('')
		setSelectedAuthKey('')
		setPhone('')
		setOptionsAllAuthentications(methods.map(a2 => ({label: t('Force2FA.method_'+a2.method), value: a2.request})))
		setServerMessage('')
		if (methods && methods.length === 0) {
			// error - missing methods
			console.error('2FA - missing methods')
			message.error(t('Force2FA.missing_methods'))
			transitionTo(LoginStates.LOGIN_PAGE)
			return false
		}
		if (methods && methods.length === 1) {
			const method = methods[0]
			setSelectedAuthKey(method.request)

			if (!method.is_ident_verified) {
				// ident not verified => set ident
				transitionTo(LoginStates.SET_IDENT)
				return false
			}
			if (method.is_ident_verified === 1) {
				// ident verified => 2FA auth => confirm
				if (method.method === AUTH_SMS_NAME) {
					// ident verified => 2FA auth => confirm
					transitionTo(LoginStates.SEND_SMS)
				}
				else {
					transitionTo(LoginStates.CONFIRM_CODE)
				}
			}
			if (selectedMethod != method.method) {
				setSelectedMethod(method.method)
			}
		}
		if (methods && methods.length > 1) {
			let isVerified = false
			methods.forEach(m => {
				if (m.is_ident_verified === 1) {
					isVerified = true
				}
			})
			setIdentVerified(isVerified)
			if (!selectedAuthKey) {
				transitionTo(LoginStates.CHOOSE_METHOD)
				return false
			}
			if (reason === 'change-password') {
				transitionTo(LoginStates.CHOOSE_METHOD)
				return false
			}
			logger('selectedAuthKey..'+selectedAuthKey)
			const method = get2faMethod(selectedAuthKey)
			if (!method) {
				console.error('2FA - method not found:'+selectedAuthKey)
				message.error('2FA - method not found')
				transitionTo(LoginStates.LOGIN_PAGE)
				return false
			}
			if (!method.is_ident_verified) {
				// ident not verified => set ident
				transitionTo(LoginStates.SET_IDENT)
				return false
			}
			if (method.is_ident_verified === 1) {
				// ident verified => 2FA auth => confirm
				if (method.method === AUTH_SMS_NAME) {
					// ident verified => 2FA auth => confirm
					transitionTo(LoginStates.SEND_SMS)
				}
				else {
					transitionTo(LoginStates.CONFIRM_CODE)
				}
			}
			if (selectedMethod != method.method) {
				setSelectedMethod(method.method)
			}
		}
		return  true
	}

	useEffect(() => {
		// continue in logging process
		// load all necessary data here
		//
		// Necessary data are:
		// - authenticated
		// - token
		// - self (user)
		// - self_customer
		// - self_settings
		// - menu_roles
		//
		// Only when they are all loaded we redirect to app route /
		// object LoggedUser cannot be used on PublicRoutes
		if (!authenticated || !token) {
			// not ready
			return
		}

		// we are authenticated
		// load data
		logger('dispatch: getSelf: ')
		dispatch(getSelf('validCustomers,roles,resourceGroups,groups,userAuthentications,authentications', suc => {
			if (!suc) {
				message.error('Permission error.')
				dispatch(logout())
			}
		}))
		dispatch(getCustomerSelf('company'))
		dispatch(getSettingsSelf())
		dispatch(getMenuRoles())
		transitionTo(LoginStates.LOADING_APP)
	}, [authenticated, token])

	useEffect(() => {
		if (!authenticated || !token || !self || !self_customer || !self_settings) {
			// not ready
			return
		}

		logger('authenticated: '+authenticated)
		if (self && self.id > 0 && !isStarted) {
			setStarted(true)
			const loggedUser = new LoggedUser(auth)

			// loading ref.tables
			dispatch(getCountries())
			dispatch(getCurrencies())

			let refTables:string[] = []
			if (loggedUser.hasRole(ROLE_COMPANY_MANAGER)) {
				refTables.push('tableCompanies')
			}
			if (loggedUser.hasRole(ROLE_CUSTOMER_MANAGER) ||
				loggedUser.hasRole(ROLE_CUSTOMER_SERVICE_EDITOR) ||
				loggedUser.hasRole(ROLE_USER_MANAGER) ||
				loggedUser.hasRole(ROLE_ZCOM_USER_MANAGER) ||
				loggedUser.hasRole(ROLE_ACCESS_MANAGER)) {
				refTables.push('tableCompanies')
				refTables.push('tableCustomers')
				refTables.push('getAllUsers')
			}

			if (loggedUser.hasRole(ROLE_BILLING_VIEWER) ||
				loggedUser.hasRole(ROLE_BILLING_EDITOR)) {
				// load Billing
				refTables.push('tableCompanies')
				refTables.push('tableCustomers')
				refTables.push('getServiceTypes')
				refTables.push('getProducts')
				refTables.push('getTaxes')
			}

			// make calls unique
			// and call them
			const uniqRefTables = Array.from(new Set(refTables));
			setUniqueRefTables(uniqRefTables)
			for (const key of uniqRefTables) {
				logger('callStateDict: key='+key )
				let x = callStateDict
				x[key] = 1
				setCallStateDict(x)
				switch (key) {
					case 'tableCompanies':
						dispatch(tableCompanies(suc => {
							if (isMounted.current && suc) {
								let y = callStateDict
								y[key] = 2
								setCallStateDict(y)
							}
						}))
						break
					case 'tableCustomers':
						dispatch(tableCustomers(suc => {
							if (isMounted.current && suc) {
								let y = callStateDict
								y[key] = 2
								setCallStateDict(y)
							}
						}))
						break
					case 'getServiceTypes':
						dispatch(getServiceTypes(suc => {
							if (isMounted.current && suc) {
								let y = callStateDict
								y[key] = 2
								setCallStateDict(y)
							}
						}))
						break
					case 'getProducts':
						dispatch(getProducts(suc => {
							if (isMounted.current && suc) {
								let y = callStateDict
								y[key] = 2
								setCallStateDict(y)
							}
						}))
						break
					case 'getTaxes':
						dispatch(getTaxes(suc => {
							if (isMounted.current && suc) {
								let y = callStateDict
								y[key] = 2
								setCallStateDict(y)
							}
						}))
						break
					case 'getAllUsers':
						dispatch(getAllUsers(suc => {
							if (isMounted.current && suc) {
								let y = callStateDict
								y[key] = 2
								setCallStateDict(y)
							}
						}))
						break
				}
			}
			logger('callStateDict: '+JSON.stringify(callStateDict) )

		}
		else{
			logger('self:: '+JSON.stringify(self) )
		}
	}, [authenticated, token, self, menu_roles])

	useEffect(() => {
		if (!authenticated || !token || !self || !self_customer || !self_settings) {
			// not ready
			return
		}
		if (!countries || !countries.length) {
			return
		}
		if (!currencies || !currencies.length) {
			return
		}
		for (const key of uniqueRefTables) {
			if (callStateDict[key] < 2) {
				logger('waiting for '+key )
				logger('callStateDict: '+JSON.stringify(callStateDict) )
				return
			}
		}

		// start application
		logger('--- start application.')
		logger('callStateDict: '+JSON.stringify(callStateDict) )
		if (location && location['from'] !== '/logout') {
			logger('redirect '+location['from'])
			history.replace(location['from'])
		}
		else {
			logger('redirect /')
			history.replace('/')
		}
	}, [countries, currencies, companies, customers, users, products, taxes, servicetypes ])

	useEffect(() => {
		if (companies && companies.length) {
			let y = callStateDict
			y['tableCompanies'] = 2
			setCallStateDict(y)
			logger('companies callStateDict: '+JSON.stringify(y) )
			for (const key of uniqueRefTables) {
				if (callStateDict[key] < 2) {
					logger('waiting for '+key )
					logger('callStateDict: '+JSON.stringify(callStateDict) )
					return
				}
			}

			// start application
			logger('--- start application.')
			logger('callStateDict: '+JSON.stringify(callStateDict) )
			if (location && location['from'] !== '/logout') {
				logger('redirect '+location['from'])
				history.replace(location['from'])
			}
			else {
				logger('redirect /')
				history.replace('/')
			}
		}
	}, [companies])

	useEffect(() => {
		if (pageNum > 0) {
			logger('useEffect[]: pageNum = '+pageNum)
			if (phoneRef.current) {
				phoneRef.current.focus();
			}
			if (codeRef.current) {
				codeRef.current.focus();
			}
		}
	}, [pageNum])

	useEffect(() => {
		if (selectedAuthKey && selectedAuthKey.length) {
			const name = methods.find(m => m.request === selectedAuthKey)?.method
			setSelectedMethod(name)
		}
		else {
			setSelectedMethod('')
		}
	}, [selectedAuthKey])

	useEffect(() => {
		if (selectedMethod && selectedAuthKey) {
			handleSelectedMethod()
		}
	}, [selectedMethod])

	useEffect(() => {
		auth_message && setServerMessage(auth_message)
	}, [auth_message])

	// ------------------

	const handleLogin = (values: Store): void => {
		// call API login
		// fetch token and set authenticated=true
		const params = {
			username: values.username,
			password: values.password
		}
		dispatch(userLogin(params))
	}

	const handleChangePass = (values: Store): void => {
		// call API /change-password
		const params = {
			request: requestId,
			password: values.password,
			password_repeat: values.password_repeat
		}
		dispatch(setPass(params, suc => {
			if (suc) {
				setResetPassCalled(suc)
				message.success(t('ForceSetPassword.passwordSet'))
			}
		}))
	}

	const handleRequestPassword = (values) => {
		// request for password reset link (to mail)
		const email = values.email
		passwordRequest({ email: email }, suc => {
			if (suc) {
				message.success(t('changePasswordPage.changed'))
				setPassRequestSent(true)
			}
			else {
				message.error('Password request error')
			}
		})
	}

	const get2faMethod = (req: string): Method2FA | undefined => {
		return methods.find(m => m.request === req)
	}

	const transitionTo = (page: number) => {
		logger(page)
		if (page === LoginStates.CHOOSE_METHOD) {
			setSelectedAuthKey('')
			setCode('')
			setPhone('')
			setIsSuccess(false)
			let isVerified = false
			methods.forEach(m => {
				if (m.is_ident_verified === 1) {
					isVerified = true
				}
			})
			setIdentVerified(isVerified)
		}
		setPageNum(page)
	}

	const handleSelectedMethod = () => {
		setCode('')
		if (selectedMethod && selectedAuthKey) {
			const method = get2faMethod(selectedAuthKey)
			if (!method) {
				console.error('2FA - method not found: '+selectedAuthKey)
				transitionTo(LoginStates.LOGIN_PAGE)
				return
			}
			if (!method.is_ident_verified) {
				// ident not verified => set ident
				setIdentVerified(false)
				if (method.method === AUTH_GA_NAME) {
					setImage2FA(method.image)
				}
				transitionTo(LoginStates.SET_IDENT)
				return
			}
			if (method.is_ident_verified === 1) {
				setIdentVerified(true)
				// ident verified => 2FA auth => confirm
				if (method.method === AUTH_SMS_NAME) {
					// ident verified => 2FA auth => confirm
					transitionTo(LoginStates.SEND_SMS)
				}
				else {
					transitionTo(LoginStates.CONFIRM_CODE)
				}
			}
		}
	}

	const handleSendCode = () => {
		if (selectedAuthKey) {
			const params = {request: selectedAuthKey, ident: phone}
			dispatch(SendConfirm(params, suc => {
				if (suc) {
					setSentCode(true)
					transitionTo(LoginStates.CONFIRM_CODE)
				}
			}))
		}
	}

	const handleConfirmCode = () => {
		if (code && selectedAuthKey) {
			const params = {request: selectedAuthKey, confirm: code}
			dispatch(AuthConfirm(params, suc => {
				if (suc) {
					setSelectedAuthKey('')
					clear()
					setIsSuccess(true)
					setSuccessMessage(t('Force2FA.code_is_correct'))
					if (isResetPassCalled) {
						message.success(t('ForceSetPassword.passwordSet'))
						transitionTo(LoginStates.LOGIN_PAGE)
					}
					else {
						message.success(t('general.success'))
					}

				}
			}))
		}
	}

	const clear = () => {
		setServerMessage('')
		setCode('')
		setErrorMessage('')
	}

	const onCodeChange = (v: string) => {
		setCode(v)
	}

	const onPhoneChange = (v: string) => {
		setPhone(v)
	}

	const phoneIsValid = (): boolean => {
		if (!phone) {
			return false
		}
		return _validatePhone(phone)
	}

	const showMsg = () => {
		let s = serverMessage.replaceAll('<strong>', '').replaceAll('</strong>', '')
		s = s.replace('DEBUG', '')
		const arr = s.split('DEBUG')
		return (<span>{arr[0]}<br/>{arr[1]}</span>)
	}


	// ------------------

	const renderLoadingPage = (loading_msg: string = '') => {
		return (
			<Card className='LoginPage' style={{height: '500px'}}>
				<Row>
					<Col span={24} style={{textAlign: 'center', padding: '50px'}}>
						{loading_msg}
					</Col>
				</Row>
				<Row>
					<Col span={24} className='loading-app' style={{textAlign: 'center', padding: '50px'}}>
						{
							authenticated && (
								<div className="progress-container">
									<div className="progress-header">ZIS 3</div>

									<div className="progress-header" style={{fontSize:'0.9em', fontWeight: 'normal'}}>version {
										window.location.hostname != 'localhost' &&
										document.querySelector('meta[name="version"]')?.getAttribute('content')
									}</div>
									<div className="progress-bar">
										<div className="progress-bar-value"></div>
									</div>
									<div className="progress-text">{t('loginPage.app_is_loading')}</div>
								</div>

							)
						}
						{
							!authenticated && (
								<LoadingIndicator background />
							)
						}
					</Col>
				</Row>
			</Card>
		)
	}

	const renderLoginPage = (error_msg: string = '') => {
		// noinspection TypeScriptValidateTypes
		return (
			<Card title={
				<>
					<LoginOutlined /> {t('loginPage.sign_in')}
				</>
			}
				  className='LoginPage'>
				<Form
					name='login'
					className='login-form'
					initialValues={{remember: true}}
					onFinish={handleLogin}
					{...formItemLayout}
					noValidate>
					<div className='two-columns'>
						<Form.Item
							name='username'
							label={t('loginPage.username')}
							rules={[{required: true, message: t('loginPage.err_username')}]}>
							<Input
								prefix={<UserOutlined className='site-form-item-icon' />}
								placeholder={t('loginPage.username')}
								value={username}
								size={fontSize}
								onChange={(event: any) => {
									setUsername(event.currentTarget.value)
								}}
								autoComplete='off'
							/>
						</Form.Item>
						<Form.Item
							name='password'
							label={t('loginPage.pass')}
							rules={[{required: true, message: t('loginPage.err_password')}]}>
							<Input
								prefix={<LockOutlined className='site-form-item-icon' />}
								type='password'
								placeholder={t('loginPage.pass')}
								value={password}
								size={fontSize}
								onChange={(e) => {
									setPassword(e.currentTarget.value)
								}}
							/>
						</Form.Item>
						<Form.Item {...tailLayout}>
							<div className='LoginPage_action-line'>
								<Button
									type='primary'
									loading={isLoading}
									htmlType='submit'
									className='login-form-button'
									size={fontSize}>
									{t('loginPage.login')}
								</Button>
								<Link to='/password-request' style={{display: 'flex', alignItems: 'center'}}>
									{t('loginPage.forgot')}
								</Link>
							</div>
						</Form.Item>
					</div>
				</Form>
			</Card>
		)
	}

	const renderRequestPass = () => {
		// noinspection TypeScriptValidateTypes
		return (
			<Card
				title={ <><UnlockOutlined /> { t('resetPasswordPage.reset_password_request') }</> }
				style={ { width: 500 } }
			>
				<Form
					name='reset_password'
					className='login-form'
					initialValues={ { remember: true } }
					onFinish={ handleRequestPassword }
					{ ...formItemLayout }
					noValidate
				>
					<Form.Item
						name='email'
						label='E-mail'
						rules={ [
							{ required: true, message: t('resetPasswordPage.err_empty') },
							{ type: 'email', message: t('resetPasswordPage.err_invalid_email') }
						] }
					>
						<Input
							type='email'
							size={ fontSize }
							prefix={ <MailOutlined className='site-form-item-icon' /> }
							placeholder={ t('resetPasswordPage.email') }
						/>
					</Form.Item>

					<Form.Item { ...tailLayout }>
						<Button
							type='primary'
							htmlType='submit'
							className='login-form-button'
							size={ fontSize }
						>
							{ t('resetPasswordPage.reset') }
						</Button>
					</Form.Item>
				</Form>
			</Card>
		)
	}

	const renderSetPass = () => {
		// noinspection TypeScriptValidateTypes
		return (
			<Card title={
				<>
					<LoginOutlined/> {t('loginPage.new_password_text')}
				</>
			}
				  className='LoginPage'>
				<Row style={{marginTop: '35px'}}>
					<Col span={24}>
						<Form name='change_password'
							  className='change-password-form'
							  onFinish={handleChangePass}
							  {...formItemLayout}
						>

							<Form.Item name='password' label={t('changePasswordPage.new_password')}
									   rules={[
										   {required: true, message: t('changePasswordPage.err_new_password')},
										   {min: 5, message: t('changePasswordPage.err_password_length', {count: 5})},
										   // { pattern: mediumRegex, message: t('changePasswordPage.err_password_mediumRegex') }
									   ]}
							>
								<Input.Password
									autoComplete="off"
									data-gramm="false"
									data-gramm_editor="false"
									data-enable-grammarly="false"
									style={{width: '240px'}}
									iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									prefix={<LockOutlined className='site-form-item-icon'/>}
									size={fontSize}/>
							</Form.Item>

							<Form.Item name='password_repeat' label={t('changePasswordPage.repeat_new_password')}
									   rules={[
										   {required: true, message: t('changePasswordPage.err_repeat_password'),},
										   ({getFieldValue}) => ({
											   validator(rule, value) {
												   if (!value || getFieldValue('password') === value) {
													   return Promise.resolve()
												   }
												   return Promise.reject(t('changePasswordPage.err_repeat_not_match'))
											   },
										   }),
									   ]}
							>
								<Input.Password
									autoComplete="off"
									data-gramm="false"
									data-gramm_editor="false"
									data-enable-grammarly="false"
									style={{width: '240px'}}
									iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									prefix={<LockOutlined className='site-form-item-icon'/>}
									size={fontSize}/>
							</Form.Item>

							<Row style={{margin: '8px'}}>
								<Col span={8} style={{textAlign: 'left'}}>
									<Button type='link'
											onClick={() => {transitionTo(LoginStates.LOGIN_PAGE)}}
											size={fontSize}>
										{t('loginPage.login')}
									</Button>
								</Col>

								<Col span={16}>
									<Button type='primary' htmlType='submit'
											className='login-form-button' size={fontSize}>
										{t('general.set')}
									</Button>
								</Col>
							</Row>
							<Row>
								<Col span={24}>&nbsp;</Col>
							</Row>
						</Form>
					</Col>
				</Row>

			</Card>
		)
	}

	const renderChooseMethod = () => {
		return (
			<Card title={<><LoginOutlined/> {t('Force2FA.title')} </>} className='LoginPage'>
				<Row>
					<Col span={24} style={{textAlign: 'center', padding: '15px'}}>
						{
							!isIdentVerified && (
								<>
									<h3 style={{color: 'red'}}>{t('Force2FA.no_methods')}</h3><br/>
								</>
							)
						}

						<h3>{t('Force2FA.select_method_title')}</h3><br/>
						<h2>{t('Force2FA.select_method')}</h2><br/>
						{
							methods && methods.length > 1 && (
								<div style={{width: '320px', margin: '0 40%', textAlign: 'left'}}>
									<Radio.Group
										options={optionsAllAuthentications}
										onChange={(v) => {
											setSelectedAuthKey(v.target.value)
										}}
										className='select2fa'
										value={selectedAuthKey}
										style={{display: 'block'}}
									/>
								</div>
							)
						}
						{
							methods && methods.length === 1 && (<span>{methods[0].method}</span>)
						}
						{
							!methods || methods.length === 0 && (<Tag color='error'>{t('Force2FA.method_not_found')}</Tag>)
						}
					</Col>
					<Col span={24} style={{textAlign: 'center', padding: '15px'}}>
						<Button
							type='primary'
							loading={isLoading}
							onClick={() => {
								selectedAuthKey && handleSelectedMethod()
							}}
							className='login-form-button'
							style={{margin: '15px'}}
							size={fontSize}>
							{t('general.continue')}
						</Button>
					</Col>
				</Row>

				<Row style={{marginTop: '35px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>{errorMessage}&nbsp;</Col>
				</Row>
			</Card>
		)}

	const renderSendSms = () => {
		return (
			<Card title={<><LoginOutlined/> {t('Force2FA.title')} </>}
				  extra={(
					  <Button type='link' size='small'
							  style={{display: (methods && methods.length>1) ? 'block' : 'none'}}
							  onClick={() => {transitionTo(LoginStates.CHOOSE_METHOD)}}> {t('Force2FA.select_method_btn')} </Button>
				  )}
				  className='LoginPage'>

				<Row style={{marginTop: '35px'}}>
					<Col span={24}  style={{textAlign: 'center', color: '#cccccc'}}>
						{
							selectedMethod === AUTH_SMS_NAME && (
								<h2>{t('Force2FA.method_'+AUTH_SMS_NAME)}</h2>
							)
						}
						{
							selectedMethod === AUTH_GA_NAME && (
								<h2>{t('Force2FA.method_'+AUTH_GA_NAME)}</h2>
							)
						}
					</Col>
				</Row>
				<Row style={{marginTop: '25px'}}>
					<Col span={24} style={{textAlign: 'center'}}>
						{t('Force2FA.we_send_sms')}
					</Col>
				</Row>

				<Row style={{marginTop: '35px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>
						<Button
							type='primary'
							loading={isLoading}
							onClick={() => handleSendCode()}
							className='login-form-button'
							style={{margin: '15px'}}
							size={fontSize}>
							{t('Force2FA.send')}
						</Button>
						<div>
							{showMsg()}
						</div>
					</Col>
				</Row>

				<Row style={{marginTop: '35px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>{errorMessage}&nbsp;</Col>
				</Row>

			</Card>
		)
	}

	const renderConfirmCode = () => {
		return (
			<Card title={<><LoginOutlined/> {t('Force2FA.title')} </>}
				  extra={(
					  <Button type='link' size='small'
							  style={{display: (methods && methods.length>1) ? 'block' : 'none'}}
							  onClick={() => {transitionTo(LoginStates.CHOOSE_METHOD)}}> {t('Force2FA.select_method_btn')} </Button>
				  )}
				  className='LoginPage'>

				<Row>
					<Col span={24}  style={{textAlign: 'center', color: '#cccccc'}}>
						{
							selectedMethod === AUTH_SMS_NAME && (
								<h2>{t('Force2FA.method_'+AUTH_SMS_NAME)}</h2>
							)
						}
						{
							selectedMethod === AUTH_GA_NAME && (
								<h2>{t('Force2FA.method_'+AUTH_GA_NAME)}</h2>
							)
						}
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{textAlign: 'center', marginTop: '25px'}}>
						<h2>{t('Force2FA.sub_title')}</h2>
					</Col>
				</Row>

				<Row style={{marginTop: '25px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>
						<Input type='text' size='large' name='code'
							   ref={codeRef}
							   value={code}
							   defaultValue={code}
							   onChange={(el) => onCodeChange(el.target.value)}
							   onPressEnter={(e) => handleConfirmCode()}
							   autoComplete='off'
							   data-gramm="false"
							   style={{width: '120px'}} />
					</Col>
				</Row>

				<Row style={{marginTop: '25px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>
						<Button
							type='primary'
							loading={isLoading}
							onClick={() => handleConfirmCode()}
							disabled={!code || code.length < 5}
							className='login-form-button'
							style={{margin: '25px'}}
							size={fontSize}>
							{t('Force2FA.confirm')}
						</Button>
						<div>
							{
								!isSuccess && showMsg()
							}
						</div>
					</Col>
				</Row>

				<Row style={{marginTop: '35px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>{errorMessage}&nbsp;</Col>
				</Row>
			</Card>
		)
	}

	const renderSetIdent = () => {
		return (
			<Card title={<><LoginOutlined/> {t('Force2FA.title')}</>}
				  extra={(
					  <Button type='link' size='small'
							  style={{display: (methods && methods.length>1) ? 'block' : 'none'}}
							  onClick={() => {transitionTo(LoginStates.CHOOSE_METHOD)}}> {t('Force2FA.select_method_btn')} </Button>
				  )}
				  className='LoginPage'>

				<Row>
					<Col span={24}  style={{textAlign: 'center', color: '#cccccc'}}>
						{
							selectedMethod === AUTH_SMS_NAME && (
								<h2>{t('Force2FA.method_'+AUTH_SMS_NAME)}</h2>
							)
						}
						{
							selectedMethod === AUTH_GA_NAME && (
								<h2>{t('Force2FA.method_'+AUTH_GA_NAME)}</h2>
							)
						}
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{textAlign: 'center', marginTop: '25px'}}>
						{
							selectedMethod === AUTH_SMS_NAME && (
								<h3>{t('Force2FA.enter_phone')}</h3>
							)
						}
					</Col>
				</Row>

				{
					selectedMethod === AUTH_SMS_NAME && (
						<Row style={{marginTop: '25px'}}>
							<Col span={24}  style={{textAlign: 'center'}}>
								<Input type='text' size='large' name='phone'
									   ref={phoneRef}
									   value={phone}
									   onChange={(el) => onPhoneChange( el.target.value)}
									   onKeyPress={(e) => {
										   if (e.key === 'Enter') {if(phoneIsValid()){handleSendCode()}}
										   if (!/[0-9+]/.test(e.key)) {
											   e.preventDefault();
										   }
									   }}
									   autoComplete='off'
									   data-gramm="false"
									   style={{width: '160px'}} />
							</Col>
						</Row>
					)
				}

				{
					selectedMethod === AUTH_GA_NAME && (
						<Row>
							<Col span={10} style={{textAlign: 'center'}}>
								<br/><br/>
								{
									image2FA && (
										<img src={image2FA} alt="Google Authenticator" width={160} height={160} />
									)
								}
							</Col>
							<Col span={14}>
								<h2>{t('Force2FA.show_qr_rules')}</h2>
								<br/>
								<ol style={{textAlign: 'left'}}>
									<li>{t('Force2FA.show_qr_line1')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
										   target='_blank'>Android</a>&nbsp;|&nbsp;
										<a href='https://apps.apple.com/us/app/google-authenticator/id388497605'
										   target='_blank'>iOS</a>
									</li>
									<li>{t('Force2FA.show_qr_line2')}</li>
									<li>{t('Force2FA.show_qr_line3')}</li>
								</ol>
							</Col>

						</Row>
					)
				}


				<Row style={{marginTop: '25px'}}>
					{
						selectedMethod === AUTH_SMS_NAME && (
							<Col span={24}  style={{textAlign: 'center'}}>
								<Button
									type='primary'
									loading={isLoading}
									onClick={() => handleSendCode()}
									disabled={!phoneIsValid()}
									className='login-form-button'
									style={{margin: '25px'}}
									size={fontSize}>
									{t('Force2FA.send')}
								</Button>
								<div>
									{
										!isSuccess && showMsg()
									}
									{
										isSuccess && (
											<Tag icon={<CheckCircleOutlined />} style={{fontSize: '1.2em'}} color="success">{t('Force2FA.code_is_correct')}</Tag>
										)
									}
								</div>
							</Col>
						)
					}
					{
						selectedMethod === AUTH_GA_NAME && (
							<Col span={24} style={{textAlign: 'center'}}>
								<Button type='primary' onClick={() => {
									transitionTo(LoginStates.CONFIRM_CODE)
									// setModalVisible(false)
								}}>{t('Force2FA.done')}</Button>
							</Col>
						)
					}
				</Row>

				<Row style={{marginTop: '25px'}}>
					<Col span={24}  style={{textAlign: 'center'}}>{errorMessage}&nbsp;</Col>
				</Row>
			</Card>
		)
	}

	const renderSuccessPage = () => {
		return (
			<Card className='LoginPage' style={{height: '500px'}}>
				<Row>
					<Col span={24} style={{textAlign: 'center', padding: '50px'}}>
						<Result
							status="success"
							title={successMessage}
							subTitle=""
						/>
					</Col>
				</Row>
			</Card>
		)
	}


	if (isLoading) {
		logger('isLoading=true')
		return renderLoadingPage()
	}

	// render
	switch(pageNum) {
		case LoginStates.LOGIN_PAGE:
			return renderLoginPage()

		case LoginStates.CHOOSE_METHOD:
			return renderChooseMethod()

		case LoginStates.SEND_SMS:
			return renderSendSms()

		case LoginStates.CONFIRM_CODE:
			return renderConfirmCode()

		case LoginStates.LOADING_APP:
			return renderLoadingPage()

		case LoginStates.SET_IDENT:
			return renderSetIdent()

		case LoginStates.SET_PASS:
			return renderSetPass()

		case LoginStates.REQUEST_PASS:
			return renderRequestPass()

		case LoginStates.SUCCESS:
			return renderSuccessPage()

		default:
			return renderLoginPage()
	}
}

export default LoginPage
