import AppBar from './AppBar/AppBar'
import React, {useEffect, useState} from 'react'
import {Redirect, Route, useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import SideNav from './SideNav/SideNav'
import {Button, Drawer, Layout, Space} from 'antd'
import AppFooter from './AppFooter/AppFooter'
import './routeComponents.scss'
import BreadcrumbZis from './Breadcrumb/BreadcrumbZis'
import {useDispatch, useSelector} from 'react-redux'
import { AppState } from 'common/models'
import { useTranslation } from 'react-i18next'
import LoggedUser from "../common/LoggedUser";
import showRightSidebar from "../pages/login/actions/showRightSidebar";


const { Footer, Content } = Layout

export const PlainRoute = ({ component: Component, ...rest }: any) => {
  return <Route {...rest} render={props => <Component {...props} />} />
}


export const PrivateRoute = ({component: Component, neededRoles=[], ...rest}: any) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const {auth} = useSelector((state: AppState) => state)
    const {authenticated, self, self_customer, menu_roles, right_sidebar, docs_content} = useSelector((state: AppState) => state.auth)
    const [isAllowed, setIsAllowed] = useState<boolean>(false)

    const [open, setOpen] = useState(true)
    const [docUrl, setDocUrl] = useState('https://zisdev.zcom.cz/index.html')
    const [domainName, setDomainName] = useState('zisdev.zcom.cz')


    const debug = true
    const logger = (msg) => { if (debug) {console.log('PrivateRoute: ' + msg)} }

    useEffect(() => {
        if (authenticated && self && self_customer && menu_roles) {
            const loggedUser = new LoggedUser(auth)
            setIsAllowed(loggedUser.isAllowed(neededRoles))
        }
    },[authenticated, self, self_customer, menu_roles, neededRoles])

    useEffect(() => {
        logger('right_sidebar: '+JSON.stringify(right_sidebar))
        const url = history.location.pathname.toLowerCase()
        right_sidebar && setOpen(right_sidebar.show)
    },[right_sidebar])


    useEffect(() => {
        let path  = history.location.pathname.toLowerCase()
        let dn = window.location.hostname
        let domName = ''
        if (dn === 'localhost' || dn === 'zisdev.zcom.cz') {
            domName ='zisdev.zcom.cz'
        } else {
            domName = window.location.hostname
        }
        setDomainName(domName)
        if (path.includes('billing')) {
            setDocUrl(`https://${domName}/docs/billing.html`)
        }
        else {
            setDocUrl(`https://${domName}/docs/index.html`)
        }
    },[history.location.pathname])

    useEffect(() => {
        logger('docUrl: '+docUrl)
    },[docUrl])

    const onClose = () => {
        dispatch(showRightSidebar(false))
    };

  const getRender = (props) => {
    if (authenticated && self && self_customer && menu_roles) {
        if (isAllowed) {
            return (
                <Layout hasSider>
                    <SideNav />
                    <Layout >
                        <AppBar />
                        <Layout hasSider style={{ padding: '0  0 0 24px' }} className='Layout'>
                            <Layout>
                                <BreadcrumbZis />
                                <Content style={{ padding: '0 24px 24px 0' }}>
                                    <Component {...props} />
                                </Content>
                            </Layout>
                            <Drawer title={right_sidebar?.title}
                                placement='right'
                                width={600}
                                onClose={onClose}
                                visible={open}
                                extra={
                                    <Space>
                                        <Button type="primary" onClick={() => {setDocUrl(`https://${domainName}/docs/index.html?ts=`+Date.now())}}>HOME</Button>
                                    </Space>
                                }
                            >
                                <hr/>
                                <iframe src={docUrl} width="100%" height={'98%'} frameBorder={0} scrolling='auto' style={{borderBottom: 'solid 1px #ededed'}} />
                            </Drawer>
                        </Layout>
                        <Footer style={{ background: '#FFF' }}>
                            <AppFooter />
                        </Footer>
                    </Layout>
                </Layout>
            )
        }
        else {
            // not allowed
            // logger('not allowed '+props.location.pathname)
            return (
                <Layout hasSider>
                    <SideNav />
                    <Layout >
                        <AppBar />
                        <Layout style={{ padding: '0 24px 24px' }} className='Layout'>
                            <Content>
                                <h1>{t('errorPage.title')}</h1>
                                <h3>{t('errorPage.content')}</h3>
                            </Content>
                        </Layout>
                        <Footer style={{ background: '#FFF' }}>
                            <AppFooter />
                        </Footer>
                    </Layout>
                </Layout>
            )
        }
    }
    else {
        // not authenticated
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: props.location.pathname + window.location.search}
                }}
                push={true}
            />
        )
    }
  }

  return (<Route {...rest} render={(props) => {return getRender(props)}} />)
}

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout hasSider>
          <SideNav />
          <Layout >
            <AppBar />
            <Layout style={{ padding: '0 24px 24px' }} className='Layout'>
              <BreadcrumbZis />
              <Content>
                <Component {...props} />
              </Content>
            </Layout>
            <Footer style={{ background: '#FFF' }}>
              <AppFooter />
            </Footer>
          </Layout>
        </Layout>
      )}
    />
  )
}

