import React from 'react'
import {Button, Dropdown, Menu} from 'antd'
import {DownOutlined, HistoryOutlined} from '@ant-design/icons'
import {DnsZoneModel, DnsRecordModel} from 'pages/dns/service/models'
import {useSelector} from "react-redux";
import {AppState} from "../../../common/models";

interface Props {
    onSelect: (record: DnsRecordModel) => void
}

const DnsRecordArchivedDropdown = (props: Props) => {

    const {archived} = useSelector((state: AppState) => state.dnsservice)

    const items = archived && archived.records.map((rec, i) => (
        {
            label: (<span>{rec.name} {rec.type} {rec.content}</span>),
            key: i,
            disabled: rec.type === 'SOA',
            onClick: (e) => props.onSelect(rec)
        }
    ))

    const menu = (<Menu items={items} />)
// let items: MenuProps['items']  onClick={() => props.onSelect(rec)}
    return (
        <Dropdown overlay={menu}>
            <Button icon={<HistoryOutlined/>}>
                History:{' '}
                {archived ? archived.records.length : 0}{' '}
                <DownOutlined/>
            </Button>
        </Dropdown>
    )
}

export default DnsRecordArchivedDropdown
