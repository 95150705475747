import React, {ChangeEvent, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Button from 'antd-button-color'
import {Form, Input, InputNumber, message, Spin} from 'antd'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import DnsZoneArchivedDropdown from './DnsZoneArchivedDropdown'
import createDnsZone from 'pages/dns/service/actions/createDnsZone'
import {CreateDnsZoneParams, DnsServiceModel} from "./models";
import getArchivedZone from "./actions/getArchivedZone";
import {_isValidDomain, ValidateParams} from "../../../common/fce";
import recoverZone from "./actions/recoverZone";
import {useForm} from "antd/lib/form/Form";
import clearArchived from "./actions/clearArchived";
import getDnsZones from "./actions/getDnsZones";

interface Props {
	serviceId: number,
	setZoneModalVisible: (visible: boolean) => void
}

const {Item} = Form

const DnsZoneForm = (props: Props) => {
	const {t} = useTranslation()
	const [form1] = useForm()
	const dispatch = useDispatch()

	const {dnsServices, archived, dnsZones, isLoadingArchive} = useSelector((state: AppState) => state.dnsservice)
	const [domain, setDomain] = useState<string>('')
	const [selectedService, setSelectedService] = useState<DnsServiceModel>()
	const [domainField, setDomainField] = useState<ValidateParams>({value: '', validateStatus: 'success', errorMsg: ''})
	const [isFormValid, setFormValid] = useState<boolean>(false)

	useEffect(() => {
		setSelectedService(dnsServices.find(s => s.id === 1))
	}, [props.serviceId])

	useEffect(() => {
		console.log('selectedService: '+JSON.stringify(selectedService))
	}, [selectedService])

	useEffect(() => {
		console.log('archived: '+JSON.stringify(archived))
	}, [archived])


	const createZone = (params: CreateDnsZoneParams) => {
		dispatch(createDnsZone(params, (suc) => {
				if (suc) {
					dispatch(getDnsZones(params.service_id))
					message.success(t('dnsPage.zone_created'))
					props.setZoneModalVisible(false)
				}
			}),
		)
	}

	const createFromArchive = () => {
		if (archived && selectedService) {
			const baseParams = {service_id: selectedService.id, domain: archived.domain}
			dispatch(recoverZone(baseParams, (suc) => {
				if (suc) {
					dispatch(getDnsZones(selectedService.id))
					message.success(t('dnsPage.zone_created'))
					props.setZoneModalVisible(false)
				}
			}))
		}
	}

	const loadArchivedZone = () => {
		// load Archived zones
		let value = form1.getFieldValue('domain')
		console.log('loadArchivedZone: '+JSON.stringify(form1.getFieldsValue()))
		if (!value) {
			message.error('Enter a domain.')
			return
		}
		if (!checkDomain(value)) {
			message.error('Invalid domain.')
			return
		}
		if (selectedService) {
			if (domainExists(value)) {
				message.error('The domain already exists on '+selectedService.host)
				return
			}
			const params = {service_id: selectedService?.id, domain: value}
			dispatch(getArchivedZone(params))
		}
	}

	const domainExists = (dom: string) => {
		if (!dom.endsWith('.')) {
			dom = dom + '.'
		}
		const z = dnsZones.find( z => z.id === dom.toLowerCase())
		return z && z.id === dom.toLowerCase()
	}

	const checkDomain = (dom: string) => {
		console.log('checkDomain: '+dom)
		if (!dom) {
			setDomainField({
				value: dom,
				validateStatus: 'success',
				errorMsg: ''
			})
			setFormValid(false)
			return true
		}
		const result = _isValidDomain(dom)
		if (result) {
			dispatch(clearArchived())
			setDomainField({
				value: dom,
				validateStatus: 'success',
				errorMsg: ''
			})
			setFormValid(true)
			return true
		}
		else {
			console.log('checkDomain3: '+dom)
			setDomainField({
				value: dom,
				validateStatus: 'error',
				errorMsg: 'Invalid domain'
			})
			setFormValid(false)
			return false
		}
	}

	if (!selectedService) {
		return (<Spin/>)
	}

	return (
		<Form {...formItemLayout}
			  onFinish={createZone}
			  form={form1}
			  initialValues={{
				  service_id: Number(selectedService.id),
				  domain: '',
				  driver_name: selectedService.driver?.name + ' ' + selectedService.host
		}}>
			<Item name='driver_name' label={t('dnsPage.driver')} >
				<Input disabled />
			</Item>
			<Item name='service_id' style={{display: 'none'}}>
				<InputNumber type='hidden' />
			</Item>
			<Item name='domain' label={t('dnsPage.domain')}
				  validateStatus={domainField.validateStatus}
				  help={domainField.errorMsg}
				  hasFeedback>
				<Input onBlur={(e) => checkDomain(e.target.value)}
					   onChange={(e) => checkDomain(e.target.value)}  />
			</Item>

			<Item {...tailLayout}>
				<Button type='primary' onClick={() => loadArchivedZone()} style={{width: '160px'}} loading={isLoadingArchive}>
					{t('dnsPage.check_archived')}
				</Button>
			</Item>
			<Item name='ns1' label={t('dnsPage.ns1')}>
				<Input placeholder='ns1.zcom.com' />
			</Item>
			<Item name='ns2' label={t('dnsPage.ns2')}>
				<Input placeholder='ns2.zcom.com' />
			</Item>

			<Item {...tailLayout}>
				<Button type='primary' htmlType='submit' disabled={!isFormValid}>
					{t('dnsPage.dns_zone_button')}
				</Button>
			</Item>
			<DnsZoneArchivedDropdown serviceId={selectedService.id}
				onDnsZoneCreateFromArchive={createFromArchive}
			/>
		</Form>
	)
}

export default DnsZoneForm
