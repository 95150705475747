import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, List, message, Row, Select, Space, Spin, Tag} from "antd";
import getDnsZones from "./actions/getDnsZones";
import {CSVLink} from 'react-csv'
import {DnsCheck, DnsZone} from "./models";
import {stopPropagation} from "../../../common/fce";

import * as i18next from "i18next";
import checkDnsZone from "./actions/checkDnsZone";


interface Props {
    serviceId: number
}

const DnsFooter = (t: i18next.TFunction, data: DnsCheck[], a: number, n: number) => {

    return (
        <Row style={{paddingTop: '8px', paddingLeft: '8px'}}>
            <Col span={4}>
                <Space>
                    <b>{t('general.total')}&nbsp;<b>{Number(data.length)}</b></b>
                </Space>
            </Col>
            <Col span={4}>
                <Space>
                    <Tag className='success'>{t('dnsPage.IS_AUTORITATIVE')}&nbsp;<b>{a}</b></Tag>
                </Space>
            </Col>
            <Col span={4}>
                <Space>
                    <Tag color="#faad14">{t('dnsPage.NOT_AUTORITATIVE')}&nbsp;<b>{n}</b></Tag>
                </Space>
            </Col>
            <Col span={12}>
                <div style={{float: 'right'}}>
                    <Button type="primary">
                        {data && (
                            <CSVLink
                                filename='dns.csv'
                                data={data}
                                className="btn btn-primary"
                                onClick={()=>{
                                    message.success("The file is downloading").then()
                                }}
                            >
                                Export to CSV
                            </CSVLink>
                        )}
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

const STATE_CHECKING = 'checking..'
const getDnsStates = () => {
    return ['NOT_AUTORITATIVE', 'IS_AUTORITATIVE', 'NOT_FOUND']
}

const DnsChecker = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {dnsChecks, dnsZones} = useSelector((state: AppState) => state.dnsservice)
    const [dataSource, setDataSource] = useState<DnsCheck[]>([])
    const [gridSource, setGridSource] = useState<DnsCheck[]>([])
    const [started, setStarted] = useState<number>(0)
    const [authoritative, setAuthoritative] = useState<number>(0)
    const [noAuthoritative, setNoAuthoritative] = useState<number>(0)
    const [checkResults, setCheckResults] = useState(new Map());
    const [searchState, setSearchState] = useState<string | undefined>(undefined)
    const [stateOptions, setStateOptions] = useState<{ label: string, value: string }[]>([])


    useEffect(() => {
        if (props.serviceId > 0) {
            setCheckResults(new Map())
            setDataSource([])
            setGridSource([])
            setStateOptions(getDnsStates().map(s => ({label: t('dnsPage.'+s), value: s})))
            dispatch(getDnsZones(props.serviceId, suc => {
                // console.log('useEffect..OK '+dnsZones.length)
                setStarted(1)
            }))
        }
    }, [])

    useEffect(() => {
        if (started > 0 && dnsZones) {
            setDataSource(dnsZones.map( z => {
                return {domain: z.name,
                    type: 'domain',
                    state: STATE_CHECKING
                }
            }))
            checkZones()
        }
    }, [started])

    useEffect(() => {
        dataSource.forEach((check: DnsCheck, idx, arr) => {
            // console.log(`${check.domain} - ${check.state}`)
        })
    }, [dataSource])

    useEffect(() => {
        if (started > 0) {
            let map = new Map(checkResults)
            Object.keys(dnsChecks).forEach( k => {
                map.set(`${k}.`, dnsChecks[k].state)
                // console.log('dnsChecks: '+`${k}.`+' '+dnsChecks[k].state)
            })
            setCheckResults(map);
        }
    }, [dnsChecks, started])

    useEffect(() => {
        refresh()
    }, [checkResults])

    useEffect(() => {
        refresh()
    }, [searchState])

    useEffect(() => {
    }, [authoritative, noAuthoritative])

    const refresh = () => {
        setAuthoritative(0)
        setNoAuthoritative(0)
        setGridSource(filtered())
    }

    const filtered = () => {
        if (!dataSource || dataSource.length === 0) {
            return []
        }
        let data = dataSource.map( z => {
            return {...z,
                state: checkResults.has(z.domain) ? checkResults.get(z.domain) : z.state,
            }
        })
        setAuthoritative(data.filter(ch => ch.state === 'IS_AUTORITATIVE').length)
        setNoAuthoritative(data.filter(ch => ch.state === 'NOT_AUTORITATIVE').length)
        if (searchState) {
            const qState = (s: DnsCheck) => s && s.state === searchState
            data = data.filter((c) => qState(c))
        }
        return data
    }

    const checkZones = () => {
        dnsZones.forEach((zone: DnsZone, idx, arr) => {
            setTimeout(() => {
                dispatch(checkDnsZone({service_id: props.serviceId, domain: zone.name}, (suc, name) => {
                    if (!suc) {
                        // set Error for the domain
                        let map = new Map(checkResults)
                        checkResults.set(name, 'Error')
                        setCheckResults(map)
                    }
                }))
            }, (idx+1) * 200)
        })
    }

    const renderState = (state: string) => {
        if ( state === STATE_CHECKING) {
            return (<span style={{color: '#cccccc', fontWeight: 'normal'}}>{STATE_CHECKING}</span>)
        }
        if ( state === 'NOT_AUTORITATIVE') {
            return (<span style={{color: '#faad14', fontWeight: 'bold'}}>{t('dnsPage.NOT_AUTORITATIVE')}</span>)
        }
        if ( state === 'IS_AUTORITATIVE') {
            return (<span style={{color: 'green', fontWeight: 'bold'}}>{t('dnsPage.IS_AUTORITATIVE')}</span>)
        }
        if ( state === 'NOT_FOUND') {
            return (<span style={{color: 'red', fontWeight: 'bold'}}>{t('dnsPage.NOT_FOUND')}</span>)
        }
        if ( state.toLowerCase() === 'error') {
            return (<span style={{color: 'red', fontWeight: 'bold'}}>Error</span>)
        }
        return (<span>Unknown ???</span>)
    }


    if (!dnsZones || dnsZones.length === 0) {
        return (<Spin/>)
    }

    return (
        <Card>
            <div id="scrollableDiv" style={{height: 550, overflow: 'auto'}}>
                <List size="small"
                    header={
                        <div style={{width: '100%', overflow: 'auto'}}>
                            <div style={{width: '40%', float: 'right'}}>
                                <Select
                                    placeholder='State'
                                    options={stateOptions}
                                    value={searchState}
                                    style={{ width: '200px', margin: '0' }}
                                    dropdownMatchSelectWidth={200}
                                    allowClear
                                    onClick={stopPropagation}
                                    onChange={(v) => {setSearchState(v)}}
                                />
                            </div>
                            <div style={{width: '15%', float: 'right'}}><b>Type</b></div>
                            <div style={{width: '45%', float: 'right'}}><b>Domain</b></div>
                        </div>
                    }
                    footer={null}
                    bordered
                    dataSource={gridSource}
                    renderItem={item => (
                        <List.Item style={{padding: '2px'}}>
                                <div style={{width: '100%'}}>
                                    <div style={{width: '40%', float: 'right'}}>{renderState(item.state)}</div>
                                    <div style={{width: '15%', float: 'right'}}>{item.type}</div>
                                    <div style={{width: '45%', float: 'right'}} className='link'><b>{item.domain}</b></div>
                                </div>
                        </List.Item>
                    )}
                />
            </div>
            {DnsFooter(t, dataSource, authoritative, noAuthoritative)}
        </Card>
    )
}

export default DnsChecker