import { ActionType, DnsServiceActions } from './actions'
import { AsyncActionMode } from 'common/models'
import {DnsServiceModel, DnsPageState} from './models'

const DNSSERVICE_INITIAL_STATE: DnsPageState = {
  isLoading: false,
  isLoadingArchive: false,
  isSaving: false,
  isSavingZone: false,
  isLoadingZone: false,
  dnsZones: [],
  dnsServices: [],
  dnsRecords: [],
  exportedFile: '',
  dnsChecks: {},
  error: ''
}

export default (state = DNSSERVICE_INITIAL_STATE, action: DnsServiceActions): DnsPageState => {
  switch (action.type) {
    case ActionType.GET_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsService: action.data,
          isLoading: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsServices: state.dnsServices?.concat(action.data),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isSaving: false,
        }
      }
      break
    case ActionType.GET_DNSSERVICEES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoading: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoading: false,
          dnsServices: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoading: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsServices: state.dnsServices!.filter((dnsservice) => dnsservice.id !== action.request.id),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.UPDATE_DNSSERVICE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSaving: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsServices: state.dnsServices!.map((dnsservice) =>
            dnsservice.id === action.data.id ? { ...action.data } : dnsservice,
          ),
          isSaving: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSaving: false,
          error: action.error,
        }
      }
      break

    case ActionType.SET_DNSSEC_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZone: action.data,
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error
        }
      }
      break
    case ActionType.SET_SOA_TTL_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZone: action.data,
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.CLEAR_ARCHIVED:
      return { ...state, isLoadingArchive: false, archived: undefined }
    case ActionType.GET_ARCHIVED_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingArchive: true, archived: undefined }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          archived: action.data,
          isLoadingArchive: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingArchive: false,
          error: action.error,
        }
      }
      break
    case ActionType.EXPORT_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isLoadingArchive: true, exportedFile: '' }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          exportedFile: action.data,
          isLoadingArchive: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingArchive: false,
          error: action.error,
        }
      }
      break
    case ActionType.ADD_RECORD_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingZone: false,
          dnsZone: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.REMOVE_RECORD_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isSavingZone: false,
          dnsZone: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.GET_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          dnsRecords: [],
          dnsZone: undefined,
          isLoadingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZone: action.data,
          dnsRecords: action.data.records,
          isLoadingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.CHECK_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state}
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        let allChecks = state.dnsChecks
        allChecks[action.data.domain] = action.data
        return {
          ...state,
          dnsChecks: {...allChecks}
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
        }
      }
      break
    case ActionType.CREATE_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZones: state.dnsZones?.concat(action.data),
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          error: action.error,
          isSavingZone: false,
        }
      }
      break
    case ActionType.GET_DNS_ZONES:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state,
          dnsZones: [],
          dnsChecks: {},
          isLoadingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          isLoadingZone: false,
          dnsZones: action.data,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isLoadingZone: false,
          error: action.error,
        }
      }
      break
    case ActionType.DELETE_DNS_ZONE:
      if (action.mode === AsyncActionMode.REQUEST) {
        return { ...state, isSavingZone: true }
      }
      if (action.mode === AsyncActionMode.RESPONSE) {
        return {
          ...state,
          dnsZones: state.dnsZones!.filter(
              (dnszone) => dnszone.name !== action.request.params.domain
          ),
          isSavingZone: false,
        }
      }
      if (action.mode === AsyncActionMode.ERROR) {
        return {
          ...state,
          isSavingZone: false,
          error: action.error
        }
      }
      break
  }
  return state
}
