import React, {useState, useEffect, useRef} from 'react'
import {Card, Button, Modal, message, Space, Table, Tag, Input, Tooltip, Row, Col, Form, Spin, AutoComplete} from 'antd'
import { CloudServerOutlined, PlusCircleOutlined } from '@ant-design/icons/lib/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import getServers from './actions/getServers'
import {CreateUpdateServerParams, ServerModel, ServerService} from './models'
import createServer from './actions/createServer'
import updateServer from './actions/updateServer'
import deleteServer from './actions/deleteServer'
import {AppState, NameItem} from 'common/models'
import PopConfirmZis from "../../components/popconfirm/PopConfirmZis";
import {DeleteTwoTone, EditTwoTone, GlobalOutlined, InfoCircleTwoTone} from "@ant-design/icons";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../components";
import ErrorPage403 from "../../components/Errors/ErrorPage403";
import useLogger from "../../common/useLogger";
import usePageSize from "../../common/usePageSize";
import {DraggableData, DraggableEvent} from "react-draggable";
import getHistoryLogs from "../../components/History/actions/getHistoryLogs";
import {ColumnsType} from "antd/lib/table";
import {removeDiac, stopPropagation} from "../../common/fce";
import {PAGING} from "../../common/enums";
import TotalNum from "../../components/TotalNum/TotalNum";
import getCustomers from "../billing/customer/actions/getCustomers";
import {IpAddressModel, IpModel} from "../ip/subnet/models";
import {sort_hostname, sort_str} from "../../common/sorting";
import getPhysicalServers from "./actions/getPhysicalServers";
import './ServersPage.scss'
import {Link} from "react-router-dom";
import getServerTypes from "./actions/getServerTypes";
import getServerServiceTypes from "./actions/getServerServiceTypes";
import getServerVpsTypes from "./actions/getServerVpsTypes";
import getZisManagementTypes from "./actions/getZisManagementTypes";
import getCustomerManagementTypes from "./actions/getCustomerManagementTypes";
import getServerBackupTypes from "./actions/getServerBackupTypes";
import getServerMonitoringTypes from "./actions/getServerMonitoringTypes";
import tableCustomers from "../login/actions/tableCustomers";

const renderIpTag = (text) => {
    return (<Tag style={{border: 0}} className='tag' key='orange'>{text}</Tag>)
}

const renderIpRow = (text) => {
    return (<><Tag style={{border: 0}} className='tag' key='orange'>{text}</Tag><br/></>)
}

const renderIP = (ipId: number, addresses: IpModel[] | undefined) => {
    if (!addresses) {
        return '?'
    }
    let address = addresses.find(addr => addr.id === ipId)
    if (address) {
        return renderIpTag(address.address)
    }
    return (<span style={{color: '#ccc'}}>{ipId + ' ??'}</span>)
}

const renderType = (rec: ServerModel, server_types, vps_types) => {
    const type_name = server_types.find(t => t.id === rec.type_id)?.name
    const vps_name = vps_types.find(t => t.id === rec.vps_type_id)?.name
    switch (rec.type_id) {
        case 2:
            return (<>
                <Tag style={{border: 0}} className='tag' key='type'>Virtual</Tag>&nbsp;
                <Tag className='microTag' color='orange' key='vps'>{vps_name}</Tag>
            </>)
        case 1:
            return (<Tag style={{border: 0}} className='tag' color='blue' key='blue'>Metal</Tag>)
    }
    return rec.server_type
}

const generateServices = (name: string) => {
    switch (name) {
        case 'bacula_client':
            return (<Tag className='microTag' color='magenta' key='magenta'>BaculaC</Tag>)
        case 'bacula_server':
            return (<Tag className='microTag' color='red' key='red'>BaculaS</Tag>)
        case 'dns':
            return (<Tag className='microTag' color='volcano' key='volcano'>DNS</Tag>)
        case 'ftp':
            return (<Tag className='microTag' color='orange' key='orange'>FTP</Tag>)
        case 'mail':
            return (<Tag className='microTag' color='gold' key='gold'>Mail</Tag>)
        case 'mysql':
            return (<Tag className='microTag' color='green' key='green'>SQL</Tag>)
        case 'proxmox':
            return (<Tag className='microTag' color='cyan' key='cyan'>Prox</Tag>)
        case 'web_ep':
            return (<Tag className='microTag' color='blue' key='blue'>WebEP</Tag>)
        case 'web_php':
            return (<Tag className='microTag' color='geekblue' key='geekblue'>WebPHP</Tag>)
        case 'web_ruby':
            return (<Tag className='microTag' color='purple' key='purple'>WebRuby</Tag>)
    }
}

const ServersPage = () => {
    const CONTROL_NAME = 'page_servers'
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {customers} = useSelector((state: AppState) => state.auth.tables)
    const {servers, physical_servers, isLoading, isLoadingServers, service_types, server_types,
        locations, vps_types, zis_managements, customer_managements, backup_types,
        server_monitorings} = useSelector( ( state: AppState ) => state.logic_server)
    const {log_servers} = useSelector((state: AppState) => state.sidenav)

    const [pageSize, setPageSize] = useState<string>()
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)

    const [hostname, setHostname] = useState('')
    const [custName, setCustName] = useState('')
    const [ipName, setIpName] = useState('')
    const [loaded, setLoaded] = useState(0)

    const [dataToUpdate, setDataToUpdate] = useState<ServerModel>()
    const [dataSource, setDataSource ] = useState<ServerModel[]>()
    const [serversFull, setServersFull ] = useState<ServerModel[]>()
    const [isModalVisible, setModalVisible] = useState<boolean>(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>()
    const [selectedServerId, setSelectedServerId] = useState(0)

    const hideModal = () => setModalVisible(false)
    const showModal = () => setModalVisible(true)

    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'ServersPage')
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        if (loggedUser) {
            setViewer(loggedUser.hasAccess('page_servers'))
            setCreator(loggedUser.hasAccess('page_servers_create_button'))
            setEditor(loggedUser.hasAccess('page_servers_edit_button'))
            setDeleter(loggedUser.hasAccess('page_servers_delete_button'))
        }
        if (!customers || customers.length === 0) {
            dispatch(tableCustomers())
        }
        if (!physical_servers || physical_servers.length === 0) {
            dispatch(getPhysicalServers())
        }
        if (!service_types || service_types.length === 0) {
            dispatch(getServerTypes())
        }
        if (!service_types || service_types.length === 0) {
            dispatch(getServerServiceTypes())
        }
        if (!vps_types || vps_types.length === 0) {
            dispatch(getServerVpsTypes())
        }
        if (!backup_types || backup_types.length === 0) {
            dispatch(getServerBackupTypes())
        }
        if (!server_monitorings || server_monitorings.length === 0) {
            dispatch(getServerMonitoringTypes())
        }
        if (!zis_managements || zis_managements.length === 0) {
            dispatch(getZisManagementTypes())
        }
        if (!customer_managements || customer_managements.length === 0) {
            dispatch(getCustomerManagementTypes())
        }
        if (!isLoadingServers) {
            dispatch(getServers())
            setLoaded(1)
            logger('getServers..')
        }

    }, [])

    useEffect(() => {
        // SideNav menu pressed
        logger('isLoadingServers:'+isLoadingServers)
        logger('loaded:'+loaded)
        if (!isLoadingServers && loaded > 0) {
            dispatch(getServers())
        }
    }, [log_servers])

    useEffect(() => {
        logger('full - servers..')
        if (servers) {
            logger('servers: '+servers.length)
            setServersFull(servers.map((s) => {
                let name = customers.find(c => c.id === s.customer_id)?.company!.name
                let ips = s.addresses?.map((a) => a.address).join('|')
                return {...s,
                    primary_ip_name: s.primary_ip?.address,
                    server_type: server_types.find(t => t.id === s.type_id)?.name,
                    location: 'loc',
                    vps_type: vps_types.find(t => t.id === s.vps_type_id)?.name,
                    zis_management: zis_managements.find(t => t.id === s.zis_management_type_id)?.name,
                    customer_management: customer_managements.find(t => t.id === s.customer_management_type_id)?.name,
                    backup_type: backup_types.find(t => t.id === s.backup_type_id)?.name,
                    server_monitoring: server_monitorings.find(t => t.id === s.monitoring_type_id)?.name,
                    searchableName: removeDiac(name) + ',' + ips + ',' + s.hostname + ',' + s.primary_ip?.address,
                    customer_name: name
                }
            }))
        }
    }, [servers])


    useEffect(() => {
        // Filters
        refreshGrid()
    }, [serversFull, ipName, hostname, custName])


    const refreshGrid = () => {
        logger('refreshGrid')
        setDataSource(filtered().sort(sort_hostname))
    }

    const filtered = () => {
        let data: ServerModel[] = []
        if (!serversFull) {
            return []
        }

        let qs:string[] = []
        data = serversFull
        if (hostname) {
            // hostname
            const qHostname = (c) => c.hostname.includes(removeDiac(hostname))
            data = data.filter((s) => qHostname(s))
            qs.push('hostname='+removeDiac(hostname))
        }

        if (ipName) {
            // ip
            const qIP = (c) => c.searchableName.includes(removeDiac(ipName))
            data = data.filter((s) => qIP(s))
            qs.push('ip='+removeDiac(ipName))
        }

        if (custName) {
            // name
            const qName = (c) => c.customer_name.includes(removeDiac(custName))
            data = data.filter((s) => qName(s))
            qs.push('customer='+removeDiac(custName))
        }

        return data
    }

    const onDelete = (id: number) => {
        dispatch(deleteServer(id))
    }

    const fetchHistory = (service: string, model: string, mid: number) => {
        setHistoryTitle(`${service} / ${model} / ${mid}`)
        dispatch(getHistoryLogs(service, model, mid))
    }

    const onFormSubmit = (values: CreateUpdateServerParams) => {
        dataToUpdate && values && values.id?
            dispatch(updateServer({...values}, (isSuccess: boolean) => {
                if (isSuccess) {
                    setModalVisible &&
                    setModalVisible(false)
                    message.info(t('serversPage.updated'))
                }
            })) :
            dispatch(createServer({...values}, (isSuccess: boolean) => {
                    if (isSuccess) {
                        setModalVisible &&
                        setModalVisible(false)
                        message.info(t('serversPage.created'))
                    }
                }
            ))
    }

    const handleExpand = (expanded, rec: ServerModel) => {
        let keys: number[] = [];
        if (expandedRowKeys) {
            keys = expandedRowKeys
        }
        if (expanded) {
            keys.push(rec.id)
            setExpandedRowKeys(keys)
            // dispatch(getIpSubnet(rec.id))
            setSelectedServerId(rec.id)
        }
        else {
            setExpandedRowKeys(expandedRowKeys?.filter(k => k != rec.id))
            setSelectedServerId(0)
        }
    }

    const tabExtra = (
        <Space>
            <Button type='primary' onClick={() => {
                setDataToUpdate(undefined)
                showModal()
            }}
            >
                <PlusCircleOutlined/>{t('serversPage.create-server')}
            </Button>
        </Space>
    )

    const FilterByHostname = servers && (
        <AutoComplete
            placeholder={t('serversPage.hostname')}
            value={hostname}
            style={{width: '160px'}}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            allowClear={true}
            onChange={v => {
               setHostname(v)
            }}
        />
    )

    const FilterByCustomer = customers && (
        <AutoComplete
            placeholder={t('serversPage.customer_id')}
            value={custName}
            style={{width: '120px'}}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            allowClear={true}
            onChange={v => { setCustName(v) }}
        />
    )

    const FilterByIp = servers && (
        <AutoComplete
            placeholder={t('serversPage.ip')}
            value={ipName}
            style={{width: '120px'}}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            allowClear={true}
            onChange={v => { setIpName(v) }}
        />
    )

    const expandedRowRender = (rec: ServerModel) => {
        return (
            <Row className='serverExpanded'>
                <Col span='12'>
                    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label={t('serversPage.location')}>
                            <span>{rec.location}</span>
                        </Form.Item>

                        <Form.Item label={t('serversPage.ip')} className='role-bokx'>
                            {rec.addresses?.map(a => renderIpRow(a.address))}
                        </Form.Item>
                    </Form>
                </Col>
                <Col span='12'>
                    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label={t('serversPage.server_monitoring')}>
                            <span>{rec.server_monitoring}</span>
                        </Form.Item>
                        <Form.Item label={t('serversPage.customer_management')}>
                            <span>{rec.customer_management}</span>
                        </Form.Item>
                        <Form.Item label={t('serversPage.zis_management')}>
                            <span>{rec.zis_management}</span>
                        </Form.Item>
                        <Form.Item label={t('serversPage.server_backup')}>
                            <span>{rec.backup_type}</span>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        )
    }

    const columns: ColumnsType<ServerModel> = [
        {
            title: FilterByHostname,
            dataIndex: 'hostname',
            key: 'hostname',
            width: 200,
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => sort_hostname(a, b),
            render: (text, rec) => {
                return (
                    <Link to={`/servers/edit/${rec.id}`}>{text}</Link>
                )
            }
        },
        {
            title: FilterByIp,
            dataIndex: 'primary_ip_name',
            key: 'primary_ip_name',
            width: 100
        },
        {
            title: FilterByCustomer,
            dataIndex: 'customer_name',
            key: 'customer_id',
            width: 120,
            ellipsis: {showTitle: false},
            render: customer_name => (
                <Tooltip placement="topLeft" title={customer_name}>
                    {customer_name}
                </Tooltip>
            ),
            sorter: (a, b) => sort_str(a.customer_name, b.customer_name),
        },
        {
            title: 'Type',
            dataIndex: 'server_type',
            key: 'server_type',
            width: 120,
            render: (t, rec) => renderType(rec, server_types, vps_types)
        },
        {
            title: t('serversPage.comment'),
            dataIndex: 'comment',
            key: 'comment',
            width: 80,
            ellipsis: {showTitle: false},
            render: comment => (
                <Tooltip placement="topLeft" title={comment}>
                    {comment}
                </Tooltip>
            ),
        },
        {
            title: 'Services',
            dataIndex: 'services',
            key: 'services',
            render: (text: ServerService[], rec: ServerModel) => rec.services?.map((v) => generateServices(v.name)),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: '140px',
            align: 'right',
            fixed: 'right',
            render: (text: string, rec: ServerModel) => (
                <Space size='small'>
                    {
                        isEditor && (
                            <Button title={t('ServersPage.update-server')}
                                    type='text' size='small'
                                    onClick={() => {
                                        setDataToUpdate(rec)
                                        showModal()

                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green'/>}
                            />
                        )
                    }

                    {
                        isDeleter && (
                            <PopConfirmZis onConfirm={() => onDelete(rec.id)}>
                                <Button title={t('ServersPage.delete')}
                                        type='text' danger size='small'
                                        className='actionButton'
                                        icon={<DeleteTwoTone twoToneColor='red'/>}
                                />
                            </PopConfirmZis>
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                fetchHistory('server', 'Server', rec.id)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone/>}
                            className='actionButton'
                    />
                </Space>
            ),
        }
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }


    return (
        <>
            <Card
                title={<><CloudServerOutlined/> &nbsp; {t('serversPage.title')}</>}
                extra={tabExtra}
                className='ServersPage'
                loading={isLoading}
            >

                <Table<ServerModel>
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{x: 'max-content'}}
                    rowKey='id'
                    bordered={true}
                    className='ServersTable'
                    expandable={{
                        expandedRowRender,
                        expandRowByClick: true,
                        expandedRowClassName: (rec, index, indent) => 'expandedRow',
                        expandedRowKeys: expandedRowKeys
                    }}
                    onExpand={handleExpand}
                    onRow={(rec:ServerModel, rowIndex) => {
                        if (expandedRowKeys && expandedRowKeys.includes(rec.id)) {
                            return { className: 'expandedRow' };
                        }
                        return { className: '' };
                    }}
                    loading={isLoadingServers}
                    pagination={{
                        defaultPageSize: appSetting.grid_page_size,
                        pageSizeOptions: PAGING,
                        showSizeChanger: true
                    }}
                    onChange={(ev) => {
                        setPageSize(`${ev.pageSize}`)
                    }}
                    footer={() => TotalNum(Number(dataSource?.length), 'Logical Servers', dataSource)}
                />

            </Card>

            <Modal
                destroyOnClose
                style={{top: 20}}
                width={500}
                title={<>
                    <CloudServerOutlined/> &nbsp; {dataToUpdate ? t('serversPage.update-server') : t('serversPage.create-server')}</>}
                visible={isModalVisible}
                onCancel={hideModal}
                footer={null}
                confirmLoading={true}
            >

                ..ServerForm

            </Modal>
        </>
    )
}

export default ServersPage