import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import {IpAddressGrid} from '../models'
import {ListIpAddressErrorAction, ListIpAddressRequestAction, ListIpAddressResponseAction} from "./index";



export default (pageSize: number, pageNumber: number, filter: string, cb?: (isSuccess: boolean) => void) => {
    return (
        dispatch: (
            arg:
                | ListIpAddressRequestAction
                | ListIpAddressResponseAction
                | ListIpAddressErrorAction,
        ) => void,
    ) => {
        const request = new ListIpAddressRequestAction(pageSize, pageNumber)
        dispatch(request)

        protectedApiClient
            .get<IpAddressGrid>(`/ip/ip?expand=ipv6,ptr&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&${filter}`)
            .then((response) => {
                dispatch(new ListIpAddressResponseAction(request, response.data))
                cb && cb(true)
            })
            .catch((error) => {
                dispatch(new ListIpAddressErrorAction(request, error))
                handleApiErrorWithNotification(error)
                cb && cb(false)
            })
    }
}
