import { protectedApiClient } from 'helpers/api'
import { serializeParams } from 'helpers/stringHelpers'
import { GetZoneParams} from '../models'
import {
  ExportDnsZoneErrorAction, ExportDnsZoneRequestAction, ExportDnsZoneResponseAction,
} from "./index";
import {handleApiError} from "../../../../helpers/errorHandling";


export default (params: GetZoneParams, cb?: (isSuccess: boolean) => void) => {
  return (
      dispatch: (
          arg:
              | ExportDnsZoneRequestAction
              | ExportDnsZoneResponseAction
              | ExportDnsZoneErrorAction
      ) => void
  ) => {
    const request = new ExportDnsZoneRequestAction(params)
    dispatch(request)
    protectedApiClient
        .get<string>(`/dns/service/${params.service_id}/zone-export?domain=${params.domain}`)
        .then((response) => {
          dispatch(new ExportDnsZoneResponseAction(request, response.data))
          cb && cb(true)
        })
        .catch((error) => {
          console.log(error)
          if (error.message.includes('status code 404')) {
            // error resolved
            dispatch(new ExportDnsZoneResponseAction(request, ''))
            cb && cb(false)
          }
          else {
            dispatch(new ExportDnsZoneErrorAction(request, error))
            handleApiError(error)
            cb && cb(false)
          }
        })
  }
}
