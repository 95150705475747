import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {tailLayout} from "../../../helpers/layoutHelpers";
import {Store} from "antd/lib/form/interface";
import {UpdateIpParams} from "./models";
import {CustomerDetails} from "../../billing/customer/models";
import {AppState} from "../../../common/models";
import {sort_ident} from "../../../common/sorting";
import updateIP from "./actions/updateIP";
import TextArea from "antd/lib/input/TextArea";

interface DnsModel {
    id: number
    host: string
    address: string
}

const fakeDns: DnsModel[] = [
    {id:1080, host: 'ns1-master.zcom.cz', address: '1.1.1.1'},
    {id:1, host: 'ns1.zcom.cz', address: '1.1.1.2'},
    {id:1253, host: 'onsite-dns1.zcom.cz', address: '1.1.1.1'}
]

interface Props {
    id: number
    onClose: () => void
}

const EditIpPage = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()

    const {companies, customers} = useSelector((state: AppState) => state.auth.tables)
    const {isLoadingIp, ip} = useSelector((state: AppState) => state.ipSubnet)

    const [customerSource, setCustomerSource] = useState<CustomerDetails[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [dnsSource, setDnsSource] = useState<DnsModel[]>([])

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        setDnsSource(fakeDns)
        if(customers && customers.length) {
            const data = customers.map((c) => {return {...c, ident: c.company!.name}})
            setCustomerSource(data.sort(sort_ident))
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [customers])

    useEffect(() => {
        if (ip && ip.id === props.id) {
            setSelectedCustomerId(ip.customer_id)
        }
    }, [ip])

    const submitIP = (values: Store): void => {
        const params: UpdateIpParams = {
            id: props.id,
            note: values.note
        }
        dispatch(updateIP(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                props.onClose()
            }
        }))
    }

    if (isLoadingIp || !ip) {
        return <Spin />
    }

    return (
        <>
            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '0'}}>
                <Col span={6} className='right' >{t('ipSubnetPage.newSubnet.customer')}:&nbsp;</Col>
                <Col span={18} >
                    <Select optionFilterProp='label'
                            style={{width: '100%'}}
                            options={customerSource.map(i => ({
                                value: i.id,
                                key: i.id,
                                label: i.ident
                            }))}
                            defaultValue={ip.customer_id}
                            disabled={true}
                    />
                </Col>
            </Row>

            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='create-ip'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form1}
                          initialValues={{...ip}}
                          onFinish={submitIP}
                    >

                        <Form.Item name='address' label={t('ipSubnetPage.address')}>
                            <Input disabled />
                        </Form.Item>

                        <Form.Item name='note' label={t('ipSubnetPage.newSubnet.note')}>
                            <TextArea data-gramm="false"
                                      data-gramm_editor="false"
                                      data-enable-grammarly="false" />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit' className='create-button'>
                                {t('general.update')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default EditIpPage