import React from 'react'
import {Collapse, Form} from 'antd'
import Button from 'antd-button-color'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import {formItemLayout, tailLayout} from "../../../helpers/layoutHelpers";

interface Props {
  serviceId?: number
  onDnsZoneCreateFromArchive: () => void
}

const { Panel } = Collapse
const {Item} = Form

const DnsZoneArchivedDropdown = (props: Props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

    const {archived} = useSelector((state: AppState) => state.dnsservice)

  return (
    <>
      <Collapse className='DnsZoneArchivedDropdown' defaultActiveKey={archived ? ['1'] : []}
                collapsible={archived ? 'header' : 'disabled'} >
        <Panel header={
          archived ?
              (<u className='text-primary'><b>
                {`Archived zone ${archived.domain}: ${archived?.records.length} records`}
                </b></u>
              )
        :
              (<span>Archived zone : 0 records</span>)
        } key='1'>
          <>
            {archived?.records
              .sort((a) => (a.type === 'SOA' ? -1 : 1))
              .map((record, i) => (
                <div key={i} className='dnsRecords'>
                  <div>{record.name} </div>
                  <div>{record.type}</div>
                  <div>{record.content}</div>
                </div>
              ))}

            <Form {...formItemLayout} style={{marginTop: '16px'}}>
                <Item {...tailLayout}>
                    <Button type='primary' onClick={() => props.onDnsZoneCreateFromArchive()}>
                        {t('dnsPage.create_zone_from_archived')}
                    </Button>
                </Item>
            </Form>
          </>
        </Panel>
      </Collapse>
    </>
  )
}

export default DnsZoneArchivedDropdown
