import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message, Select, Spin} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {formItemLayout, tailLayout} from 'helpers/layoutHelpers'
import createPricelist from 'pages/billing/pricelist/actions/createPricelist'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Store} from 'antd/lib/form/interface'
import {UpdatePricelistParams, PricelistDetails} from 'pages/billing/pricelist/models'
import moment from 'moment'
import updatePricelist from 'pages/billing/pricelist/actions/updatePricelist'
import {CustomerDetails} from 'pages/billing/customer/models'
import {AppState} from 'common/models'
import {SelectValue} from 'antd/lib/select'
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {sort_ident, sort_label} from "../../../common/sorting";
import getPricelists from "./actions/getPricelists";
import DateInput from "../../../components/RangeFilter/DateInput";
import getCurrencies from "../../../components/SelectCurrencies/actions/getCurrencies";
import {removeDiac} from "../../../common/fce";


interface Props {
	dataToUpdate?: UpdatePricelistParams
	onClose?: () => void
	customers?: CustomerDetails[]
	pricelists?: PricelistDetails[]
}

const {Option} = Select
const {Item} = Form

const isClosed = (obj: UpdatePricelistParams | undefined): boolean => {
	// is Pricelist closed?
	if (!obj) {
		return true
	}
	if (!obj.valid_to) {
		return false
	}
	return Boolean(moment(obj.valid_to) < moment())
}

const PricelistForm = ({dataToUpdate, onClose}: Props) => {
	const [form] = useForm()
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const {currencies} = useSelector((state: AppState) => state.currencies)
	const {pricelists} = useSelector((state: AppState) => state.pricelist)
	const {customers} = useSelector((state: AppState) => state.auth.tables)

	const [selectedCustomerId, setSelectedCustomerId] = useState<number>()
	const [selectedCurrency, setSelectedCurrency] = useState<SelectValue>()
	const [disabledDate, setDisabledDate] = useState<moment.Moment>()
	const [updateStatus, setUpdateStatus] = useState<boolean>(false)
	const [custOptions, setCustOptions] = useState<{ label: string, value: number }[]>([])

	const [customerPricelists, setCustomerPricelists] = useState<PricelistDetails[]>([])
	const [rootPricelist, setRootPricelist] = useState<PricelistDetails | undefined>(undefined)
	const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails | undefined>(undefined)
	const [hasPricelist, setHasPricelist] = useState<boolean>(false)
	const [hasActivePricelist, setHasActivePricelist] = useState<boolean>(false)
	const [customerCurrencies, setCustomerCurrencies] = useState<string[]>([])

	// get settings and logged user from store
	const loggedUser = useLoggedUser()
	if (!loggedUser || !loggedUser.isLoaded()) {
		return (<Spin />)
	}
	const appSetting = loggedUser.getAppSettings()

	// logger
	const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('PricelistForm: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('PricelistForm: ' + msg)} }

	useEffect(() => {
		if (!currencies || currencies.length === 0) {
			dispatch(getCurrencies())
		}

		const items = customers.map(cust => ({label: cust.company!.name, value: cust.id}))
		setCustOptions(items.sort(sort_label))
	}, []);

	useEffect(() => {
		if (selectedCustomerId) {
			logger('selectedCustomerId='+selectedCustomerId)
			const customerPLs = pricelists.filter((pl) => pl.customer_id === Number(selectedCustomerId) )
			setCustomerPricelists(customerPLs)
			setHasPricelist(customerPLs.length > 0)
			logger('HasPricelist='+hasPricelist)
			const cust = customers.find((c) => c.id === selectedCustomerId)
			setSelectedCustomer(cust)
			if (cust) {
				logger('customerId='+cust.id)
				if (cust.id === 1) {
					// zcom
					setCustomerCurrencies(currencies)
				}
				else {
					setCustomerCurrencies([cust.billing_currency])
				}
				setSelectedCurrency(cust.billing_currency)
				logger('SelectedCurrency='+cust.billing_currency)
				const rootPL = pricelists.find((pl) => pl.currency === cust.billing_currency && pl.parent_id === null)
				setRootPricelist(rootPL)
				const hasActivePL = customerPLs.filter((pl) => (moment(pl.valid_from) <= moment()) && (pl.valid_to === null))
				setHasActivePricelist(hasActivePL.length > 0)
				logger('setHasActivePricelist='+hasActivePL.length)
				const inactivePriceDates = customerPLs.map((pl) => pl.valid_to !== null ? moment(pl.valid_to) : moment())
				const maxDate = inactivePriceDates && moment.max(inactivePriceDates);
				setDisabledDate(maxDate)
				logger('maxDate='+moment(maxDate).format('YYYY-MM-DD'))

				if (hasActivePL.length > 0) {
					// customer has active pricelist
					form.setFieldsValue({
						name: `${cust.company!.name} ${cust.billing_currency}`,
						valid_from: moment(maxDate).add(1, 'day'),
						parent_id: cust.billing_currency === 'CZK' ? 1 : 2,
					})

					form.setFields([
						{
							name: 'customer_id', errors: [t('billing.pricelist.error.hasActivePrice')]
						}
						]
					)
				} else {
					// customer has no active pricelist
					form.setFieldsValue({
						name: `${cust.company!.name} ${cust.billing_currency}`,
						valid_from: moment(maxDate).add(1, 'day'),
						parent_id: cust.billing_currency === 'CZK' ? 1 : 2,
					})
				}
				logger('Form='+ form.getFieldError('customer_id'))
			}
			setUpdateStatus(dataToUpdate === undefined)		// create
		}

	}, [selectedCustomerId])

	useEffect(() => {
		if (selectedCustomer) {
			form.setFieldsValue({
				currency: selectedCustomer.billing_currency
			})
		}
	}, [selectedCurrency])


	const onFormSubmit = (values: Store) => {
		const cc = customers.find(c => c.id === values.customer_id)
		if (!cc) {
			console.warn('Missing current customer.')
			return
		}
		dataToUpdate
			? dispatch(
			updatePricelist(
				{
					id: dataToUpdate.id,
					counter: dataToUpdate.counter,
					name: values.name,
					description: values.description,
					valid_from: appSetting.renderDateSQL(values.valid_from),
					valid_to: appSetting.renderDateSQL(values.valid_to),
					customer_id: values.customer_id,
					currency: values.currency,
					parent_id: values.parent_id,
				},
				(suc: boolean) => {
					logger('--- billing.pricelist.updated')
					if (suc) {
						message.success(t('billing.pricelist.updated'))
						onClose && onClose()
						dispatch(getPricelists())
					}
				},
			),
			)
			: dispatch(
			createPricelist(
				{
					name: cc.company!.name ? cc.company!.name : values.description,
					description: values.description,
					valid_from: appSetting.renderDateSQL(values.valid_from),
					valid_to: appSetting.renderDateSQL(values.valid_to),
					customer_id: values.customer_id,
					currency: values.currency,
					parent_id: values.parent_id
				},
				(suc: boolean) => {
					if (suc) {
						message.success(t('billing.pricelist.created'))
						onClose && onClose()
						dispatch(getPricelists())
					}
				},
			),
		)
	}

	return (
		<Form
			{...formItemLayout}
			onFinish={onFormSubmit}
			form={form}
			onChange={() => setUpdateStatus(true)}
			initialValues={{
				...dataToUpdate,
				currency: selectedCurrency,
				parent_id: rootPricelist ? rootPricelist.id : null,
				valid_from: dataToUpdate?.valid_from ? moment(dataToUpdate?.valid_from) : '',
				valid_to: dataToUpdate?.valid_to ? moment(dataToUpdate?.valid_to) : '',
			}}>
			<Item name='customer_id'
				  rules={[{required: true, message: t('billing.pricelist.error.customer_id')}]}
				  label={t('billing.pricelist.customer_id')}
				  validateStatus={hasActivePricelist ? 'error' : ''}
				  help={hasActivePricelist ? t('billing.pricelist.error.hasActivePrice') : ''}>
				<Select
					showSearch
					allowClear
					options={custOptions}
					status={ hasActivePricelist ? 'error' : ''}
					optionFilterProp='label'
					filterOption={(val, opt) => {
						return !!opt && removeDiac(opt.label).includes(removeDiac(val))
					}}
					disabled={!!dataToUpdate}
					onChange={(value) => setSelectedCustomerId(value)}
					/>
			</Item>

			{selectedCustomerId !== 1 && (
				<Item name='parent_id'
					rules={[{required: form.getFieldValue('customer_id') !== 1, message: t('billing.pricelist.error.parent_id')}]}
					label={t('billing.pricelist.parent_id')}
					hasFeedback>
					<Select disabled={true}>
						{rootPricelist &&
							<Option key={rootPricelist.id} value={rootPricelist.id}>
								{rootPricelist.name}
							</Option>
						}
					</Select>
				</Item>
			)}

			<Item name='currency'
				rules={[{required: true, message: t('billing.pricelist.error.currency')}]}
				label={t('billing.pricelist.currency')}
				hasFeedback>
				<Select
					optionFilterProp='label'
					disabled={dataToUpdate && true}
					onChange={(value) => setSelectedCurrency(value)}
					options={customerCurrencies.map((currency) => ({label: currency, value: currency}))}
				/>
			</Item>

			<Item name='description' label={t('billing.pricelist.description')} hasFeedback>
				<Input />
			</Item>

			<Item name='valid_from' label={t('billing.pricelist.valid_from')}
				rules={[{required: true, message: t('billing.pricelist.error.valid_from')}]}
				hasFeedback>
				<DateInput htmlId='__valid_from'
						   initDateStr={dataToUpdate?.valid_from ? appSetting.renderDateSQL(dataToUpdate.valid_from) : appSetting.renderDateSQL(moment().toDate())}
						   disabled={!!dataToUpdate}
						   required={true}
						   format={appSetting.date_picker_format} // not Moment formats!!
						   onChange={(date) => {
							   form.setFieldsValue({valid_from: appSetting.renderDateSQL(date)})
						   }}
				/>
			</Item>

			<Item name='valid_to' label={t('billing.pricelist.valid_to')}>
				<DateInput htmlId='__valid_to'
						   initDateStr={dataToUpdate?.valid_to ? appSetting.renderDateSQL(dataToUpdate.valid_to) : ''}
						   disabled={isClosed(dataToUpdate)}
						   format={appSetting.date_picker_format} // not Moment formats!!
						   onChange={(date) => {
							   form.setFieldsValue({valid_to: appSetting.renderDateSQL(date)})
							   setUpdateStatus(true)
						   }}
				/>
			</Item>

			<Item {...tailLayout} shouldUpdate>
				{() => (
					<Button type='primary' htmlType='submit'
						className='login-form-button'
						disabled={hasActivePricelist || !!form.getFieldsError().filter(({errors}) => errors.length).length || !updateStatus}>
						{dataToUpdate ? t('billing.pricelist.update') : t('billing.pricelist.create')}
					</Button>
				)}
			</Item>
		</Form>
	)
}

export default PricelistForm
