import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Layout, Menu, MenuProps} from 'antd'
import {
    PieChartOutlined,
    TeamOutlined,
    ShoppingCartOutlined,
    DollarOutlined,
    PercentageOutlined,
    ShoppingOutlined,
    UserOutlined,
    InfoCircleOutlined,
    ShopOutlined,
    MailOutlined,
    TagOutlined,
    FileDoneOutlined,
    PaperClipOutlined,
    EuroOutlined,
    IdcardOutlined,
    AppstoreOutlined,
    ContainerOutlined,
    OneToOneOutlined,
    SettingOutlined,
    PicRightOutlined,
    GroupOutlined,
    ApartmentOutlined,
    PartitionOutlined,
    CodeSandboxOutlined,
    AccountBookOutlined,
    AppstoreAddOutlined,
    FunnelPlotOutlined,
    UserSwitchOutlined,
    ControlOutlined,
    BlockOutlined,
    TrophyOutlined,
    RadarChartOutlined,
    GlobalOutlined, DatabaseOutlined, PicCenterOutlined, PicLeftOutlined
} from '@ant-design/icons'
import {Theme} from '../ThemeSwitcher/models'
import {AppState} from 'common/models'
import {useTranslation} from 'react-i18next'
import './SideNav.scss'
import {_getRoles, _isAllowed, remLastSlash} from "../../common/fce";
import {useHistory} from "react-router";
import toggleMenu from "./actions/toggleMenu";
import {CloudServerOutlined, SafetyCertificateOutlined, SplitCellsOutlined} from "@ant-design/icons/lib/icons";
import LoggedUser, {
    MINIMAL_ROLE, ROLE_ACCESS_MANAGER, ROLE_APP_ADMIN, ROLE_BILLING_EDITOR, ROLE_BILLING_VIEWER,
    ROLE_COMPANY_MANAGER,
    ROLE_CUSTOMER_MANAGER, ROLE_CUSTOMER_SERVICE_EDITOR,
    ROLE_USER_MANAGER, ROLE_ZCOM_USER_MANAGER,
    SUPER_ROLE
} from "../../common/LoggedUser";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import {LoadingIndicator} from "../index";


const {Sider} = Layout

const SideNav = () => { // ant-menu-item-selected
    let location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {t} = useTranslation()

    const {auth} = useSelector((state: AppState) => state)
    const {self, menu_roles} = useSelector((state: AppState) => state.auth)
    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const {theme} = useSelector((state: AppState) => state.theme)
    const {authenticated, self_customer, self_settings} = useSelector((state: AppState) => state.auth)

    const [collapsed, setCollapsed] = useState(false)
    const [openKeys, setOpenKeys] = useState<string[]>()
    const pathName = location.pathname.split('/')
    const [domainName, setDomainName] = useState<string>('localhost')
    const [menuSelectedKey, setMenuSelectedKey] = useState<string>('/')
    const [menuOpenedKey, setMenuOpenedKey] = useState<string[]>([])

    useEffect(() => {
        // this is fix for hot reload error: https://github.com/facebook/create-react-app/issues/11771
        // window.process = { ...window.process, }

        if (window.location.hostname === 'localhost') {
            setDomainName('localhost')
        } else if (window.location.hostname === 'zistest.zcom.cz') {
            setDomainName('stage')
        } else if (window.location.hostname === 'zisdev.zcom.cz') {
            setDomainName('devel')
        } else if (window.location.hostname === 'zis.zcom.cz') {
            setDomainName('prod')
        } else {
            setDomainName('')
        }
    }, [])

    useEffect(() => {
        if (location) {
            // open and select menu based on url
            if( menuSelectedKey !== location.pathname ) {
                if (location.pathname.includes('/billing')) {
                    setMenuOpenedKey(['#billing'])
                    if (location.pathname.includes('/billing/dim')) {
                        setMenuOpenedKey(['#billing', '#dim-billing'])
                    }
                }
                if (location.pathname.includes('/inventory')) {
                    setMenuOpenedKey(['#inventory'])
                    if (location.pathname.includes('/inventory/dim')) {
                        setMenuOpenedKey(['#inventory', '#dim-inventory'])
                    }
                }
                if (location.pathname.includes('/rights/role')) {
                    setMenuOpenedKey(['#rights'])
                    setMenuSelectedKey('/rights/role')
                    return
                }
                if (location.pathname.includes('/rights/resource-group')) {
                    setMenuOpenedKey(['#rights'])
                    setMenuSelectedKey('/rights/resource-group')
                    return
                }
                if (location.pathname.includes('/rights/user-group')) {
                    setMenuOpenedKey(['#rights'])
                    setMenuSelectedKey('/rights/user-group')
                    return
                }
                if (location.pathname.includes('/rights/user')) {
                    setMenuOpenedKey(['#rights'])
                    setMenuSelectedKey('/rights/user')
                    return
                }
                if (location.pathname.includes('/rights')) {
                    setMenuOpenedKey(['#rights'])
                }
                if (location.pathname.includes('/ip/subnet')) {
                    setMenuSelectedKey('/ip/subnets')
                    return
                }
                if (location.pathname.includes('/vpsadmin')) {
                    setMenuOpenedKey(['#vpsadmin'])
                    return
                }
                setMenuSelectedKey(location.pathname);
            }
        }
    }, [location, menuSelectedKey]);

    const isNotProd = () => {
        // TODO remove it
      if (domainName === 'prod') {
          return false
      }
      return true
    }

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed)
    }

    const getRoles = (ctrlName: string): string[] => {
        // returns roles needed for displaying a control element
        if (menu_roles) {
            return _getRoles(ctrlName, menu_roles)
        }
        return []
    }

    const canShow = (ctrlName: string) => {
        if (self) {
            if (self.roles.includes(SUPER_ROLE)) {
                return true
            }

            const neededRoles = getRoles(ctrlName)
            if (!neededRoles || neededRoles.length === 0) {
                return false
            }
            return _isAllowed(neededRoles, self.roles)
        }
        return false
    }

    const isReady = () => {
        let ready = Boolean(authenticated && self && self.roles.length > 0 && self_customer && self_settings && self_settings.length > 0)
        if (ready) {
            // check companies table
            const company_roles: string[] = [
                ROLE_COMPANY_MANAGER,
                ROLE_CUSTOMER_MANAGER,
                ROLE_CUSTOMER_SERVICE_EDITOR,
                ROLE_ZCOM_USER_MANAGER,
                ROLE_ACCESS_MANAGER,
                ROLE_BILLING_VIEWER,
                ROLE_BILLING_EDITOR,
                ROLE_USER_MANAGER]
            for (const cr of company_roles) {
                if (self!.roles.includes(cr)) {
                    return companies && companies.length > 0
                }
            }
        }
        return ready
    }

    const onClick: MenuProps['onClick'] = e => {
        let path = e.key
        if (path && typeof path === 'string') {
            path = path.split('?')[0]
            path = remLastSlash(path)

            setMenuSelectedKey(path)
            if (path[0] === '/') {
                history.push(e.key)
            }
        }

        // HACK
        // refresh data on page when click on the same menu item
        let page = '/billing/companies'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/billing/customer'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/billing/pricelist'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/billing/invoice'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/billing/bill'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/rights/role'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/rights/resource-group'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/rights/user-group'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/rights/user'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/inventory'
        if (path === page && (remLastSlash(location.pathname) === page || location.pathname.includes('/inventory/'))) {
            dispatch(toggleMenu(page))
        }
        page = '/inventory/physical-servers'
        if (path === page && (location.pathname === page || location.pathname.includes('/inventory/physical-servers/'))) {
            dispatch(toggleMenu(page))
        }
        page = '/inventory/dim/locations'
        if (path === page && (location.pathname === page || location.pathname.includes('/inventory/dim/locations/'))) {
            dispatch(toggleMenu(page))
        }
        page = '/inventory/dim/types'
        if (path === page && (location.pathname === page || location.pathname.includes('/inventory/dim/types/'))) {
            dispatch(toggleMenu(page))
        }

        page = '/datacenter'
        if (path === page && (remLastSlash(location.pathname) === page || location.pathname.includes('/datacenter/'))) {
            dispatch(toggleMenu(page))
        }
        page = '/datacenter/rack'
        if (path === page && (remLastSlash(location.pathname) === page || location.pathname.includes('/datacenter/rack/'))) {
            dispatch(toggleMenu(page))
        }
        page = '/datacenter/block'
        if (path === page && (remLastSlash(location.pathname) === page || location.pathname.includes('/datacenter/block/'))) {
            dispatch(toggleMenu(page))
        }

        page = '/ip/subnets'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/dns/servers'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
        page = '/servers'
        if (path === page && location.pathname === page) {
            dispatch(toggleMenu(page))
        }
    };

    const onOpenChange = e => {
        setMenuOpenedKey(e)
    }

    type MenuItem = Required<MenuProps>['items'][number];
    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {key, icon, children, label, type} as MenuItem;
    }

    const stopPropagation = (e) => {
        // e.stopPropagation()
        e.preventDefault()
    }

    const getLink = (label, url) => {
        return <a href={url} onClick={stopPropagation}>{label}</a>
    }

    const createLeftSideMenu = () => {
        const loggedUser = new LoggedUser(auth)
        const items: MenuProps['items'] = [getItem(getLink(t('dashboardPage.title'), '/'), '/', <PieChartOutlined/>)]

        const view_users_groups = loggedUser.hasRole(ROLE_USER_MANAGER) || loggedUser.hasRole(ROLE_CUSTOMER_MANAGER) || loggedUser.hasRole(ROLE_ACCESS_MANAGER)
        const view_users_permissions = loggedUser.hasRole(ROLE_ACCESS_MANAGER) || loggedUser.hasRole(ROLE_APP_ADMIN)

        // Users and Groups
        const users_groups_children: MenuProps['items'] = []
        let item = getItem(getLink(t('usersPage.title'), '/users'), '/users', <UserOutlined/>)
        view_users_groups && users_groups_children.push(item)
        item = getItem(getLink(t('rights.users.title'), '/rights/user'), '/rights/user', <UserSwitchOutlined />)
        view_users_groups && users_groups_children.push(item)
        item = getItem(getLink(t('rights.userGroups.title'), '/rights/user-group'), '/rights/user-group', <BlockOutlined />)
        view_users_groups && users_groups_children.push(item)
        // Resources and Roles
        item = getItem(getLink(t('rights.resources.title'), '/rights/resource-group'), '/rights/resource-group', <AppstoreAddOutlined />)
        view_users_permissions && users_groups_children.push(item)
        item = getItem(getLink(t('rights.roles.title'), '/rights/role'), '/rights/role', <TrophyOutlined />)
        view_users_permissions && users_groups_children.push(item)
        view_users_groups && items.push(getItem(t('rights.title'), '#rights', <ControlOutlined />, users_groups_children))

        // Billing
        if (canShow('left_menu_billing')) {
            let children: MenuItem[] = []
            if (canShow('left_menu_billing_company')) {
                children.push(getItem(getLink(t('companiesPage.title'), '/billing/companies'), '/billing/companies', <IdcardOutlined/>))
            }

            if (canShow('left_menu_billing_customer')) {
                children.push(getItem(getLink(t('customerPage.title'), '/billing/customer'), '/billing/customer', <ShoppingCartOutlined/>))
            }

            if (canShow('left_menu_billing_pricelist')) {
                children.push(getItem(getLink(t('billing.pricelist.title'), '/billing/pricelist'), '/billing/pricelist', <EuroOutlined/>))
            }

            if (canShow('left_menu_billing_invoice')) {
                children.push(getItem(getLink(t('billing.invoice.title'), '/billing/invoice'), '/billing/invoice', <PaperClipOutlined/>))
            }

            if (canShow('left_menu_billing_bill')) {
                children.push(getItem(getLink(t('billing.bill.title'), '/billing/bill'), '/billing/bill', <FileDoneOutlined/>))
            }

            if (canShow('left_menu_billing_mail')) {
                children.push(getItem(getLink(t('billing.mail.title'), '/billing/mail'), '/billing/mail', <MailOutlined/>))
            }

            if (canShow('left_menu_billing_dimensions')) {
                children.push(getItem(t('billing.dimensions.title'), '#dim-billing', <TagOutlined/>,
                    [
                        getItem(getLink(t('billing.products.title'), '/billing/dim/products'), '/billing/dim/products',
                            <ShoppingOutlined/>),
                        getItem(getLink(t('costAllocationPage.title'), '/billing/dim/cost-allocation-category'), '/billing/dim/cost-allocation-category',
                            <AppstoreAddOutlined/>),
                        getItem(getLink(t('billing.servicetypes.title'), '/billing/dim/servicetypes'), '/billing/dim/servicetypes',
                            <ShopOutlined/>),
                        getItem(getLink(t('billing.mail_template.title'), '/billing/dim/mail-templates'), '/billing/dim/mail-templates',
                            <MailOutlined/>),
                        getItem(getLink(t('billing.tax.title'), '/billing/dim/taxes'), '/billing/dim/taxes',
                            <PercentageOutlined/>),
                        getItem(getLink(t('countries.title'), '/billing/dim/countries'), '/billing/dim/countries',
                            <AccountBookOutlined/>),
                    ]))
            }

            items.push(getItem(t('billing.title'), '#billing', <DollarOutlined/>, children))
        }
        // inventory
        const inventory_children: MenuProps['items'] = []
        item = getItem(getLink(t('inventory.menu.inventory'), '/inventory'), '/inventory/', <OneToOneOutlined />)
        canShow('left_menu_inventory') && inventory_children.push(item)
        item = getItem(getLink(t('physicalServersPage.menu.title'), '/inventory/physical-servers'), '/inventory/physical-servers', <GroupOutlined />)
        canShow('left_menu_inventory_physical') && inventory_children.push(item)

        item = getItem(t('inventory.menu.dimensions_title'), '#dim-inventory', <TagOutlined/>, [
            getItem(getLink(t('inventory.menu.rack_title'), '/datacenter/rack'), '/datacenter/rack', <PicCenterOutlined />),
            getItem(getLink(t('inventory.menu.datacenter_title'), '/datacenter'), '/datacenter', <DatabaseOutlined />),
            getItem(getLink(t('inventory.menu.location_title'), '/inventory/dim/locations'), '/inventory/dim/locations', <ShopOutlined/>),
            getItem(getLink(t('inventory.menu.inventory_type_title'), '/inventory/dim/types'), '/inventory/dim/types', <SplitCellsOutlined />)

        ])
        canShow('left_menu_inventory_dim') && inventory_children.push(item)
        canShow('left_menu_inventory_menu') && items.push(getItem(t('inventory.menu.title'), '#inventory', <OneToOneOutlined />, inventory_children))

        // Servers
        canShow('left_menu_server') &&
        items.push(getItem(getLink(t('serversPage.title'), '/servers'), '/servers', <CloudServerOutlined />))

        // IP
        canShow('left_menu_ip') &&
        items.push(getItem(getLink(t('ipSubnetPage.title'), '/ip/subnets'), '/ip/subnets', <PartitionOutlined />))

        // DNS
        canShow('left_menu_dns_servers') &&
        items.push(getItem(getLink(t('dnsPage.title'), '/dns/servers'), '/dns/servers', <GlobalOutlined />))

        // Cert
        canShow('left_menu_cert') &&
        items.push(getItem(getLink(t('certificatesPage.title'), '/certificates'), '/certificates', <SafetyCertificateOutlined />))

        // RDB
        canShow('left_menu_rdb') &&
        items.push(getItem(getLink(t('databasesPage.title'), '/databases'), '/databases', <DatabaseOutlined />))


        // VPS Admin
        const vpsa_children: MenuProps['items'] = []
        item = getItem(getLink(t('vpsadmin.hypervisors.title'), '/vpsadmin/hypervisors'), '/vpsadmin/hypervisors', <CodeSandboxOutlined />)
        canShow('page_vpsadmin_hypervisor') && vpsa_children.push(item)
        item = getItem(getLink(t('vpsadmin.vps.title'), '/vpsadmin/vps'), '/vpsadmin/vps', <PicRightOutlined />)
        canShow('page_vpsadmin_vps') && vpsa_children.push(item)
        item = getItem(getLink(t('vpsadmin.setting.title'), '/vpsadmin/setting'), '/vpsadmin/setting', <SettingOutlined />)
        canShow('page_vpsadmin_setting') && vpsa_children.push(item)
        canShow('left_menu_vpsadmin') && items.push(getItem(t('vpsadmin.title'), '#vpsadmin', <CodeSandboxOutlined />, vpsa_children))

        // canShow('left_menu_app_settings') && items.push(getItem(getLink(t('settingsPage.title'), '/settings'), '/settings', <SettingOutlined />))
        canShow('left_menu_logviewer') && items.push(getItem(getLink(t('logViewer.menu_title'), '/logviewer'), '/logviewer', <FunnelPlotOutlined />))
        items.push(getItem(getLink(t('statusPage.title'), '/status'), '/status', <InfoCircleOutlined/>))
        return items
    }

    const createAnonymMenu = () => {
        const items: MenuProps['items'] = [getItem(t('loginPage.login'), '/login', <UserOutlined/>)]
        items.push(getItem(t('statusPage.title'), '/status', <InfoCircleOutlined/>))
        return items
    }

    const items: MenuProps['items'] = isReady() ? createLeftSideMenu() : createAnonymMenu()

    return (
        <Sider
            collapsible
            width={220}
            collapsed={collapsed}
            onCollapse={onCollapse}
            className='SideNav'
            theme={theme === Theme.DEFAULT ? 'light' : 'dark'}>
            <Link to='/' className='iconLink'>
                <div className={`logo ${theme === Theme.DEFAULT ? 'light' : 'dark'}`}/>
            </Link>
            <div className='appVersion'>
                {
                    window.location.hostname != 'localhost' &&
                    document.querySelector('meta[name="version"]')?.getAttribute('content')
                }
            </div>
            <Menu
                onClick={onClick}
                onOpenChange={onOpenChange}
                selectedKeys={[menuSelectedKey]}
                openKeys={menuOpenedKey}
                mode="inline"
                className="LeftSideMenu"
                theme={theme === Theme.DEFAULT ? 'light' : 'dark'}
                items={items}
            />
        </Sider>
    )
}

export default SideNav
