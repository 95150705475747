import {
    Badge,
    Button,
    Col, Collapse,
    Divider,
    Dropdown,
    Form,
    Input,
    Layout,
    Menu,
    MenuProps, message, Modal,
    Row, Select,
    Space,
    Spin,
    Table, Tooltip
} from "antd";
import {useHistory, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import React, {useEffect, useState} from "react";
import {
    CaretDownOutlined, CheckCircleOutlined, DeleteTwoTone,
    EditTwoTone, ExclamationCircleOutlined, FilePdfOutlined, MenuOutlined,
    PaperClipOutlined, PlusCircleTwoTone,
} from "@ant-design/icons";
import './EditInvoicePage.scss';
import {ColumnsType} from "antd/es/table";
import {InvoiceDetails, InvoiceItemDetails, NoteInvoicePayload} from "./models";
import moment from "moment";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import TextArea from "antd/lib/input/TextArea";
import {Link} from "react-router-dom";
import {protectedApiClient} from "../../../helpers/api";
import getInvoice from "./actions/getInvoice";
import {CustomerDetails} from "../customer/models";
import {CompanyDetails} from "../../company/models";
import getInvoiceDocumentByInvoice from "./actions/getInvoiceDocumentByInvoice";
import InvoiceAttachment from "../../../components/Invoice/InvoiceAttachments";
import {getDownloadName} from "./InvoicePage";
import createInvoiceMail from "./actions/createInvoiceMail";
import SendMailForm from "./SendMailForm";
import InvoiceItemForm from "./InvoiceItemForm";
import generateInvoice from "./actions/generateInvoice";
import postCreditNote from "./actions/postCreditNote";
import repairInvoice from "./actions/repairInvoice";
import finalizeDocument from "./actions/finalizeInvoice";
import History2 from "../../../components/History/History2";
import InvoiceCostAllocationPage from "../invoicecostallocation/InvoiceCostAllocationPage";
import updateInvoice from "./actions/updateInvoice";
import updateNote from "./actions/updateNote";
import deleteInvoiceItem from "./actions/deleteInvoiceItem";
import HistoryLog from "../../../components/History/HistoryLog";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {sort_num, sort_position, sort_version} from "../../../common/sorting";
import updateDateOfPayment from "./actions/updateDateOfPayment";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import DateInput from "../../../components/RangeFilter/DateInput";
import deleteInvoice from "./actions/deleteInvoice";


const { Panel } = Collapse;
const { confirm } = Modal

interface ParamTypes {
    id: string
}

interface VatItem {
    id: number
    rate: number
    name: string
    total: number
}


const EditInvoicePage = () => {
    const CONTROL_NAME = 'page_billing_invoice'
    const {id} = useParams<ParamTypes>()
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const history = useHistory()
    const dispatch = useDispatch()

    const {taxes} = useSelector((state: AppState) => state.tax)
    const {invoice, isLoading: isInvoiceLoading, isSaving} = useSelector((state: AppState) => state.invoice)
    const {companies, customers} = useSelector((state: AppState) => state.auth.tables)

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetails>()
    const [selectedCompany, setSelectedCompany] = useState<CompanyDetails>()
    const [isFinalized, setIsFinalized] = useState<boolean>(false)
    const [shift, setShift] = useState<number>(0)
    const [downloading, setDownloading] = useState<boolean>(false)
    const [isSavingNote, setIsSavingNote] = useState<boolean>(false)
    const [previewLoading, setPreviewLoading] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>('')
    const [invoiceColor, setInvoiceColor] = useState<string>('')
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [sendMailVisible, setSendMailVisible] = useState<boolean>(false)
    const [invoiceItemVisible, setInvoiceItemVisible] = useState(false)
    const [invoiceItemId, setInvoiceItemId] = useState<number>(0)
    const [invoiceDetail, setInvoiceDetail] = useState<number>(2)
    const [dataToUpdate, setDataToUpdate] = useState({})
    const [historyModal, setHistoryModal] = useState<boolean>(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [forceUpdateId, setForceUpdateId] = useState<number>(1)
    const [payDate, setPayDate] = useState<Date | undefined>(undefined)
    const [taxDate, setTaxDate] = useState<Date | undefined>(undefined)
    const [maturityDate, setMaturityDate] = useState<Date | undefined>(undefined)
    const [issueDate, setIssueDate] = useState<Date | undefined>(undefined)
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemSource, setItemSource] = useState<InvoiceItemDetails[]>()
    const [vatItems, setVatItems] = useState<VatItem[]>([]);

    const [openDelete, setOpenDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [isViewer, setViewer] = useState(false)
    const [isEditor, setEditor] = useState(false)

    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // logger
    const logger = (msg, obj:any=null) => { if (appSetting && appSetting.debug) {obj ? console.log('EditInvoicePage: ' + msg + ' > ' + JSON.stringify(obj)) : console.log('EditInvoicePage: ' + msg)} }

    useEffect(() => {
        // fetch invoice
        setEditor(loggedUser.hasAccess('page_billing_invoice_edit_button'))
        const viewer = loggedUser.hasAccess('page_billing_invoice_detail')
        setViewer(viewer)
        if (!viewer) {
            // go to invoices
            history.replace('/billing/invoice')
        }
        if (id) {
            const iid = parseInt(id)
            if (iid > 0) {
                dispatch(getInvoice(iid, suc => {
                    // failover 404
                    !suc && history.replace('/billing/invoice')
                }))
                return // ok
            }
        }
        // failover 404
        history.replace('/billing/invoice')

    }, [id])

    useEffect(() => {
        // fetch additional data
        if (invoice && invoice.id === parseInt(id)) {
            const customer = customers.find(c => c.id === invoice.customer_id)
            if (!customer) {
                console.error('Missing customer!  customer_id='+invoice.customer_id)
                message.error('Missing customer!')
                return
            }
            setSelectedCustomer(customer)
            const company = companies.find(c => c.id === customer.company_id)
            if (!company) {
                console.error('Missing company!  company_id='+customer.company_id)
                message.error('Missing company!')
                return
            }
            setSelectedCompany(company)

            invoice.invoiceItems && setItemSource(invoice.invoiceItems.sort(sort_position))
            setInvoiceDetail(invoice.invoice_detail)
            if (invoice.date_of_payment) {
                setPayDate(new Date(invoice.date_of_payment))
            }
            if (invoice.date_of_taxing) {
                setTaxDate(new Date(invoice.date_of_taxing))
            }
            if (invoice.date_of_maturity) {
                setMaturityDate(new Date(invoice.date_of_maturity))
            }
            if (invoice.date_of_issue) {
                setIssueDate(new Date(invoice.date_of_issue))
            }
            setIsFinalized(invoice.state === 2)
            const n = invoice.state === 2 ? 0 : 3
            setShift(n)
            const formData = {
                ...invoice,
                date_of_issue: invoice.date_of_issue && moment(invoice.date_of_issue),
                date_of_taxing: invoice.date_of_taxing && moment(invoice.date_of_taxing),
                date_of_maturity: invoice.date_of_maturity && moment(invoice.date_of_maturity),
                date_of_payment: invoice.date_of_payment && moment(invoice.date_of_payment),
                period_from: invoice.period_from && moment(invoice.period_from),
                period_to: invoice.period_to && moment(invoice.period_to),
                period: [invoice.period_from && moment(invoice.period_from), invoice.period_to && moment(invoice.period_to)],
            }
            countVatItems()
            setDataToUpdate(formData)
            form.setFieldsValue({ ...formData, internal_note: invoice.internal_note })
            dispatch(getInvoiceDocumentByInvoice(invoice.id))
            setHistoryModelId(invoice.id)
            setInvoiceColor(invoice.state === 2 ? 'invoice-finalized' : 'invoice-prepared')
        }
    }, [invoice])

    useEffect(() => {
        if (invoice && invoice.id > 0 && invoice.state === 2) {
            const sdt = payDate ? appSetting.renderDateSQL(payDate) : ''
            let doUpdate = false
            if (!invoice.date_of_payment && sdt) {
                doUpdate = true
            }
            if (invoice.date_of_payment && !sdt) {
                doUpdate = true
            }
            if (invoice.date_of_payment && sdt && invoice.date_of_payment != sdt) {
                doUpdate = true
            }
            if (doUpdate) {
                let payload = {id: invoice.id, date_of_payment: sdt}
                dispatch(updateDateOfPayment(payload, (suc) => {
                    loadHistory()
                    suc && message.success(t('billing.invoice.was_paid'))
                }))
            }
        }
    }, [payDate])

    useEffect(() => {
        if (invoice && taxDate) {
            const dt = appSetting.renderDateSQL(taxDate)
            let data = form.getFieldsValue()
            form.setFieldsValue({ ...data, date_of_taxing: dt })
        }
    }, [taxDate])

    useEffect(() => {
        if (invoice && maturityDate) {
            const dt = appSetting.renderDateSQL(maturityDate)
            let data = form.getFieldsValue()
            form.setFieldsValue({ ...data, date_of_maturity: dt })
        }
    }, [maturityDate])

    useEffect(() => {
        if (invoice && issueDate) {
            const dt = appSetting.renderDateSQL(issueDate)
            let data = form.getFieldsValue()
            form.setFieldsValue({ ...data, date_of_issue: dt })
        }
    }, [issueDate])

    const countVatItems = () => {

        if (!invoice) {
            setVatItems([])
            return
        }
        const vats:VatItem[] = []
        invoice.customer_id
        getCurrentTaxes().forEach(function (t) {
            let taxItem = {
                id: t.id,
                rate: t.rate,
                name: appSetting.renderPercent(t.rate),
                total: 0
            }
            vats.push(taxItem)
        })

        vats.forEach(function (taxItem) {
            invoice.invoiceItems.forEach(function (invoiceItem) {
                if (invoiceItem && invoiceItem.total_vat && invoiceItem.tax_id === taxItem.id) {
                    taxItem.total = taxItem.total + invoiceItem.total_vat
                }
            })
        })
        setVatItems(vats)
        logger('countVatItems2', vats)
    }

    const getCurrentTaxes = () => {
        return taxes
            .filter( t => t.country.toLowerCase().includes('cz') )
            .filter(t2 => moment().isBetween(t2.valid_from, t2.valid_to ? t2.valid_to : moment(), undefined, '[]'))
            .sort((a, b) => sort_num(a.rate, b.rate))
    }

    const pdfPreview = () => {
        // download and show preview PDF
        if (invoice) {
            setPreviewVisible(true)
            setPreviewLoading(true)
            protectedApiClient.get<string>(`/billing/invoice/${invoice.id}/preview`)
                .then(response => {
                    setPreviewLoading(false)
                    setPreview(response.data)
                })
                .catch(error => {
                    message.error(t('billing.invoice.preview_fail'))
                    setPreviewLoading(false)
                })
        }
    }

    const handleDownload = (version: number, id: number) => {
        if (invoice) {
            setDownloading(true)
            protectedApiClient.get<string>(`/billing/invoice-document/${id}/content`, {
                onDownloadProgress: progressEvent => {
                }
            })
                .then(response => {
                    const downloadLink = document.createElement("a")
                    downloadLink.href = `data:application/pdf;base64,${response.data}`
                    downloadLink.download = getDownloadName(invoice, version)
                    downloadLink.click()
                    setDownloading(false)
                })
                .catch(error => {
                    console.log(error)
                    message.error(error.message)
                    setDownloading(false)
                })
        }
    }

    const handleGenerateInvoice = () => {
        if (invoice && invoice.id) {
            dispatch(generateInvoice({
                id: invoice.id,
                invoice_detail: invoiceDetail
            }, suc => {
                if(suc) {
                    loadHistory()
                    message.success(t('billing.invoice.generated'), 2)
                }
            }))
        }
    }

    const creditNote = () => {
        if (invoice && invoice.id) {
            Modal.confirm({
                content: t('billing.invoice.creditNote_title'),
                okText: t('billing.invoice.creditNote_btn'),
                cancelText: t('billing.invoice.onHide'),
                onOk() {
                    dispatch(postCreditNote({id: invoice.id},
                        progress => {},
                        (suc, id) => {
                            Modal.confirm({
                                content: t('billing.invoice.creditNote'),
                                okText: t('billing.invoice.seeCreditNote'),
                                cancelText: t('billing.invoice.onHide'),
                                onOk() {
                                    history.replace('/billing/invoice/edit/'+id)
                                },
                                icon: <CheckCircleOutlined/>,
                            });
                        }))
                },
                icon: <CheckCircleOutlined/>,
            });
        }
    }

    const repair = () => {
        if (invoice && invoice.id) {
            dispatch(repairInvoice({id: invoice.id}, suc => {
                if (suc) {
                    loadHistory()
                    message.success("Invoice Repaired")
                }
            }))
        }
    }

    const finalize = () => {
        if (invoice && invoice.id) {
            dispatch(finalizeDocument({id: invoice.id}, suc => {
                if (suc) {
                    loadHistory()
                    message.success(t('billing.invoice.finalize'))
                }
            }))
        }
    }

    const loadHistory = () => {
        setForceUpdateId(forceUpdateId+1)	// update history
    }

    const saveNote = () => {
        if (!invoice || !invoice.id) {
            return
        }
        const params: NoteInvoicePayload = {id: invoice.id, internal_note: form.getFieldValue('internal_note')}
        setIsSavingNote(true)
        dispatch(updateNote(params, (suc) => {
            setIsSavingNote(false)
            if (suc) {
                loadHistory()
                message.success(t('billing.invoice.invoice-note-updated'))
            }
        }))
    }

    const onMenuClick: MenuProps['onClick'] = e => {
        switch (e.key) {
            case 'finalized':
                finalize()
                break
            case 'repair':
                repair()
                break
            case 'delete':
                showConfirmDelete()
                break
            case 'send':
                handleSendMail('mail_invoice')
                break
            case 'reminder':
                handleSendMail('mail_invoice_reminder')
                break
            case 'credit':
                creditNote()
                break
        }
    }

    // state= 1  => Dokončit
    // state= 2  => Opravit již dokončenou fakturu, Odeslat fakturu, Vytvořit dobropis
    // state= 2+sent => Opravit již dokončenou fakturu, Poslat 1. upomínku, Vytvořit dobropis
    const actionMenu = () => {
        if (!invoice) {
            return (
                <Menu onClick={onMenuClick}>error</Menu>
            )
        }
        if (!isEditor) {
            return (
                <Menu onClick={onMenuClick}>Read Only</Menu>
            )
        }

        if (!isFinalized) {
            // invoice PREPARED
            return (
                <Menu onClick={onMenuClick}>
                    <Menu.Item key="finalized">{ isInvoice(invoice) ? t('billing.invoice.finalized_invoice') : t('billing.invoice.finalized_invoice_credit')}</Menu.Item>
                    <Menu.Item key="delete">{t('billing.invoice.delete_invoice')}</Menu.Item>
                </Menu>
            )
        }
        else {
            // invoice FINALIZED
            return (
                <Menu onClick={onMenuClick}>
                    <Menu.Item key="finalized" disabled>{ isInvoice(invoice) ? t('billing.invoice.finalized_invoice') : t('billing.invoice.finalized_invoice_credit')}</Menu.Item>
                    <Menu.Item key="repair">{t('billing.invoice.unlock_invoice')}</Menu.Item>
                    <Menu.Item key="send">{t('billing.invoice.send_invoice')}</Menu.Item>
                    <Menu.Item key="delete" disabled>{t('billing.invoice.delete_invoice')}</Menu.Item>
                    {
                        isInvoice(invoice) &&
                        <>
                            <Menu.Item key="reminder">{t('billing.invoice.send_reminder')}</Menu.Item>
                            <Menu.Item key="credit">{t('billing.invoice.credit_note')}</Menu.Item>
                        </>
                    }
                </Menu>
            )
        }
    }

    const handleSendMail = (templateName: string) => {
        if (invoice) {
            // create an email on server
            // and opens a mail-dialog
            setSendMailVisible(true)
            dispatch(createInvoiceMail({template_name: templateName, id: invoice.id},
                suc => {
                    if (suc) {
                        loadHistory()
                    } else {
                        message.error(t('billing.invoice.create_mail_error'))
                    }
                })
            )
        }
    }

    const showConfirmDelete = () => {
        if (!invoice) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined className='red' />,
                title: t('billing.invoice.confirm_delete_invoice'),
                content: <p>{invoice.number ? invoice.number : t('billing.invoice.day_at') + ': ' + invoice.date_of_issue}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteInvoice(invoice.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                            history.replace('/billing/invoice')
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const showConfirmDeleteItem = (item: InvoiceItemDetails, title: string) => {
        if (!invoice || !item) {
            return
        }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{item && item.name}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteInvoiceItem(item.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            dispatch(getInvoice(item.invoice_id))
                            loadHistory()
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const handleSubmit = (values) => {
        // save changes in the invoice
        if (invoice) {
            const invoiceToSave = {
                ...values,
                id: invoice.id,
                date_of_issue: appSetting.renderDateSQL(values.date_of_issue),
                date_of_taxing: appSetting.renderDateSQL(values.date_of_taxing),
                date_of_maturity: appSetting.renderDateSQL(values.date_of_maturity),
                internal_note: values.internal_note,
                items_text_sufix: values.items_text_sufix
            }
            logger('handleSubmit: ', invoiceToSave)
            dispatch(updateInvoice(invoiceToSave, (suc) => {
                    if (suc) {
                        loadHistory()
                        message.success(<>{t('billing.invoice.updated')}</>)
                    }
                }
            ))
        }
    }

    const onChangePanel = (keys: string | string[]) => {
        let isHistory = false
        if (typeof keys === 'string') {
            keys = [keys]
        }
        for (const key of keys) {
            if (key === 'history') {
                isHistory = true
            }
        }
        if (isHistory) {
            loadHistory()
        }
    }

    const getPriceType = (record) => {
        if (!record || !record.calculation_type) {
            return 'price'
        }
        if (record.calculation_type.toUpperCase() === 'FIX') {
            return 'priceFix'
        }
        if (record.calculation_type.toUpperCase() === 'UNIT') {
            return 'priceUnit'
        }
        if (record.calculation_type.toUpperCase() === 'RANGE') {
            return 'priceRange'
        }
        return 'price'
    }


    const baseColumns: ColumnsType<InvoiceItemDetails> = [
        {
            title: t('billing.invoice.name'),
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (name, record) => {
                if (!record.parent_id) {
                    return (<span style={{marginLeft: '0'}}>{name}</span>)
                }
                return (<span style={{marginLeft: '15px'}}>{name}</span>)
            }
        },
        {
            title: t('billing.invoice.from'), dataIndex: 'period_from', key: 'period_from',
            align: 'center',
            render: text => appSetting.renderDate(text)
        },
        {
            title: t('billing.invoice.to'),
            dataIndex: 'period_to',
            key: 'period_to',
            align: 'center',
            render: text => appSetting.renderDate(text)
        },
        {
            title: t('billing.invoice.unit-count'),
            dataIndex: 'unit_count',
            key: 'unit_count',
            align: 'center',
            render: count => appSetting.renderCount(count)
        },
        {
            title: t('billing.invoice.unit'), dataIndex: 'unit', key: 'unit', align: 'center'
        },
        {
            title: t('billing.invoice.unit-price'),
            dataIndex: 'price_per_unit',
            key: 'price_per_unit',
            className: 'right',
            render: (price, record) => {
                if (!record) {
                    return ''
                }
                return (<div className={getPriceType(record)}>{appSetting.renderPrice(price)}</div>)
            },
        },
        {
            title: t('billing.invoice.tax'),
            dataIndex: 'tax_id',
            key: 'tax_id',
            align: 'center',
            render: text => {
                const tax = taxes?.filter(
                    v => moment().isBetween(v.valid_from, v.valid_to ? v.valid_to : moment(), undefined, '[]') && v.country.toLowerCase().includes('cz')
                ).find(item => item.id === text)
                return appSetting.renderPercent(tax?.rate)
            }
        },
        {
            title: t('billing.invoice.no-vat'),
            dataIndex: 'total_without_vat',
            key: 'total_without_vat',
            className: 'right',
            render: price => appSetting.renderPrice(price)
        },
        {
            title: t('billing.invoice.total-vat'),
            dataIndex: 'total_vat',
            key: 'total_vat',
            className: 'right',
            render: price => appSetting.renderPrice(price)
        },
        {
            title: t('billing.invoice.total-with-vat'),
            dataIndex: 'total_with_vat',
            key: 'total_with_vat',
            className: 'right',
            render: price => appSetting.renderPrice(price)
        }
    ]

    const handleSelectDetail = (v, e) => {
        setInvoiceDetail(parseInt(v))
    }

    if (!invoice || !selectedCustomer || !selectedCompany) {
        // loading invoice
        return <Spin />
    }

    const columns: ColumnsType<InvoiceItemDetails> = invoice.state === 2 ? baseColumns : [...baseColumns, {
        title: t('billing.invoice.action'),
        key: 'action',
        width: '70px',
        showSorterTooltip: false,
        dataIndex: 'action',
        render: (_: string, record: InvoiceItemDetails) => (
            <Space size={1}>
                {
                    isEditor && (
                        <Button
                            type='text'
                            size='small'
                            onClick={() => {
                                setInvoiceItemVisible(true)
                                setInvoiceItemId(record.id)
                            }}
                            icon={<EditTwoTone twoToneColor='green' />}
                        />
                    )
                }
                {
                    isEditor && (
                            <Button type='text' danger
                                    size='small'
                                    onClick={() => showConfirmDeleteItem(record, t('inventoryPage.confirm_delete_item'))}
                                    className='actionButton'
                                    icon={<DeleteTwoTone twoToneColor='red' />} />

                    )
                }

                {
                    !isEditor && (
                        <Button type='text' size='small'
                                disabled={true} icon={<EditTwoTone twoToneColor='gray' />}
                        />
                    )
                }

            </Space>
        ),
    }]

    const isInvoice = (inv: InvoiceDetails) => {
        return invoice.total_with_vat >= 0
    }

    const payButton = ():JSX.Element => {
        if (!payDate) {
            return (<Button size='small'>{t('billing.invoice.btn_set')}</Button>)
        }
        return (
            <span>
                <span className='value' style={{float: 'left'}}>{appSetting.renderDate(invoice.date_of_payment)}</span>&nbsp;<Button size='small'>{t('billing.invoice.btn_set')}</Button>
            </span>
        )
    }

    const taxButton = ():JSX.Element => {
        if (!taxDate) {
            return (<Button size='small'>{t('billing.invoice.btn_set')}</Button>)
        }
        return (
            <Button size='small'>{appSetting.renderDate(taxDate)}</Button>
        )
    }


    const issueButton = ():JSX.Element => {
        if (!issueDate) {
            return (<Button size='small'>{t('billing.invoice.btn_set')}</Button>)
        }
        return (
            <Button size='small'>{appSetting.renderDate(issueDate)}</Button>
        )
    }

    const maturityButton = ():JSX.Element => {
        if (!maturityDate) {
            return (<Button size='small'>{t('billing.invoice.btn_set')}</Button>)
        }
        return (
            <Button size='small'>{appSetting.renderDate(maturityDate)}</Button>
        )
    }


    return (
        <>
            <Layout className='EditInvoicePage'>

                <Form form={form}
                      labelCol={{flex: '1'}}
                      wrapperCol={{flex: '1'}}
                      autoComplete='off'
                      onFinish={handleSubmit}
                      initialValues={ dataToUpdate }
                      validateMessages={{required: 'Required'}}
                >
                    <Row gutter={[8, 8]}>
                        <Col flex={8} className='invoiceHeader' style={{paddingBottom: 0}}><PaperClipOutlined/> &nbsp; { isInvoice(invoice) ? t('billing.invoice.invoice-details') : t('billing.invoice.invoice-details-credit')}</Col>
                        <Col flex={8} className='invoiceHeader' style={{paddingBottom: 0}}>&nbsp;</Col>
                        <Col flex={8} className='invoiceHeader' style={{textAlign: 'right'}}>
                            { isInvoice(invoice) ? t('billing.invoice.invoice-number') : t('billing.invoice.invoice-number-credit')}: &nbsp;
                            <span className='invoiceNumber'>{invoice.number ? invoice.number : '____'}</span>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]} className={invoiceColor}>
                        <Col span={4} className='right'>
                            <div><label>{t('companiesPage.name')}:</label></div>
                            <div><label>{t('companiesPage.ico')}:</label></div>
                            <div><label>{t('companiesPage.country')}:</label></div>
                            <div><label>{t('companiesPage.vat_payer')}:</label></div>
                            <div><label>{t('customerPage.billing_currency')}:</label></div>
                        </Col>
                        <Col span={10}>
                            <div className='companyName'>{invoice.customer_name}</div>
                            <div><span className='value'>{invoice.customer_company_number ? invoice.customer_company_number : ' - '} </span></div>
                            <div><span className='value'>{invoice.customer_country}</span></div>
                            <div><span className='value'>{selectedCompany?.vat_payer ? t('general.yes') : t('general.no')}</span></div>
                            <div><span className='value'>{invoice.currency}</span></div>
                        </Col>
                        <Col span={10} style={{borderLeft: '1px solid #dcdcdc'}}>
                            {   // date_of_issue
                                isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}
                                             className='right'><label>{t('billing.invoice.date_of_issue')}:</label></Col>
                                        <Col span={12}><span
                                            className='value'>{appSetting.renderDate(invoice.date_of_issue)}</span></Col>
                                    </Row>
                                )
                            }
                            {
                                !isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Form.Item label={t('billing.invoice.date_of_issue')}
                                                   name='date_of_issue'
                                                   rules={[{required: true}]}
                                                   style={{width: '100%', marginBottom: '0'}}
                                                   labelCol={{span: 12}}
                                                   wrapperCol={{span: 12}}>
                                            <DateInput htmlId='__date_of_issue'
                                                       initDateStr={appSetting.renderDateSQL(issueDate)}
                                                       disabled={!isEditor}
                                                       required={true}
                                                       format={appSetting.date_picker_format} // not Moment formats!!
                                                       // customInput={issueButton()}
                                                       onChange={(date) => {
                                                           date && form.setFieldsValue({date_of_issue: appSetting.renderDateSQL(date)})
                                                           date &&  setIssueDate(new Date(date))
                                                       }}
                                            />
                                        </Form.Item>
                                    </Row>
                                )
                            }

                            {   // date_of_maturity
                                isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}
                                             className='right'><label>{t('billing.invoice.date_of_maturity')}:</label></Col>
                                        <Col span={12}><span
                                            className='value'>{appSetting.renderDate(invoice.date_of_maturity)}</span></Col>
                                    </Row>
                                )
                            }
                            {
                                !isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Form.Item label={t('billing.invoice.date_of_maturity')}
                                                   name='date_of_maturity'
                                                   rules={[{required: true}]}
                                                   style={{width: '100%', marginBottom: '0'}}
                                                   labelCol={{span: 12}}
                                                   wrapperCol={{span: 12}}>
                                            <DateInput htmlId='__date_of_maturity'
                                                       initDateStr={appSetting.renderDateSQL(maturityDate)}
                                                       disabled={!isEditor}
                                                       required={true}
                                                       format={appSetting.date_picker_format} // not Moment formats!!
                                                        // customInput={maturityButton()}
                                                       onChange={(date) => {
                                                           date && form.setFieldsValue({date_of_maturity: appSetting.renderDateSQL(date)})
                                                           date &&  setMaturityDate(new Date(date))
                                                       }}
                                            />
                                        </Form.Item>
                                    </Row>
                                )
                            }

                            {   // date_of_taxing
                                isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Col span={12} className='right'>
                                            <label>{t('billing.invoice.date_of_taxing')}:</label>
                                        </Col>
                                        <Col span={12}>
                                            <span className='value'>{appSetting.renderDate(invoice.date_of_taxing)}</span>
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                !isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Form.Item label={t('billing.invoice.date_of_taxing')}
                                                   name='date_of_taxing'
                                                   rules={[{required: true}]}
                                                   style={{width: '100%', marginBottom: '0'}}
                                                   labelCol={{span: 12}}
                                                   wrapperCol={{span: 12}}>
                                            <DateInput htmlId='__date_of_taxing'
                                                       initDateStr={appSetting.renderDateSQL(taxDate)}
                                                       disabled={!isEditor}
                                                       required={true}
                                                       format={appSetting.date_picker_format} // not Moment formats!!
                                                    // customInput={issueButton()}
                                                       onChange={(date) => {
                                                           date && form.setFieldsValue({date_of_taxing: appSetting.renderDateSQL(date)})
                                                           date && setTaxDate(new Date(date))
                                                       }}
                                            />
                                        </Form.Item>
                                    </Row>
                                )
                            }

                            {   // date_of_payment
                                !isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Col span={12} className='right'>
                                            <label>{t('billing.invoice.date_of_payment')}:</label>
                                        </Col>
                                        <Col span={12}><span className='value'> &nbsp;</span></Col>
                                    </Row>
                                )
                            }
                            {
                                isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Col span={12} className='right'>
                                            <label>{t('billing.invoice.date_of_payment')}:</label>
                                        </Col>
                                        <Col span={12}>
                                            {
                                                isEditor && (
                                                    <DateInput htmlId='__date_of_payment'
                                                        initDateStr={appSetting.renderDateSQL(payDate)}
                                                        disabled={!isEditor}
                                                        format={appSetting.date_picker_format} // not Moment formats!!
                                                        // customInput={payButton()}
                                                        onChange={(date) => {
                                                            date && form.setFieldsValue({date_of_payment: appSetting.renderDateSQL(date)})
                                                            date ? setPayDate(new Date(date)) : setPayDate(undefined)
                                                        }}
                                                    />
                                                )
                                            }
                                            {
                                                !isEditor && invoice.date_of_payment && (
                                                    <span className='value' style={{float: 'left'}}>{appSetting.renderDate(invoice.date_of_payment)}</span>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                )
                            }
                        </Col>
                    </Row>

                    {
                        invoice && invoice.period_from && (
                            <Row gutter={[8, 8]} style={{borderTop: '1px solid #dcdcdc'}}>
                                <Col span={4} className='right' style={{paddingTop: '16px'}}>
                                    {
                                        isInvoice(invoice) && <>{t('billing.invoice.period')}:</>
                                    }
                                </Col>
                                <Col span={8} style={{paddingTop: '16px'}}>
                                    {
                                        isInvoice(invoice) && (
                                            <span className='period'>{appSetting.renderDate(invoice?.period_from)}{' - '}{appSetting.renderDate(invoice?.period_to)}</span>
                                        )
                                    }
                                </Col>
                                <Col span={12} className='center'>
                                    {
                                        !isFinalized && isInvoice(invoice) && (
                                            <Row>
                                                <Col flex={2} className='right'>
                                                    {t('billing.invoice.invoice_detail')}:
                                                    <Select
                                                        optionFilterProp='label'
                                                        options={[
                                                            ({label: t('billing.invoice.single_item'), value: 0}),
                                                            ({label: t('billing.invoice.group_item'), value: 1, disabled: true}),
                                                            ({label: t('billing.invoice.all_details'), value: 2}),
                                                        ]}
                                                        size='small'
                                                        dropdownMatchSelectWidth={200}
                                                        onSelect={handleSelectDetail}
                                                        defaultValue={invoiceDetail}
                                                        disabled={!isEditor}
                                                        style={{width: 160, margin: '4px'}}
                                                    />
                                                </Col>
                                                <Col flex={2} className='middle'>
                                                    <Tooltip title={t('billing.invoice.regenerate_title')}>
                                                        <Button style={{margin: '4px', color: 'white', backgroundColor: '#F06464'}}

                                                            size='small'
                                                            onClick={handleGenerateInvoice}
                                                            disabled={!isEditor}>
                                                            {t('billing.invoice.regenerate_button')}
                                                        </Button>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Col>
                            </Row>
                        )
                    }


                    <Row gutter={[8, 8]}>
                        <Col span={24}><Divider><small>{t('billing.invoice.more-info')}</small></Divider></Col>
                    </Row>

                    <Table dataSource={itemSource}
                           columns={columns}
                           rowKey='id'
                           className='invoiceItemsTable'
                           bordered={true}
                           pagination={false} />

                    <Row>
                        <Col flex={6}>
                            {
                                !isFinalized && (
                                    <Row gutter={[8, 8]}>
                                        <Col span={24} className='left'>
                                            <Tooltip title={t('billing.invoice.add_item_help')}>
                                                <Button type='text' size='small'
                                                        disabled={!isEditor}
                                                        onClick={() => {
                                                            setInvoiceItemVisible(true)
                                                            setInvoiceItemId(0)
                                                        }}
                                                        icon={<PlusCircleTwoTone twoToneColor='#52c41a' />}
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                )
                            }

                            <Row gutter={[8, 8]}>
                                {
                                    isFinalized && (
                                        <Col span={24}><span
                                            style={{fontSize: '0.7em'}}>&nbsp;{invoice.items_text_sufix}</span></Col>
                                    )
                                }
                                {
                                    !isFinalized && (
                                        <>
                                            <Col span={2} className='right' style={{marginTop:'12px'}}>{t('billing.invoice.suffix')}:</Col>
                                            <Col span={22}>
                                                <Form.Item label=''
                                                           name='items_text_sufix'
                                                           style={{width: '90%', marginBottom: '0'}}
                                                           labelCol={{span: 24}}
                                                           wrapperCol={{span: 24}}>
                                                    <Input type='text' size='small' disabled={!isEditor} />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )
                                }
                            </Row>
                            <Row gutter={[8, 8]} style={{marginTop: '24px'}}>
                                <Col flex={1}>

                                </Col>
                                <Col flex={3} style={{textAlign: 'center'}}>
                                    <span className='bold'>{t('billing.invoice.preview')}</span>
                                    <br/>
                                    <Button type='primary' shape="round" icon={<FilePdfOutlined/>} onClick={pdfPreview}>
                                        {t('billing.invoice.pdf_preview')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex={4}>
                            <Row gutter={[8, 8]}>
                                <Col span={10}>{t('billing.invoice.total')}</Col>
                                <Col span={14-shift} className='right'>{appSetting.renderPrice(invoice.total_without_vat)} {invoice.currency}</Col>
                                <Col span={shift}>&nbsp;</Col>
                            </Row>

                            {
                                vatItems.filter(v => v.total > 0).map(vat => (
                                    <Row key={vat.id} gutter={[8, 8]}>
                                        <Col span={2}>{t('billing.invoice.vat')}</Col>
                                        <Col span={10}>{vat.name}</Col>
                                        <Col span={12 - shift}
                                             className='right'>{appSetting.renderPrice(vat.total)} {invoice.currency}</Col>
                                        <Col span={shift}>&nbsp;</Col>
                                    </Row>
                                ))
                            }

                            <Row gutter={[8, 8]} style={{fontSize: '1.4em', borderTop: '2px solid black'}}>
                                <Col span={10}>{t('billing.invoice.total')}<span style={{fontSize: '80%', fontWeight: 'normal'}}>(+{t('billing.invoice.vat')})</span></Col>
                                <Col span={14-shift} className='bold right'>{appSetting.renderPrice(invoice.total_with_vat)} {invoice.currency}</Col>
                                <Col span={shift}>&nbsp;</Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]} style={{borderTop: '1px solid #dcdcdc', backgroundColor: '#dcdcdc'}}>
                        <Col span={8}>
                            <Dropdown overlay={actionMenu()}>
                                <Button style={{marginLeft: '25px'}} type='primary'>
                                    {t('general.actions')} <CaretDownOutlined/>
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col span={8} style={{textAlign: 'center'}}>
                            {t('billing.invoice.related')}: &nbsp;
                            <Button size='small' style={{backgroundColor: '#dcdcdc'}}>
                                <Link className='link' to={`/billing/customer-services/${invoice.customer_id}`}>
                                    {t('billing.invoice.services')}
                                </Link>
                            </Button>
                            &nbsp;
                            <Button size='small' style={{backgroundColor: '#dcdcdc'}}>
                                <Link className='link' to={`/billing/mail?customer_id=${invoice.customer_id}`}>
                                    {t('billing.invoice.mails')}
                                </Link>
                            </Button>
                        </Col>

                        <Col span={8} style={{textAlign: 'right'}}>
                            <Button type='primary' className='success' size='middle'
                                    style={{marginRight: '25px', marginLeft: '40px'}}
                                    htmlType='submit'
                                    loading={isSaving}
                                    disabled={!isEditor || isInvoiceLoading || invoice.state === 2}>
                                {t('billing.invoice.update')}
                            </Button>
                        </Col>
                    </Row>

                    <Collapse defaultActiveKey={['note', 'attachment', 'cost', 'history']} onChange={onChangePanel}>
                        <Panel header={t('billing.invoice.note')} key="note">
                            <Form.Item label='' name='internal_note'
                                       style={{width: '100%', marginBottom: '0'}}
                                       labelCol={{span: 24}}
                                       wrapperCol={{span: 24}}>
                                <TextArea rows={5}
                                          data-gramm="false"
                                          data-gramm_editor="false"
                                          data-enable-grammarly="false"
                                          disabled={!isEditor} />
                            </Form.Item>
                            <div style={{padding: '12px'}}>
                                <Button type='primary'
                                        style={{ float: 'right'}}
                                        onClick={saveNote}
                                        loading={isSavingNote}
                                        disabled={!isEditor}
                                        size='small'>
                                    { t( 'billing.invoice.update_note' ) }
                                </Button>
                            </div>
                        </Panel>

                        <Panel header={(<Badge color='blue' count={invoice.invoiceDocuments?.length}>{t('billing.invoice.attachments') + ' `' } </Badge>)} key="attachment">
                            <Row>
                                <Col span={12}>
                                    <span className='bold'>{t('billing.invoice.historical_v')}</span>
                                    { downloading && <Spin/>}
                                    <div className='docFrame'>
                                        {
                                            invoice.invoiceDocuments && invoice.invoiceDocuments.sort(sort_version).map(d => (
                                                <div key={d.id} className="d-flex" onClick={() => handleDownload(d.version, d.id)}>
                                                    <div className="download" style={{margin: '8px'}}>
                                                        <Button type="primary" shape="round" icon={<PaperClipOutlined/>}
                                                                size='small'>
                                                            Download version-{d.version}
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <span className='bold'>{t('billing.invoice.attachments')}&nbsp;</span>
                                    <InvoiceAttachment invoice_id={invoice.id}/>
                                </Col>
                            </Row>

                        </Panel>
                        <Panel header={t('billing.invoice-cost-allocation.page-title')} key="cost">
                            <InvoiceCostAllocationPage
                                title={t('billing.invoice-cost-allocation.page-title')}
                                invoice={invoice}
                            />
                        </Panel>
                        <Panel header={t('history.title')} key="history">
                            <HistoryLog service='billing'
                                        model='Invoice'
                                        modelId={historyModelId}
                                        forceUpdateId={forceUpdateId}
                                        isModal={false}
                                        showTitle={true} />

                        </Panel>
                        <Panel header="Stará historie" key="history2">
                            <p><Button type='primary' onClick={() => setHistoryModal(true)}>Stará historie</Button></p>
                        </Panel>
                    </Collapse>

                </Form>
            </Layout>

            <Modal title={t('billing.invoice.pdf_preview')}
                   visible={previewVisible}
                   footer={null}
                   onCancel={() => setPreviewVisible(false)}
                   width={1200}
                   maskClosable={false}
                   style={{height: '100vh', top: 50}}
            >
                {
                    previewLoading &&
                    <Spin/>
                }
                {
                    !previewLoading &&
                    <embed type='application/pdf' src={`data:application/pdf;headers=filename%3Daaafilename.pdf;base64, ${preview}`}
                    style={{width: '100%', height: '100vh'}}/>
                }
            </Modal>

            <Modal title={t('billing.invoice.send_mail')}
                   visible={sendMailVisible}
                   footer={null}
                   onCancel={() => setSendMailVisible(false)}
                   width={900}
                   maskClosable={false}
                   getContainer={false}
            >
                <SendMailForm
                    setSendMailVisible={setSendMailVisible}
                    selectedCustomer={selectedCustomer}
                    updateHistory={() => setForceUpdateId(forceUpdateId+1)}
                />
            </Modal>

            <Modal title={t('billing.invoice.invoice_item_title')}
                   destroyOnClose
                   style={{top: 20}}
                   width={500}
                   visible={invoiceItemVisible}
                   onCancel={() => {
                       setInvoiceItemVisible(false)
                   }}
                   footer={null}
                   maskClosable={false}
                   confirmLoading={true}>

                <InvoiceItemForm invoiceItemId={invoiceItemId}
                                 selectedCompany={selectedCompany}
                                 selectedCustomer={selectedCustomer}
                                 updateHistory={() => setForceUpdateId(forceUpdateId+1)}
                                 hideInvoiceItemModal={() => setInvoiceItemVisible(false)}/>
            </Modal>

            <Modal
                destroyOnClose
                style={{top: 50}}
                bodyStyle={{height: '60%', minHeight: 150, padding: 2}}
                width='60%'
                className='historyModal'
                title={t('usersPage.history')}
                visible={historyModal}
                onCancel={() => setHistoryModal(false)}
                footer={null}
                maskClosable={false}
            >
                <History2
                    url={"/model-history?service=billing&model=Invoice&id="+invoice.id}
                    id={Number(id)}
                    historyName="user"
                    showTitle={false}
                    scrollY='450px'
                />
            </Modal>

        </>
    )
}

export default EditInvoicePage