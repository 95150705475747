import {NameItem} from "../../../common/models";

export interface DnsPageState {
  isSaving: boolean
  isLoading: boolean
  isLoadingArchive: boolean
  isSavingZone: boolean
  isLoadingZone: boolean
  dnsZone?: DnsZoneModel
  archived?: DnsZoneModel
  dnsZones: DnsZone[]
  dnsService?: DnsServiceModel
  dnsServices: DnsServiceModel[]
  dnsRecords: DnsRecordModel[]
  exportedFile: string
  dnsChecks: MapCheck
  error?: string
}

export type MapCheck = {
  [key: string]: DnsCheck;
};

export interface DnsServiceModel extends CreateDnsServiceParams {
  id: number
}

export interface CreateDnsServiceParams {
  server_id: number
  driver_id: number
  driver_name: string
  driver?: NameItem
  protocol: 'http' | 'https'
  host: string
  port: number
  instance?: string //localhost
  username?: string
  password?: string
  base_url?: string //API path
  token_name?: string //API token
  token_value?: string //API token value
  searchableName: string
}

export interface UpdateDnsServiceParams {
  id: number
  protocol?: 'http' | 'https'
  host?: string
  port?: number
  instance?: string //localhost
  username?: string
  password?: string
  base_url?: string //API path
  token_name?: string //API token
  token_value?: string //API token value
}
export interface DeleteDnsServiceParams {
  id: number
}

export interface DnsZone {
  name: string
  id: string
}

export interface DnsCheck {
  domain: string
  type: string
  state: string
  ts_called?: number
}

export interface DnsZoneModel {
  domain: string
  dnssec: boolean
  records: DnsRecordModel[]
  archived?: DnsZoneModel
}

export interface CreateDnsZoneParams {
  service_id: number
  domain: string //Domain
  ns1: string
  ns2: string
}

export interface DeleteDnsZoneParams {
  service_id: number
  domain: string
}

export interface GetZoneParams {
  service_id: number
  domain: string
}

export interface ExportDnsZoneParams {
  service_id: number
  domain: string
}

export interface SetSoaTtlDnsZoneParams {
  id: number
  domain: string
  ttl: number //TTL
}

export interface SetDnsSecDnsZoneParams {
  service_id: number
  domain: string
  dnssec: 1 | 0
}

export interface DnsRecordModel extends AddDnsRecordParams {}

export interface AddDnsRecordParams {
  service_id: number
  domain: string // Domain
  name: string // @
  type: string
  is_new?: Boolean
  content: string
  ttl: number //TTL
}

export interface RemoveDnsRecordParams {
  id: number
  domain: string // Domain
  name: string // @
  type: string
  is_new?: Boolean
}


export enum DNSType {
  SOA = 'SOA',
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  MX = 'MX',
  NS = 'NS',
  PTR = 'PTR',
  SRV = 'SRV',
  TXT = 'TXT',
}
