import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {
    Card,
    Button,
    Modal,
    Table,
    Space,
    Spin,
    Select,
    Input,
    Row,
    Col,
    Tag,
    AutoComplete, message
} from 'antd'
import {
    PlusCircleOutlined
} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {InventoryModel} from './models'
import './inventory.scss'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import getInventories from './actions/getInventories'
import getCompanies from 'pages/company/actions/getCompanies'
import {_isNotEmptyObject, isAlphaNumCode, removeDiac, SQL_DATE_FORMAT, stopPropagation} from "../../../common/fce";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import queryString, {ParsedQuery} from "query-string";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    CommentOutlined,
    EditTwoTone, FilePdfTwoTone,
    InfoCircleTwoTone,
    OneToOneOutlined,
} from "@ant-design/icons";
import getInventory from "./actions/getInventory";
import getInventoryPageLocations from "./actions/getInventoryPageLocations";
import getInventoryPageTypes from "./actions/getInventoryPageTypes";
import clearInventory from "./actions/clearInventory";
import InventoryCreateForm from "./inventoryCreateForm";
import InventoryEditForm from "./inventoryEditForm";
import moment, {Moment} from "moment";
import usePageSize from "../../../common/usePageSize";
import useLogger from "../../../common/useLogger";
import {ColumnsType} from "antd/lib/table";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {sort_label, sort_searchable, sort_str} from "../../../common/sorting";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import DateRange from "../../../components/RangeFilter/DateRange";
import getInventoryStatuses from "./actions/getInventoryStatuses";
import {BillAttachmentModel} from "../../billing/bill/models";
import getBillAttachments from "../../../components/BillVat/BillAttachment/actions/getBillAttachments";
import {protectedApiClient} from "../../../helpers/api";
import getInventoryBill from "./actions/getInventoryBill";
import CommentForm from "../../../components/Comment/CommentForm";
import updateInventory from "./actions/updateInventory";
import lookupInventory from "./actions/lookupInventory";
import PdfPreview from "../../../components/ModalDialogs/PdfPreview";
import HistoryModal from "../../../components/History/HistoryModal";
import Pager from "../../../components/pagination/pager";
import tableCompanies from "../../login/actions/tableCompanies";


const RANGE_FROM_ALL = '2020-01-01'
const INVENTORY_ACTIVE_STATUS_ID = 1

const initRange = ():[string, string] => {
    return [RANGE_FROM_ALL, moment().endOf('year').format(SQL_DATE_FORMAT)]
}
const allRange = ():[string, string] => {
    return ['2000-01-01', moment().endOf('year').format(SQL_DATE_FORMAT)]
}


const InventoryPage = () => {
    const CONTROL_NAME = 'page_inventory'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {search} = useLocation()
    const history = useHistory()

    const {inventories, pager, isLoading, bill, locations, types, statuses,
        lookup_serial, lookup_name, lookup_inv_no, isLoadingLookup} = useSelector((state: AppState) => state.inventory)
    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const {inventory_items} = useSelector((state: AppState) => state.sidenav)

    // options
    const [typeOptions, setTypeOptions] = useState<{ label: string, value: number }[]>([])
    const [statusOptions, setStatusOptions] = useState<{ label: string, value: number }[]>([])
    const [locationOptions, setLocationOptions] = useState<{ label: string, value: number }[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: number }[]>([])
    const [serialOptions, setSerialOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [nameOptions, setNameOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [numberOptions, setNumberOptions] = useState<{ label: string | ReactNode, value: string }[]>([])

    // data
    const [dataSource, setDataSource] = useState<InventoryModel[]>([])
    const [searchableInventories, setSearchableInventories] = useState<InventoryModel[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)

    const [qsFilter, setQsFilter] = useState<string>('')

    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [iid, setIid] = useState(0)
    const [searchNameLookup, setSearchNameLookup] = useState<string>('')
    const [searchName, setSearchName] = useState<string>('')
    const [searchSerialLookup, setSearchSerialLookup] = useState<string>('')
    const [searchSerial, setSearchSerial] = useState<string>('')
    const [searchNumberLookup, setSearchNumberLookup] = useState<string>('')
    const [searchNumber, setSearchNumber] = useState<string>('')
    const [searchType, setSearchType] = useState<number | undefined>(undefined)
    const [searchStatus, setSearchStatus] = useState<number | undefined>(INVENTORY_ACTIVE_STATUS_ID)
    const [searchLocation, setSearchLocation] = useState<number | undefined>(undefined)
    const [rangeStart, setRangeStart] = useState<string>('')
    const [rangeEnd, setRangeEnd] = useState<string>('')

    // page states
    const [dataToUpdate, setDataToUpdate] = useState<InventoryModel>()
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [isModalCreateVisible, setModalCreateVisible] = useState<boolean>(false)
    const [isModalEditVisible, setModalEditVisible] = useState<boolean>(false)
    const [isModalCommentVisible, setModalCommentVisible] = useState<boolean>(false)
    const [previewLoading, setPreviewLoading] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>('')
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [ready, setReady] = useState<number>(0)

    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'InventoryPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)


    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    // to handle SideNav menu pressed after 1sec
    const [triggerOneTimer, setTriggerOneTimer] = useState(0)

    useEffect(() => {
        // trigger ONCE
        setTriggerOneTimer(Date.now().valueOf())
        logger('=== ONCE  url='+search)
        setPageSize(appSetting.grid_page_size)
        if (loggedUser) {
            setViewer(loggedUser.hasAccess('page_inventory'))
            setCreator(loggedUser.hasAccess('page_inventory_create_button'))
            setEditor(loggedUser.hasAccess('page_inventory_edit_button'))
            setDeleter(loggedUser.hasAccess('page_inventory_delete_button'))
        }

        // load Options for refresh F5
        loadOptions()
        handleQueryStringFilters()
    }, [])

    useEffect(() => {
        // handle a single item by ID
        initializeRange()
        logger('=== IID '+iid)
    }, [iid])

    useEffect(() => {
        // SideNav menu pressed
        const when = triggerOneTimer + 1000
        if (triggerOneTimer > 0 && when < Date.now().valueOf()) {
            logger('--- SideNav menu pressed')
            clearFilters()
            fetchRecords(1, pageSize)
        }
    }, [inventory_items])

    useEffect(() => {
        // check if Ready
        if (companies && companies.length &&
            locations && locations.length &&
            types && types.length &&
            statuses && statuses.length) {
            // options are loaded
            setReady(ready+1)
        }
    }, [companies, locations, types, statuses])

    useEffect(() => {
        // process Options
        if (ready) {
            logger(`Ready=${ready} iid=${iid}`)
            setCompanyOptions(companies.sort(sort_searchable).map(t => ({label: t.name, value: t.id})))
            setLocationOptions(locations.map(t => ({label: t.name, value: t.id})).sort(sort_label))
            setTypeOptions(types.map(t => ({label: t.name, value: t.id})).sort(sort_label))
            setStatusOptions(statuses.map(t => ({label: t.name, value: t.id})).sort(sort_label))
            fetchRecords(pageNumber, pageSize)
        }
    }, [ready])

    useEffect(() => {
        // populate numberOptions
        setNumberOptions(lookup_inv_no.map(s => ({label: s, value: s})))
    }, [lookup_inv_no])

    useEffect(() => {
        // populate serialOptions
        setSerialOptions(lookup_serial.map(s => ({label: s, value: s})))
    }, [lookup_serial])

    useEffect(() => {
        // populate nameOptions
        setNameOptions(lookup_name.map(s => ({label: s, value: s})))
    }, [lookup_name])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])

    useEffect(() => {
        // update QsFilter
        if (!isModalCreateVisible) {
            const qs: string = prepareQsFilter()
            logger(`qsFilter:${qs}`)
            if (qs != qsFilter) {
                setQsFilter(qs)
            }
        }
    }, [searchLocation, searchType, searchStatus, rangeStart, rangeEnd,
        searchNumber, searchSerial, searchName])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])

    useEffect(() => {
        // process new server-data
        if (!ready) {
            return
        }
        if (inventories.items && filterIsValid()) {
            setSearchableInventories(inventories.items.map(item => {
                return {
                    ...item,
                    type: types.find(t => t.id === item.type_id)?.name,
                    location: locations.find(lo => lo.id === item.location_id)?.name,
                    vendor: companies.find(c => c.id === item.vendor_id)?.name,
                    status: statuses.find(c => c.id === item.status_id)?.name,
                    searchableName: removeDiac(item.name)
                }
            }))
        }
    }, [inventories.items])

    useEffect(() => {
        // render data
        if (!ready) {
            return
        }
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [searchableInventories])

    // ------------------------------ end hooks

    const replaceHistory = () => {
        if (filterIsValid()) {
            const url = `/inventory?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&` + qsFilter
            history.replace(url)
        }
    }

    const filterIsValid = (): boolean => {
        // purchase_from is required
        return !!qsFilter && qsFilter.includes('purchase_from=')
    }

    const handleQueryStringFilters = () => {
        // parse query string
        // and set filters
        // /billing/invoice?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        let passedRange = 0
        if (parsed && _isNotEmptyObject(parsed)) {
            logger(`handleQueryStringFilters..`)
            const qs_id: string | string[] | null = parsed['id']
            if (qs_id && typeof qs_id === 'string') {
                // call by ID
                const id = parseInt(qs_id)
                if (id > 0) {
                    setIid(id)
                    return
                }
            }

            // iid=0
            const qs_name: string | string[] | null = parsed['name']
            if (qs_name && typeof qs_name === 'string') {
                setSearchNameLookup(qs_name)  // textbox
                setSearchName(qs_name) // filter
            }
            const qs_serial: string | string[] | null = parsed['serial']
            if (qs_serial && typeof qs_serial === 'string') {
                setSearchSerialLookup(qs_serial)  // textbox
                setSearchSerial(qs_serial) // filter
            }
            const qs_number: string | string[] | null = parsed['inv_no']
            if (qs_number && typeof qs_number === 'string') {
                setSearchNumberLookup(qs_number.toLowerCase())  // textbox
                setSearchNumber(qs_number.toLowerCase()) // filter
            }
            const qs_type: string | string[] | null = parsed['type_id']
            if (qs_type && typeof qs_type === 'string') {
                const tid = parseInt(qs_type)
                if (tid > 0) {
                    setSearchType(tid)
                }
            }
            const qs_status: string | string[] | null = parsed['status_id']
            if (qs_status && typeof qs_status === 'string') {
                const sid = parseInt(qs_status)
                if (sid > 0) {
                    setSearchStatus(sid)
                }
            }
            const qs_location: string | string[] | null = parsed['location_id']
            if (qs_location && typeof qs_location === 'string') {
                const lid = parseInt(qs_location)
                if (lid > 0) {
                    setSearchType(lid)
                }
            }
            const qs_size: string | string[] | null = parsed['pagination[pageSize]']
            if (qs_size && typeof qs_size === 'string') {
                const ps = parseInt(qs_size)
                if (ps > 0) {
                    setPageSize(ps)
                }
            }
            const qs_num: string | string[] | null = parsed['pagination[page]']
            if (qs_num && typeof qs_num === 'string') {
                const pn = parseInt(qs_num)
                setPageNumber(pn)
            }
            // period
            const qs_from: string | string[] | null = parsed['purchase_from']
            if (qs_from && typeof qs_from === 'string' && qs_from.length > 8) {
                setRangeStart(qs_from)
                passedRange++
            }
            const qs_to: string | string[] | null = parsed['purchase_to']
            if (qs_to && typeof qs_to === 'string' && qs_to.length > 8) {
                setRangeEnd(qs_to)
                passedRange++
            }
        }
        if (passedRange === 0) {     // init searchRange
            initializeRange()
        }
    }

    const initializeRange = () => {
        // set default range (from 2000 till now)
        logger(`initializeRange`)
        if (iid && iid > 0) {
            setRangeStart(allRange()[0])
            setRangeEnd(allRange()[1])
        }
        else {
            setRangeStart(initRange()[0])
            setRangeEnd(initRange()[1])
        }
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (rangeStart && rangeEnd) {
            let dt1 = moment(rangeStart)
            let dt2 = moment(rangeEnd)
            qs.push('purchase_from=' + dt1.format(SQL_DATE_FORMAT))
            qs.push('purchase_to=' + dt2.format(SQL_DATE_FORMAT))
        }
        if (searchType && searchType > 0) {
            qs.push('type_id=' + searchType)
        }
        if (searchStatus && searchStatus > 0) {
            qs.push('status_id=' + searchStatus)
        }
        if (searchLocation && searchLocation > 0) {
            qs.push('location_id=' + searchLocation)
        }
        return qs
    }

    const fetchNumberLookup = (searchText: string) => {
        // call lookup for inv.number
        searchText = searchText.trim()
        if (checkMinSearch(searchText.trim())) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchName)) {
                qs.push('name=' + encodeURIComponent(searchName))
            }
            if (checkMinSearch(searchSerial)) {
                qs.push('serial=' + encodeURIComponent(searchSerial))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=inv_no')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupInventory: ' + qs.join("&"))
                dispatch(lookupInventory('inv_no', qs.join("&")))
            }
        }
    }

    const fetchNameLookup = (searchText: string) => {
        // call lookup for name
        if (checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchNumber)) {
                qs.push('inv_no=' + encodeURIComponent(searchNumber))
            }
            if (checkMinSearch(searchSerial)) {
                qs.push('serial=' + encodeURIComponent(searchSerial))
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=name')
                qs.push('value=' + encodeURIComponent(searchText))
                logger('lookupInventory: ' + qs.join("&"))
                dispatch(lookupInventory('name', qs.join("&")))
            }
        }
    }

    const fetchSerialLookup = (searchText: string) => {
        // call lookup serial
        if (checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            if (checkMinSearch(searchName)) {
                qs.push('name=' + encodeURIComponent(searchName))
            }
            if (checkMinSearch(searchNumber)) {
                qs.push('inv_no=' + searchNumber)
            }
            if (checkMinSearch(searchText)) {
                qs.push('field=serial')
                qs.push('value=' + encodeURIComponent(searchText.trim()))
                logger('lookupInventory: '+qs.join("&"))
                dispatch(lookupInventory('serial', qs.join("&")))
            }
        }
    }

    const prepareQsFilter = (): string => {
        if (iid > 0) {
            let qs: string[] = []
            // inv_no is uniq
            qs.push('inv_no='+iid)
            qs.push('purchase_from=' + allRange()[0])
            qs.push('purchase_to=' + allRange()[1])
            return qs.join("&")
        }
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (checkMinSearch(searchName)) {
            qs.push('name=' + encodeURIComponent(searchName))
        }
        if (checkMinSearch(searchNumber)) {
            qs.push('inv_no=' + encodeURIComponent(searchNumber))
        }
        if (checkMinSearch(searchSerial)) {
            qs.push('serial=' + encodeURIComponent(searchSerial))
        }
        logger('prepareQsFilter: '+qs.join("&"))
        return qs.join("&")
    }

    const onClearName = () => {
        setSearchNameLookup('')
        setSearchName('')
        setNameOptions([])
    }

    const onSelectName = (data: string) => {
        setSearchName(data)
    }

    const onChangeNameLookup = (data: string) => {
        if (!data) {
            if (searchNameLookup.length === 1) {
                setSearchNameLookup('')
                fetchNameLookup('')
            }
            return
        }
        if (data != searchNameLookup){
            setSearchNameLookup(data)
            fetchNameLookup(data)
        }
    }

    const onClearNumber = () => {
        setSearchNumberLookup('')
        setSearchNumber('')
        setNumberOptions([])
    }

    const onSelectNumber = (data: string) => {
        setSearchNumber(data)
    }

    const onChangeNumberLookup = (data: string) => {
        if (!data) {
            if (searchNumberLookup.length === 1) {
                setSearchNumberLookup('')
                fetchNumberLookup('')
            }
            return
        }
        if (data != searchNumberLookup){
            setSearchNumberLookup(data)
            fetchNumberLookup(data)
        }
    }

    const onClearSerial = () => {
        setSearchSerialLookup('')
        setSearchSerial('')
        setSerialOptions([])
    }

    const onChangeSerialLookup = (data: string) => {
        if (!data) {
            if (searchSerialLookup.length === 1) {
                setSearchSerialLookup('')
                fetchSerialLookup('')
            }
            return
        }
        if (data != searchSerialLookup){
            setSearchSerialLookup(data)
            fetchSerialLookup(data)
        }
    }

    const onSelectSerial = (data: string) => {
        setSearchSerial(data)
    }

    const clearFilters = () => {
        // used when clicked on left menu
        logger('clearFilters')
        setIid(0)
        setSearchNameLookup('')
        setSearchNumberLookup('')
        setSearchSerialLookup('')
        setSearchName('')
        setSearchNumber('')
        setSearchSerial('')
        setSearchType(undefined)
        setSearchStatus(INVENTORY_ACTIVE_STATUS_ID)
        setSearchLocation(undefined)
    }

    const loadOptions = () => {
        // load Options for refresh F5
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
        }
        if (!locations || locations.length === 0) {
            dispatch(getInventoryPageLocations())
        }
        if (!types || types.length === 0) {
            dispatch(getInventoryPageTypes())
        }
        if (!statuses || statuses.length === 0) {
            dispatch(getInventoryStatuses())
        }
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > appSetting.min_search_length
    }

    const fetchRecords = (pn: number, ps: number) => {
        if (!isViewer) {
            return
        }
        setPageNumber(pn)
        setPageSize(ps)
        if (ready && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getInventories(ps, pn - 1, qsFilter, suc => {}))
        }
    }

    const refreshGrid = () => {
        logger('refreshGrid '+iid)
        setDataSource(searchableInventories.sort((a, b) => sort_str(b.inv_no, a.inv_no)))
    }

    const handleFilterChange = (name: string, value: any) => {
        if (name && value) {
            if (iid > 0) {
                // clear ID
                setIid(0)
            }
        }
        if (name === 'location') {
            // load data from server - automatic
            if (value && value>0) {
                setSearchLocation(value)
            } else {
                setSearchLocation(undefined)
            }
        }
        if (name === 'type') {
            // load data from server - automatic
            if (value && value>0) {
                setSearchType(value)
            } else {
                setSearchType(undefined)
            }
        }
        if (name === 'status') {
            // load data from server - automatic
            if (value && value>0) {
                setSearchStatus(value)
            } else {
                setSearchStatus(undefined)
            }
        }
        if (name === 'name') {
            if (value) {
                if (checkMinSearch(value)) {
                    setSearchNameLookup(value)
                }
            } else {
                setSearchNameLookup('')
            }
        }
        if (name === 'number') {
            if (value) {
                if (checkMinSearch(value)) {
                    setSearchNumberLookup(value)
                }
            } else {
                setSearchNumberLookup('')
            }
        }
        if (name === 'serial') {
            if (value) {
                if (checkMinSearch(value)) {
                    setSearchSerialLookup(value)
                }
            } else {
                setSearchSerialLookup('')
            }
        }
    }

    const handleDateChange = (dt_from: string, dt_to: string) => {
        // use SQL_DATE_FORMAT
        if (dt_from && dt_to) {
            logger(`dt_from: ${dt_from} - dt_to: ${dt_to}`)
            setRangeStart(dt_from)
            setRangeEnd(dt_to)
        }
    }

    const FilterByLocation = locations && (
        <Select
            showSearch
            placeholder={t('inventoryPage.location')}
            options={locationOptions}
            value={searchLocation}
            style={{ width: '100px', marginRight: "1rem" }}
            dropdownMatchSelectWidth={200}
            optionFilterProp="children"
            filterOption={(input, opt) => removeDiac(opt?.label ?? '').includes(removeDiac(input))}
            filterSort={sort_label}
            allowClear
            onClick={stopPropagation}
            onChange={(v) => {handleFilterChange('location', v)}}
        />
    )

    const FilterByType = types && (
        <Select
            showSearch
            placeholder={t('inventoryPage.inventory_type')}
            options={typeOptions}
            value={searchType}
            style={{ width: '100px', marginRight: "1rem" }}
            dropdownMatchSelectWidth={160}
            optionFilterProp="children"
            filterOption={(input, opt) => removeDiac(opt?.label ?? '').includes(removeDiac(input))}
            filterSort={sort_label}
            allowClear
            onClick={stopPropagation}
            onChange={(v) => {handleFilterChange('type', v)}}
        />
    )

    const FilterByNumber = (
        <AutoComplete
            showSearch
            placeholder={t('inventoryPage.inv_no')}
            style={{ width: '80px', marginRight: "1rem" }}
            value={searchNumberLookup}
            options={numberOptions}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectNumber(e.currentTarget.value)
                }
            }}
            onSelect={onSelectNumber}
            onSearch={onChangeNumberLookup}
            onChange={onChangeNumberLookup}
            onClear={onClearNumber}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            allowClear={true}
        />
    )

    const FilterByName = (
        <AutoComplete
            showSearch
            value={searchNameLookup}
            options={nameOptions}
            style={{ width: 240 }}
            dropdownMatchSelectWidth={400}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectName(e.currentTarget.value)
                }
            }}
            onSelect={onSelectName}
            onSearch={onChangeNameLookup}
            onChange={onChangeNameLookup}
            onClear={onClearName}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            placeholder={t('inventoryPage.name')}
            allowClear={true}
        />
    )

    const FilterBySerial = (
        <AutoComplete
            showSearch
            value={searchSerialLookup}
            options={serialOptions}
            style={{ width: 120 }}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectSerial(e.currentTarget.value)
                }
            }}
            onSelect={onSelectSerial}
            onSearch={onChangeSerialLookup}
            onChange={onChangeSerialLookup}
            onClear={onClearSerial}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={true}
            optionFilterProp='label'
            placeholder={t('inventoryPage.serial')}
            allowClear={true}
        />
    )

    const renderStatus = (rec: InventoryModel) => {
        if (rec.status_id === 1) {
            return (<Tag color='green'>{rec.status}</Tag> )
        }
        if (rec.status_id === 2) {
            return (<Tag color='orange'>{rec.status}</Tag> )
        }
        if (rec.status_id === 3) {
            return (<Tag color='darkgray'>{rec.status}</Tag> )
        }
        if (rec.status_id === 4) {
            return (<Tag color='gray'>{rec.status}</Tag> )
        }
        return (<span>{rec.status}</span> )
    }

    const saveComment = (text?: string) => {
        if (dataToUpdate && dataToUpdate.id) {
            text ? dataToUpdate.comment = text : dataToUpdate.comment = ''
            const params = {id: dataToUpdate.id, comment: dataToUpdate.comment}
                dispatch(updateInventory(params, suc => {
                setModalCommentVisible(false)
            }))
        }
    }

    const getBill = (bid: number) => {
        dispatch(getInventoryBill(bid, suc => {
            downloadDocument()
        }))
    }

    const downloadDocument = () => {
        if (!bill) {
            message.error('Bill not found.')
            return
        }

        message.success({content: 'Downloading...', duration: 0, key: bill.id}).then()
        dispatch(getBillAttachments(bill.id, (suc, attachments: BillAttachmentModel[] | undefined) => {
            if (suc) {
                if (attachments && attachments.length) {
                    const attach = attachments[0]
                    protectedApiClient.get<string>(`/billing/bill-attachment/${attach.id}/content`,{onDownloadProgress: () => {
                        }})
                        .then(response => {
                            const downloadLink = document.createElement("a")
                            downloadLink.href = `data:application/pdf;base64,${response.data}`
                            downloadLink.download = attach.name
                            downloadLink.click()
                            message.destroy(bill.id)
                        })
                        .catch(error => {
                            console.log(error)
                            message.error('Download Failed')
                        })
                }
                else {
                    message.error('File not found')
                }
            }
        }))
    }

    const hidePreview = () => {setPreviewVisible(false)}

    const pdfPreview = (id: number) => {
        // download and show preview PDF
        if (id > 0) {
            setPreviewVisible(true)
            setPreviewLoading(true)
            protectedApiClient.get<string>(`/billing/bill/${id}/preview`)
                .then(response => {
                    setPreviewLoading(false)
                    setPreview(response.data)
                })
                .catch(error => {
                    message.error(t('billing.invoice.preview_fail'))
                    setPreviewLoading(false)
                })
        }
    }

    const columns: ColumnsType<InventoryModel> = [
        { title: FilterByNumber,
            dataIndex: 'inv_no',
            key: 'inv_no',
            fixed: 'left',
            width: 120,
            showSorterTooltip: false,
            sorter: (a, b) => sort_str(a.inv_no, b.inv_no)
        },
        { title: FilterByName,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: '36%',
            ellipsis: true,
            showSorterTooltip: false,
            sorter: (a, b) => sort_str(a.name, b.name)
        },
        {
            title: FilterByType,
            dataIndex: 'type_id',
            key: 'type_id',
            width: 140,
            showSorterTooltip: false,
            render: (tid: number, rec: InventoryModel) => rec.type,
            sorter: (a, b) => sort_str(a.type, b.type)
        },
        {
            title: FilterBySerial,
            dataIndex: 'serial',
            key: 'serial',
            width: 200,
            showSorterTooltip: false,
            sorter: (a, b) => sort_str(a.serial, b.serial)
        },
        {
            title: FilterByLocation,
            dataIndex: 'location_id',
            key: 'location_id',
            className: 'hide',
            width: 120,
            showSorterTooltip: false,
            render: (text: number, rec: InventoryModel) => rec.location,
            sorter: (a, b) => sort_str(a.location, b.location)
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            dataIndex: 'action',
            fixed: 'right',
            width: 130,
            render: (_: string, record: InventoryModel) => (
                <Space size={1}>
                    {
                        (record.comment) ? (
                            <Button title={record.comment}
                                    icon={<CommentOutlined style={{backgroundColor: '#ccffcc', borderRadius: '5px'}} />}
                                    size='small'
                                    className='actionButton'
                                    onClick={() => {
                                        if (isEditor) {
                                            setDataToUpdate(record)
                                            setModalCommentVisible(true)
                                        }
                                    }}
                            />
                        ) : (
                            <Button icon={<CommentOutlined color='lightgray' />}
                                    size='small'
                                    className='actionButton'
                                    onClick={() => {
                                        if (isEditor) {
                                            setDataToUpdate(record)
                                            setModalCommentVisible(true)
                                        }
                                    }}
                            />
                        )
                    }
                    {
                        (record.bill_id && record.bill_id > 0) ? (
                            <Button title={t('billing.invoice.table.download')}
                                    icon={<FilePdfTwoTone />}
                                    size='small'
                                    className='actionButton'
                                    onClick={() => record.bill_id && pdfPreview(record.bill_id)}
                            />
                        ) : (
                            <Button title={t('billing.invoice.table.no_download')}
                                    icon={<FilePdfTwoTone twoToneColor='lightgray' />}
                                    size='small'
                                    disabled={true}
                                    className='actionButton'
                            />
                        )
                    }
                    {
                        isEditor && (
                            <Button type='text' size='small'
                                    onClick={() => {
                                        dispatch(getInventory(record.id))	// load fresh record
                                        setDataToUpdate(record)
                                        setModalEditVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green' />}
                            />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                setHistoryTitle(record.name)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                    <span>&nbsp;</span>
                </Space>
            ),
        }
    ]

    if (!rangeStart) {
        return (<Spin/>)
    }

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!ready) {
        return (<Spin/>)
    }

    return (
        <>
            <Card
                title={
                    <Row>
                        <Col span={6}>
                            <OneToOneOutlined/> &nbsp; {t('inventoryPage.title')}
                        </Col>
                        <Col span={2} className='right' style={{padding: '2px'}}>{t('inventoryPage.period_filter')}:&nbsp;</Col>
                        <Col span={10}>
                            <DateRange format={appSetting.date_picker_format} // not Moment formats!!
                                       initStart={rangeStart}
                                       initEnd={rangeEnd}
                                       onChange={handleDateChange}
                            />
                        </Col>
                        <Col span={2} className='right' style={{padding: '5px'}}>{t('inventoryPage.status')}:&nbsp;</Col>
                        <Col span={4}>
                            <Select
                                showSearch
                                placeholder={t('inventoryPage.status')}
                                options={statusOptions}
                                value={searchStatus}
                                style={{ width: '100px', marginRight: "1rem" }}
                                dropdownMatchSelectWidth={120}
                                optionFilterProp="children"
                                filterOption={(input, opt) => removeDiac(opt?.label ?? '').includes(removeDiac(input))}
                                filterSort={sort_label}
                                allowClear
                                onClick={stopPropagation}
                                onChange={(v) => {handleFilterChange('status', v)}}
                            />
                        </Col>
                    </Row>
                }
                extra={
                        <Button type='primary' disabled={!isCreator}
                                onClick={() => {
                            setDataToUpdate(undefined)
                            dispatch(clearInventory())
                            setModalCreateVisible(true)
                        }}
                        ><PlusCircleOutlined/> {t('inventoryPage.new_item')} </Button>
                }
                className='InventoryPage'
            >
                <Table<InventoryModel>
                    className='inventoryTable'
                    rowClassName={(record:InventoryModel) => {
                        if (record.status_id > 1)
                            if (record.status_id === 2)
                                return 'sold'
                            if (record.status_id > 2)
                                return 'disposed'
                        else
                            return ''
                    }}
                    showHeader={true}
                    size='small'
                    bordered={true}
                    columns={columns}
                    scroll={{ x: 680 }}
                    dataSource={dataSource}
                    loading={isLoading}
                    rowKey='id'
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <PdfPreview
                title={t('billing.invoice.pdf_preview')}
                loading={previewLoading}
                visible={previewVisible}
                hide={hidePreview}
                preview={preview}
            />

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><CommentOutlined/> &nbsp; {t('general.comment')}</>
                </div>
            }
                   destroyOnClose
                   className='modalComment'
                   style={{top: 20}}
                   visible={isModalCommentVisible}
                   onOk={() => setModalCommentVisible(false)}
                   onCancel={() => setModalCommentVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   getContainer={false}
                   footer={null}
                   confirmLoading={true}
            >
                <CommentForm comment={dataToUpdate?.comment} setModalVisible={setModalCommentVisible} saveComment={saveComment} />
            </Modal>

            <Modal title={
                    <div style={{width: '100%', cursor: 'move'}}
                         onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                         onMouseOut={() => {setDisabled(true)}}
                         onFocus={() => {}}
                         onBlur={() => {}}
                    >
                        <><OneToOneOutlined /> &nbsp; {t('inventoryPage.create_form_header')}</>
                    </div>
                }
                destroyOnClose
                className='inventoryModalCreate'
                style={{top: 20}}
                visible={isModalCreateVisible}
                onOk={() => setModalCreateVisible(false)}
                onCancel={() => setModalCreateVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                getContainer={false}
                footer={null}
                confirmLoading={true}
            >
                <InventoryCreateForm setModalVisible={setModalCreateVisible} />
            </Modal>

            <Modal title={
                    <div style={{width: '100%', cursor: 'move'}}
                         onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                         onMouseOut={() => {setDisabled(true)}}
                         onFocus={() => {}}
                         onBlur={() => {}}
                    >
                        <><OneToOneOutlined/> &nbsp; {t('inventoryPage.edit_form_header')}</>
                    </div>
                }
                destroyOnClose
                className='inventoryModalEdit'
                style={{top: 20}}
                visible={isModalEditVisible}
                onOk={() => setModalEditVisible(false)}
                onCancel={() => setModalEditVisible(false)}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                getContainer={false}
                footer={null}
                confirmLoading={true}
            >
                <InventoryEditForm objId={dataToUpdate?.id} setModalVisible={setModalEditVisible} />
            </Modal>


            <HistoryModal service='inventory' model='Inventory'
                          modelId={historyModelId}
                          title={historyTitle}
                          isModalVisible={isHistoryModalVisible}
                          setModalVisible={() => setHistoryModalVisible(false)}
                          modalRender={(modal) => (
                              <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                  <div ref={draggleRef}>{modal}</div>
                              </Draggable>
                          )}
            />
        </>
    )

}

export default InventoryPage
