import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import type { MenuProps } from 'antd';
import {Layout, Menu} from 'antd'
import {Theme} from '../ThemeSwitcher/models'
import {AppState} from 'common/models'
import './AppBar.scss'
import SelectCompany from "../SelectCompany/SelectCompany"
import reloadToken from "../../pages/login/actions/reloadToken"
import {currentUser, storeCurrentUser} from "../../helpers/authHelpers"
import {useHistory, withRouter} from "react-router";
import TodayTime from "../TodayTime/TodayTime";
import TodayDate from "../TodayDate/TodayDate";
import {useLoggedUser} from "../../helpers/loginUserHelper";
import SearchBox from "../SearchBox/SearchBox";
import {useTranslation} from "react-i18next";
import {LogoutOutlined, QuestionCircleTwoTone, UserOutlined} from "@ant-design/icons";
import Button from "antd-button-color";
import PopConfirmZis from "../popconfirm/PopConfirmZis";
import showRightSidebar from "../../pages/login/actions/showRightSidebar";
import getDocs from "../../pages/login/actions/getDocs";


const {SubMenu} = Menu
const {Header} = Layout



const AppBar = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {theme} = useSelector((state: AppState) => state.theme)
    const {authenticated, token, reason, self, self_customer, menu_roles, self_settings, docs_content} = useSelector((state: AppState) => state.auth)

    const [logoutLimit, setLogoutLimit] = useState<number>(30)
    const [showClock, setShowClock] = useState<number>(1)

    // logger
    const debug = true
    const logger = (msg) => { if (debug) {console.log('AppBar: ' + msg)} }

    const loggedUser = useLoggedUser()

    const isReady = () => {
        return Boolean(authenticated && self && self_customer && self_settings && self_settings.length > 0)
    }

    useEffect(() => {
        if ((!authenticated || !token) && !reason) {
            if (!reason) {
                // NO TOKEN !!!
                // try to reload token from localStorage
                dispatch(reloadToken((suc) => {
                    logger('dispatch: reloadToken =' + suc)
                    if (suc) {
                        // Token found in localStorage!!
                        // This is used for reload F5
                        // routeComponent redirects to /login
                        // and data are loaded as we have the token
                        logger('reloadToken ok')
                    } else {
                        // No token found in localStorage => LOGOUT
                        // No need to redirect to /login manually
                        // routeComponent redirect it automatically when not auth
                        logger('reloadToken failed')
                    }
                }))
            }
            return
        }

        if (token && authenticated) {
            // compare tokens for store and localStorage
            const t2 = currentUser()
            if (token != t2) {
                logger('save token')
                storeCurrentUser(token)
            }

            // auth
            if (loggedUser && loggedUser.isLoaded()) {
                const appSetting = loggedUser.getAppSettings()
                setLogoutLimit(appSetting.auto_logout_minutes)
                setShowClock(appSetting.show_clock)
            }
        }
    }, [authenticated, token, self, menu_roles, self_customer, self_settings])

    const createMenu = (): MenuProps['items'] => {
        if (!self) {
            return []
        }
        return [
            {label: <SelectCompany/>, key: 'sm_company'},
            {
                label: <SearchBox/>,
                key: 'sm_search',
                style: {width: '160px', lineHeight: '54px', paddingRight: 10, paddingLeft: 10}
            },
            {label: <TodayDate/>, key: 'sm_date'},
            {label: <TodayTime logoutLimit={logoutLimit} showClock={showClock}/>, key: 'sm_time'},
            {label: <Button type='link' className='btnProfile' icon={<UserOutlined />} onClick={() => {history.replace('/users/me')}} >{t('updateUserPage.title') + ' @' + self.username}</Button>, key: 'sm_profile'},
            {label: <PopConfirmZis title={t('logoutPage.logout')+'?'} onConfirm={() => {history.replace('/logout')}}><Button type='text' size='small' icon={<LogoutOutlined />} >{t('logoutPage.logout')}</Button></PopConfirmZis>, key: 'logout'},
            {label: <Button type='link' icon={<QuestionCircleTwoTone />} title='Help' onClick={() => {dispatch(showRightSidebar(true))}}/>, key: 'sm_help'},
        ]
    }

    //let items: {label, key, style}[]
    let items: MenuProps['items']
    if (loggedUser && loggedUser.isLoaded()) {
        items = createMenu()
    }
    else {
        items = [
            { label: <TodayDate/>, key: 'sm_date', style: {float: 'right'}}
        ]
    }

    return (
        <Header className={`AppBar ${theme === Theme.DEFAULT ? 'light' : 'dark'}`}>
            <Menu items={items} mode='horizontal'
                  disabledOverflow={true}

                  theme={theme === Theme.DEFAULT ? 'light' : 'dark'}
                  style={ loggedUser && loggedUser.isLoaded() ? {display: 'flex'} : {display: 'block'}}>
            </Menu>
        </Header>
    )
}

export default withRouter(AppBar)
