import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "antd/lib/form/Form";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {Button, Col, Form, Input, message, Row, Select, Spin, Switch, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {tailLayout} from "../../../helpers/layoutHelpers";
import isCidr from "is-cidr";
import {Store} from "antd/lib/form/interface";
import ipRegex from 'ip-regex';
import TextArea from "antd/lib/input/TextArea";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import createIpSubnet from "./actions/createIpSubnet";
import {CreateIpParams, CreateSubnetIpParams} from "./models";
import createIP from "./actions/createIP";
import {CustomerDetails} from "../../billing/customer/models";
import {AppState} from "../../../common/models";
import {sort_ident} from "../../../common/sorting";
import getCustomers from "../../billing/customer/actions/getCustomers";

interface DnsModel {
    id: number
    host: string
    address: string
}

const fakeDns: DnsModel[] = [
    {id:1080, host: 'ns1-master.zcom.cz', address: '1.1.1.1'},
    {id:1, host: 'ns1.zcom.cz', address: '1.1.1.2'},
    {id:1253, host: 'onsite-dns1.zcom.cz', address: '1.1.1.1'}
]

interface Props {
    onClose: () => void
}

const NewSubnetPage = (props: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form1] = useForm()
    const [form2] = useForm()

    const {companies, customers} = useSelector((state: AppState) => state.auth.tables)

    const [customerSource, setCustomerSource] = useState<CustomerDetails[]>([])
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | undefined>()
    const [ipmiChecked, setIpmiChecked] = useState<boolean>()
    const [selectedNS, setSelectedNS] = useState<DnsModel>()
    const [dnsSource, setDnsSource] = useState<DnsModel[]>([])


    // get settings and logged user from store
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        return (<Spin/>)
    }
    const appSetting = loggedUser.getAppSettings()

    useEffect(() => {
        // once
        setDnsSource(fakeDns)
        if(customers && customers.length) {
            const data = customers.map((c) => {return {...c, ident: c.company!.name}})
            setCustomerSource(data.sort(sort_ident))
        }
        else {
            dispatch(getCustomers('company,last_invoice'))
        }
    }, [])

    useEffect(() => {
        setSelectedCustomerId(loggedUser.customer.id)
    }, [dispatch, customers])

    const submitSubnet = (values: Store): void => {
        const params: CreateSubnetIpParams = {
            note: values.note,
            customer_id: selectedCustomerId ? selectedCustomerId : loggedUser.customer.id,
            cidr: values.cidr,
            ipmi: values.ipmi ? 1 : 0,
            vlan: values.vlan ? Number(values.vlan) : undefined,
            location: values.location ? values.location : undefined,
            dns_id: values.ns ? values.ns : undefined
        }
        dispatch(createIpSubnet(params, suc => {
            if (suc) {
                message.success(t('general.success'))
                props.onClose()
            }
        }))
    }

    return (
        <>
            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '0'}}>
                <Col span={6} className='right' >{t('ipSubnetPage.newSubnet.customer')}:&nbsp;</Col>
                <Col span={18} >
                    <Select optionFilterProp='label'
                            style={{width: '100%'}}
                            options={customerSource.map(i => ({
                                value: i.id,
                                key: i.id,
                                label: i.ident
                            }))}
                            defaultValue={loggedUser.customer.id}
                            onChange={ (value: number) => { setSelectedCustomerId(value) }}
                            showSearch
                    />
                </Col>
            </Row>

            <Row justify='space-between' className='InvoiceTopForm' style={{marginTop: '25px'}}>
                <Col span={24} >

                    <Form name='create-subnet'
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          layout="horizontal"
                          form={form1}
                          onFinish={submitSubnet}
                    >
                        <Form.Item name='note'
                                   label={t('ipSubnetPage.newSubnet.name')}
                                   rules={[{required: true, message: t('errors.field_required')}]}>
                            <Input placeholder='cloud1' />
                        </Form.Item>
                        <Form.Item name='cidr'
                                   label={t('ipSubnetPage.newSubnet.cidr')}
                                   rules={[
                                       {required: true, message: t('errors.field_required')},
                                       {validator: (_, value) => {
                                               if (isCidr(value)) {
                                                   return Promise.resolve();
                                               } else {
                                                   return Promise.reject('Validation CIDR failed.');
                                               }
                                           }, message: t('errors.field_error')
                                       }
                                   ]}>
                            <Input placeholder='185.172.35.0/24' />
                        </Form.Item>
                        <Form.Item name='location' label={t('ipSubnetPage.newSubnet.location')}>
                            <Input placeholder='TTC' />
                        </Form.Item>
                        <Form.Item name='ns' label={t('ipSubnetPage.newSubnet.ns')}>
                            <Select placeholder='ns.zcom.cz' size='middle'
                                    options={dnsSource.map(i => ({
                                        value: i.id,
                                        key: i.id,
                                        label: i.host
                                    }))}
                                    onChange={
                                        (value: number) => {
                                            setSelectedNS(dnsSource.find(c => c.id === value)!)
                                        }
                                    }
                                    showSearch
                                    optionFilterProp='label'
                            />
                        </Form.Item>
                        <Form.Item name='vlan' label={t('ipSubnetPage.newSubnet.vlan')}>
                            <Input type='number' min={1} />
                        </Form.Item>
                        <Form.Item name='ipmi' label={t('ipSubnetPage.newSubnet.ipmi')}
                                   initialValue={false}
                                   valuePropName='checked'
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={ipmiChecked}
                                onChange={() => setIpmiChecked(!ipmiChecked)}
                            />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit' className='create-button'>
                                {t('ipSubnetPage.newSubnet.create')}
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>
        </>
    )

}

export default NewSubnetPage