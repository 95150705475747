import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../common/models";
import {Button, Col, Form, Input, InputNumber, message, Row, Spin} from "antd";
import {DnsRecordModel} from "./models";
import {formItemLayout, tailLayout} from "../../../helpers/layoutHelpers";
import {useForm} from "antd/lib/form/Form";
import {Store} from "antd/lib/form/interface";
import setSoaTtl from "./actions/setSoaTtl";
import getDnsZone from "./actions/getDnsZone";

interface Props {
    record: DnsRecordModel | undefined
    serviceId: number | undefined
    closeModal: () => void
}

const {Item} = Form

const SoaTtlForm = ({record, serviceId, closeModal}: Props) => {
    const [form] = useForm()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {isLoadingZone, isSavingZone} = useSelector((state: AppState) => state.dnsservice)
    const [updateStatus, setUpdateStatus] = useState<boolean>(false)

    useEffect(() => {
        console.log('exportedFile: ')
    }, [])

    if (!record || !serviceId) {
        return (<Spin/>)
    }

    const onFormSubmit = (values: Store) => {
        // save data
        console.log('record: '+ JSON.stringify(record))
        dispatch(setSoaTtl({
            id: serviceId,
            domain: record.name,
            ttl: values.ttl,
        }, (suc: boolean) => {
            if (suc) {
                closeModal()
                dispatch(getDnsZone({
                    service_id: serviceId,
                    domain: record.name
                }))
                message.success(t('general.success'))
            }
        }))
    }

    return (
        <Form
            {...formItemLayout}
            onFinish={onFormSubmit}
            form={form}
            onChange={() => setUpdateStatus(true)}
            initialValues={{ttl: record.ttl, content: record.content}}
        >
            <Item name='content' label='SOA' labelCol={{span: 4}} wrapperCol={{span: 20}}>
                <Input disabled={true} style={{width: '100%'}} />
            </Item>

            <Item name='ttl' label='TTL' labelCol={{span: 4}} wrapperCol={{span: 20}}
                  rules={[{required: true, message: t('errors.field_required')}]}
            >
                <InputNumber min={ 1 } max={ 3600 * 24 * 365 } step={ 1 }
                             onChange={() => setUpdateStatus(true)}
                             style={{width: '120px'}} />
            </Item>

            <Row>
                <Col span={4}></Col>
                <Col span={20}>
                    <Button loading={isSavingZone} type='primary' htmlType='submit'
                            disabled={!updateStatus}>
                        {t('general.change')}
                    </Button>
                </Col>
            </Row>


        </Form>
    )
}

export default SoaTtlForm