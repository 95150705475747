import React, {useEffect, useState} from 'react'
import {Col, Form, Input, InputNumber, message, Radio, Row, Space, Typography,} from 'antd'
import {useTranslation} from 'react-i18next'
import {AppState} from 'common/models'
import {useDispatch, useSelector} from 'react-redux'
import {labelTopLayout} from 'helpers/layoutHelpers'
import Button from 'antd-button-color'
import getArchivedZone from 'pages/dns/service/actions/getArchivedZone'
import {LoadingOutlined} from '@ant-design/icons'
import DnsRecordArchivedDropdown from './DnsRecordArchivedDropdown'
import {useForm} from 'antd/es/form/Form'
import {AddDnsRecordParams, DnsRecordModel, DNSType} from "./models";
import addDnsRecord from "./actions/addDnsRecord";
import getDnsZone from "./actions/getDnsZone";
import {_isValidDomain, _isValidIP4, _isValidIP6} from "../../../common/fce";

interface Props {
    record: DnsRecordModel | undefined
    closeModal: () => void
}

const {Item} = Form


interface RTOption {
    label: string
    value: string
    description: string
    rule: string
    disabled: boolean
}

const dnsRecordTypes: RTOption[] = [
    {
        label: 'A',
        value: 'A',
        description: 'Host address',
        rule: 'IPv4',
        disabled: false
    },
    {
        label: 'AAAA',
        value: 'AAAA',
        description: 'IPv6 host address',
        rule: 'IPv6',
        disabled: false
    },
    {
        label: 'CNAME',
        value: 'CNAME',
        description: 'Canonical name for an alias',
        rule: 'Domain name',
        disabled: false
    },
    {
        label: 'MX',
        value: 'MX',
        description: 'Mail',
        rule: 'Mail server address',
        disabled: false
    },
    {
        label: 'NS',
        value: 'NS',
        description: 'Name Server',
        rule: 'Name server address',
        disabled: false
    },
    {label: 'PTR', value: 'PTR', description: 'Pointer', rule: 'Pointer', disabled: false},
    {
        label: 'SOA',
        value: 'SOA',
        description: 'SOA record',
        rule: 'SOA',
        disabled: false
    },
    {
        label: 'SRV',
        value: 'SRV',
        description: 'location of service',
        rule: 'Location',
        disabled: false
    },
    {
        label: 'TXT',
        value: 'TXT',
        description: 'Descriptive text',
        rule: 'Text value',
        disabled: false
    },
]

const isValidRecord = (type: DNSType, value: string) => {
    if (!value || !value.trim()) {
        return true
    }
    value = value.trim()
    if (type === DNSType.NS) {
        return _isValidDomain(value) || _isValidIP4(value) || _isValidIP6(value)
    }
    if (type === DNSType.A) {
        return _isValidIP4(value)
    }
    if (type === DNSType.AAAA) {
        return _isValidIP6(value)
    }
    if (type === DNSType.MX) {
        const parts = value.split(' ')
        if (parts.length != 2) {
            return false
        }
        const [priority, mailServer] = parts
        return _isValidDomain(mailServer) && parseInt(priority) > 0
    }
    if (type === DNSType.CNAME) {
        return _isValidDomain(value)
    }
    return true
}

const DnsRecordForm = ({record, closeModal}: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form] = useForm()

    const {isLoading, dnsZone, isSavingZone} = useSelector((state: AppState) => state.dnsservice)

    const [selectedDns, setSelectedDns] = useState<RTOption>()
    const [dnsRecordOptions, setDnsRecordOptions] = useState<RTOption[]>(dnsRecordTypes)


    useEffect(() => {
        if (record) {
            dispatch(getArchivedZone({
                    service_id: record.service_id,
                    domain: record.domain
                })
            )
            setSelectedDns(dnsRecordTypes.find(t => t.value === record?.type.toString()))
            let types: RTOption[]
            if (record.is_new) {
                types = dnsRecordTypes.map(t => {
                    return {...t, disabled: false}
                })
            } else {
                types = dnsRecordTypes.map(t => {
                    return {...t, disabled: t.value != record?.type.toString()}
                })
            }
            setDnsRecordOptions(types)
        }
    }, [])

    useEffect(() => {

    }, [dnsRecordOptions])

    useEffect(() => {
        console.log('selectedDns: ' + JSON.stringify(selectedDns))
    }, [selectedDns])

    const finish = (params: AddDnsRecordParams) => {
        console.log('form: ' + JSON.stringify(form.getFieldsValue()))
        dispatch(addDnsRecord(params, (suc) => {
                if (suc) {
                    dispatch(getDnsZone({service_id: params.service_id, domain: params.domain}))
                    message.success(t('dnsPage.zone_created'))
                    closeModal()
                }
            }),
        )
    }


    return (
        <Form
            {...labelTopLayout}
            form={form}
            onFinish={finish}
            initialValues={record}
        >
            <Item name='service_id' style={{display: 'none'}}>
                <Input type='hidden'/>
            </Item>
            <Item name='domain' style={{display: 'none'}}>
                <Input type='hidden'/>
            </Item>

            <Row gutter={16}>
                <Col span={24}>
                    <Item name='type' label={t('dnsPage.dnsRecordForm.input_type')} rules={[{required: true}]}>
                        <Radio.Group
                            size='large'
                            options={dnsRecordOptions}
                            onChange={(e) => {
                                if (record && record.is_new === true) {
                                    setSelectedDns(dnsRecordOptions.find((v) => v.value === e.target.value)!)
                                }
                            }}
                            optionType='button'
                            buttonStyle='solid'
                        />
                    </Item>
                </Col>

                <Col span={24}>
                    <Space align='center'>
                        <Typography.Title level={5}>
                            {selectedDns?.description}
                        </Typography.Title>
                    </Space>
                </Col>

                <Col span={16}>
                    <Item name='name'
                          rules={[{required: true}]}
                          label={t('dnsPage.dnsRecordForm.input_name')}>
                        <Input addonAfter={<strong>{dnsZone?.domain}</strong>}/>
                    </Item>
                </Col>

                <Col span={8}>
                    <Item name='ttl'
                          rules={[{required: true}]}
                          label={t('dnsPage.dnsRecordForm.input_ttl')}
                    >
                        <InputNumber
                            min={1}
                            step={1}
                            style={{width: '100%'}}
                            placeholder='3600'
                        />
                    </Item>
                </Col>

                <Col span={24}>
                    <Item name='content'
                          rules={[
                              {
                                  required: true,
                                  message: `Invalid content: ${selectedDns?.rule}`,
                                  validator: async (rule, value) => {
                                      const selectedType = form.getFieldValue('type')
                                      const isValid = isValidRecord(selectedType, value)
                                      if (!isValid) {
                                          throw new Error('Something wrong!');
                                      }
                                  }
                              },
                          ]}
                          label={t('dnsPage.dnsRecordForm.input_content')}
                    >
                        <Input placeholder={selectedDns?.rule}/>
                    </Item>
                </Col>

                <Col span={12}>
                    <Item>
                        <Button type='primary' htmlType='submit' loading={isSavingZone}>
                            {t('dnsPage.dnsRecordForm.button_create')}
                        </Button>
                    </Item>
                </Col>
                <Col span={12}>
                    {isLoading ? (
                        <LoadingOutlined/>
                    ) : (
                        <>
                            <DnsRecordArchivedDropdown
                                onSelect={(record) => form.setFieldsValue(record)}
                            />
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    )
}

export default DnsRecordForm
