import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import {AppState} from "../../../common/models";
import useLogger from "../../../common/useLogger";
import usePageSize from "../../../common/usePageSize";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import queryString, {ParsedQuery} from "query-string";
import moment, {Moment} from "moment";
import {_isNotEmptyObject, removeDiac, SQL_DATE_FORMAT, stopPropagation} from "../../../common/fce";
import {BillModel} from "./models";
import {sort_str} from "../../../common/sorting";
import SortComponent from "../../../components/SortComponent/SortComponent";
import {AutoComplete, Card, Col, message, Modal, Row, Space, Spin, Table} from "antd";
import Button from "antd-button-color";
import {
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined, FileDoneOutlined,
    FilePdfOutlined,
    FilePdfTwoTone,
    InfoCircleTwoTone
} from "@ant-design/icons";
import {protectedApiClient} from "../../../helpers/api";
import deleteBill from "./actions/deleteBill";
import Pager from "../../../components/pagination/pager";
import getBills from "./actions/getBills";
import DateRange from "../../../components/RangeFilter/DateRange";
import {PlusCircleOutlined} from "@ant-design/icons/lib/icons";
import lookupBill from "./actions/lookupBill";
import UserStorage from "../../../common/userStorage";
import PdfPreview from "../../../components/ModalDialogs/PdfPreview";
import HistoryModal from "../../../components/History/HistoryModal";
import tableCompanies from "../../login/actions/tableCompanies";
import lookupCompany from "./actions/lookupCompany";


const { confirm } = Modal
const RANGE_FROM_ALL = '2000-01-01'

const allRange = ():[string, string] => {
    return [RANGE_FROM_ALL, moment().endOf('year').format(SQL_DATE_FORMAT)]
}


const BillsPage = () => {
    const CONTROL_NAME = 'page_billing_bill'
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const {search} = useLocation()

    const {bills, pager, lookup_number, lookup_company, isLoading, isLoadingLookup} = useSelector((state: AppState) => state.bill)
    const {companies} = useSelector((state: AppState) => state.auth.tables)
    const {billing_bills} = useSelector((state: AppState) => state.sidenav)

    // data
    const [dataSource, setDataSource] = useState<BillModel[]>([])
    const [searchableBills, setSearchableBills] = useState<BillModel[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [qsFilter, setQsFilter] = useState<string>('')

    // options
    const [numberOptions, setNumberOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string | ReactNode, value: string }[]>([])
    
    // filters
    const [parsed, setParsed] = useState<ParsedQuery<string>>(queryString.parse(search))
    const [iid, setIid] = useState(0)
    const [rangeStart, setRangeStart] = useState<string>('')
    const [rangeEnd, setRangeEnd] = useState<string>('')
    const [searchNumberLookup, setSearchNumberLookup] = useState<string>('')
    const [searchNumber, setSearchNumber] = useState<string>('')
    const [searchCompanyLookup, setSearchCompanyLookup] = useState<string>('')
    const [searchCompany, setSearchCompany] = useState<string>('')

    // page states
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [historyModelId, setHistoryModelId] = useState<number | undefined>()
    const [isModalCreateVisible, setModalCreateVisible] = useState<boolean>(false)
    const [previewLoading, setPreviewLoading] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>('')
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [ready, setReady] = useState<number>(0)
    const [openDelete, setOpenDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);


    // permissions
    const [isViewer, setViewer] = useState(false)
    const [isCreator, setCreator] = useState(false)
    const [isEditor, setEditor] = useState(false)
    const [isDeleter, setDeleter] = useState(false)


    // get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'BillsPage')
    const [pageSize, setPageSize] = useState<number>(appSetting.grid_page_size)
    usePageSize(appSetting, loggedUser.user.id, pageSize)

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }


    // to handle SideNav menu pressed after 1sec
    const [triggerOneTimer, setTriggerOneTimer] = useState(0)

    useEffect(() => {
        // trigger ONCE
        setTriggerOneTimer(Date.now().valueOf())
        logger('=== ONCE  url='+search)
        setPageSize(appSetting.grid_page_size)
        if (loggedUser) {
            setViewer(loggedUser.hasAccess('page_billing_bill'))
            setCreator(loggedUser.hasAccess('page_billing_bill_create_button'))
            setEditor(loggedUser.hasAccess('page_billing_bill_edit_button'))
            setDeleter(loggedUser.hasAccess('page_billing_bill_delete_button'))
        }

        // load Options for refresh F5
        loadOptions()
        handleQueryStringFilters()
    }, [])

    useEffect(() => {
        // handle a single item by ID
        initializeRange()
        logger('=== IID '+iid)
    }, [iid])

    useEffect(() => {
        // SideNav menu pressed
        const when = triggerOneTimer + 1000
        if (triggerOneTimer > 0 && when < Date.now().valueOf()) {
            logger('--- SideNav menu pressed')
            clearFilters()
            fetchRecords(1, pageSize)
        }
    }, [billing_bills])

    useEffect(() => {
        // check if Ready
        if (companies && companies.length) {
            // options are loaded
            setReady(ready+1)
        }
    }, [companies])

    useEffect(() => {
        // populate companyOptions
        setCompanyOptions(lookup_company.map(s => ({label: s, value: s})))
    }, [lookup_company])

    useEffect(() => {
        // populate numberOptions
        setNumberOptions(lookup_number.map(s => ({label: s, value: s})))
    }, [lookup_number])

    useEffect(() => {
        // process Options
        if (ready) {
            logger(`Ready=${ready} iid=${iid}`)
            fetchRecords(pageNumber, pageSize)
        }
    }, [ready])

    useEffect(() => {
        replaceHistory()
    }, [pageSize, pageNumber, qsFilter])

    useEffect(() => {
        // update QsFilter
        if (!isModalCreateVisible) {
            const qs: string = prepareQsFilter()
            logger(`qsFilter:${qs}`)
            if (qs != qsFilter) {
                setQsFilter(qs)
            }
        }
    }, [rangeStart, rangeEnd, searchNumber, searchCompany])

    useEffect(() => {
        // when filter is changed
        // show the first page
        logger(`qsFilter changed: page: ${pageNumber}, pageSize: ${pageSize}, qs=${qsFilter}`)
        let pn = pageNumber
        if (ready) {    // do not change page for F5
            pn = 1
        }
        fetchRecords(pn, pageSize)
    }, [qsFilter])

    useEffect(() => {
        // process new server-data
        if (!ready) {
            return
        }
        if (bills.items && filterIsValid()) {
            setSearchableBills(bills.items.map(item => {
                return {
                    ...item,
                    company_name: companies.find(c => c.id === item.company_id)?.name,
                    searchableName: removeDiac(companies.find(c => c.id === item.company_id)?.name) + ';' + item.number
                }
            }))
        }
    }, [bills.items])

    useEffect(() => {
        // render data
        if (!ready) {
            return
        }
        if (filterIsValid()) {
            refreshGrid()
        }
    }, [searchableBills])



    // ------------------------------ end hooks

    const fetchRecords = (pn: number, ps: number) => {
        if (!isViewer) {
            return
        }
        setPageNumber(pn)
        setPageSize(ps)
        if (ready && filterIsValid()) {
            // purchase_from is required
            logger(`fetchRecords: page: ${pn}, pageSize: ${ps}, qs=${qsFilter}`)
            dispatch(getBills(ps, pn - 1, qsFilter, suc => {}))
        }
    }

    const refreshGrid = () => {
        logger('refreshGrid '+iid)
        setDataSource(searchableBills.sort((a, b) => sort_str(b.number, a.number)))
    }

    const replaceHistory = () => {
        if (filterIsValid()) {
            logger('replaceHistory '+qsFilter)
            const url = `/billing/bill?pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}&` + qsFilter
            history.replace(url)
        }
    }

    const loadOptions = () => {
        // load Options for refresh F5
        if (!companies || companies.length === 0) {
            dispatch(tableCompanies())
        }
    }

    const initializeRange = () => {
        // set default range (from 2000 till now)
        logger(`initializeRange`)
        if (iid && iid > 0) {
            setRangeStart(allRange()[0])
            setRangeEnd(allRange()[1])
        }
        else {
            setRangeStart(UserStorage.GetInitRangeStr()[0])
            setRangeEnd(UserStorage.GetInitRangeStr()[1])
        }
    }

    const filterIsValid = (): boolean => {
        // purchase_from is required
        return !!qsFilter && qsFilter.includes('taxing_from=')
    }

    const clearFilters = () => {
        // used when clicked on left menu
        logger('clearFilters')
        setIid(0)
        onClearNumber()
        onClearCompany()
    }

    const onClearNumber = () => {
        setSearchNumberLookup('')
        setSearchNumber('')
        setNumberOptions([])
    }

    const onChangeNumberLookup = (data: string) => {
        if (!data) {
            if (searchNumberLookup.length === 1) {
                setSearchNumberLookup('')
                fetchNumberLookup('')
            }
            return
        }
        if (data != searchNumberLookup){
            setSearchNumberLookup(data)
            fetchNumberLookup(data)
        }
    }

    const fetchNumberLookup = (searchText: string) => {
        // call lookup serial
        if (checkMinSearch(searchText)) {
            let qs: string[]
            qs = getQSFilter()
            qs.push('field=number')
            qs.push('value=' + encodeURIComponent(searchText.trim()))
            logger('lookupBill: '+qs.join("&"))
            dispatch(lookupBill('number', qs.join("&")))
        }
    }

    const onSelectNumber = (data: string) => {
        setSearchNumber(data)
    }

    const onClearCompany = () => {
        setSearchCompanyLookup('')
        setSearchCompany('')
        setCompanyOptions([])
    }

    const onSelectCompany = (data: string) => {
        setSearchCompany(data)
    }

    const onChangeCompanyLookup = (data: string) => {
        if (!data) {
            if (searchCompanyLookup.length === 1) {
                setSearchCompanyLookup('')
                fetchCompanyLookup('')
            }
            return
        }
        if (data != searchCompanyLookup){
            setSearchCompanyLookup(data)
            fetchCompanyLookup(data)
        }
    }

    const fetchCompanyLookup = (searchText: string) => {
        // call lookup for company_name
        if (checkMinSearch(searchText)) {
            let qs: string[] = []
            qs = getQSFilter()
            qs.push('field=name')
            qs.push('value=' + encodeURIComponent(searchText))
            logger('lookupCompany: ' + qs.join("&"))
            dispatch(lookupCompany('name', qs.join("&")))
        }
    }

    const getQSFilter = (): string[] => {
        let qs: string[] = []
        if (rangeStart && rangeEnd) {
            let dt1 = moment(rangeStart)
            let dt2 = moment(rangeEnd)
            qs.push('taxing_from=' + dt1.format(SQL_DATE_FORMAT))
            qs.push('taxing_to=' + dt2.format(SQL_DATE_FORMAT))
        }
        return qs
    }

    const prepareQsFilter = (): string => {
        if (iid > 0) {
            let qs: string[] = []
            // inv_no is uniq
            qs.push('id='+iid)
            qs.push('taxing_from=' + allRange()[0])
            qs.push('taxing_to=' + allRange()[1])
            return qs.join("&")
        }
        // load filtered data from server
        let qs: string[]
        qs = getQSFilter()
        if (checkMinSearch(searchCompany)) {
            qs.push('company[name]=' + encodeURIComponent(searchCompany))
        }
        if (checkMinSearch(searchNumber)) {
            qs.push('number=' + encodeURIComponent(searchNumber))
        }
        logger('prepareQsFilter: '+qs.join("&"))
        return qs.join("&")
    }

    const handleQueryStringFilters = () => {
        // parse query string
        // and set filters
        // /billing/bills?filter_id=6&number=122&from=2022-06-01&to=2022-08-10
        let passedRange = 0
        if (parsed && _isNotEmptyObject(parsed)) {
            logger(`handleQueryStringFilters..`)
            const qs_id: string | string[] | null = parsed['id']
            if (qs_id && typeof qs_id === 'string') {
                // call by ID
                const id = parseInt(qs_id)
                if (id > 0) {
                    setIid(id)
                    return
                }
            }

            // iid=0
            const qs_name: string | string[] | null = parsed['company[name]']
            if (qs_name && typeof qs_name === 'string') {
                setSearchCompany(qs_name)
            }
            const qs_number: string | string[] | null = parsed['number']
            if (qs_number && typeof qs_number === 'string') {
                setSearchNumber(qs_number)
            }
            const qs_size: string | string[] | null = parsed['pagination[pageSize]']
            if (qs_size && typeof qs_size === 'string') {
                const ps = parseInt(qs_size)
                if (ps > 0) {
                    setPageSize(ps)
                }
            }
            const qs_num: string | string[] | null = parsed['pagination[page]']
            if (qs_num && typeof qs_num === 'string') {
                const pn = parseInt(qs_num)
                setPageNumber(pn)
            }
            // period
            const qs_from: string | string[] | null = parsed['purchase_from']
            if (qs_from && typeof qs_from === 'string' && qs_from.length > 8) {
                setRangeStart(qs_from)
                passedRange++
            }
            const qs_to: string | string[] | null = parsed['purchase_to']
            if (qs_to && typeof qs_to === 'string' && qs_to.length > 8) {
                setRangeEnd(qs_to)
                passedRange++
            }
        }
        if (passedRange === 0) {     // init searchRange
            initializeRange()
        }
    }

    const checkMinSearch = (val: string | undefined) => {
        if (!val) {
            return false
        }
        return val.length > appSetting.min_search_length
    }

    const handleDateChange = (dt_from: string, dt_to: string) => {
        // use SQL_DATE_FORMAT
        if (dt_from && dt_to) {
            logger(`dt_from: ${dt_from} - dt_to: ${dt_to}`)
            setRangeStart(dt_from)
            setRangeEnd(dt_to)
        }
    }

    const pdfPreview = (id: number) => {
        // download and show preview PDF
        if (id > 0) {
            setPreviewVisible(true)
            setPreviewLoading(true)
            protectedApiClient.get<string>(`/billing/bill/${id}/preview`)
                .then(response => {
                    setPreviewLoading(false)
                    setPreview(response.data)
                })
                .catch(error => {
                    message.error(t('billing.invoice.preview_fail'))
                    setPreviewLoading(false)
                })
        }
    }

    const showConfirmDelete = (b: BillModel, title: string) => {
        if (!b) {
            return
        }
        // if (!isDeleter) {
        //     message.error(t('general.error403'))
        //     return
        // }
        confirm({
                icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
                title: title,
                content: <p>{b.number}</p>,
                okText: t('general.yes'),
                cancelText: t('general.cancel'),
                okButtonProps: { loading: confirmDelete },
                className: 'confirm-alert',
                onOk() {
                    setConfirmDelete(true)
                    dispatch(deleteBill(b.id,suc => {
                        setConfirmDelete(false)
                        setOpenDelete(false)
                        if (suc) {
                            message.success(t('general.success'))
                        }
                    }))
                },
                onCancel() {
                    setOpenDelete(false)
                },
            }
        )
    }

    const FilterByCompany = (
        <AutoComplete
            showSearch
            value={searchCompanyLookup}
            options={companyOptions}
            style={{ width: 160 }}
            dropdownMatchSelectWidth={400}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectCompany(e.currentTarget.value)
                }
            }}
            onSelect={onSelectCompany}
            onSearch={onChangeCompanyLookup}
            onChange={onChangeCompanyLookup}
            onClear={onClearCompany}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            placeholder={t('billing.bill.company_id')}
            allowClear={true}
        />
    )
    
    const FilterByNumber = (
        <AutoComplete
            showSearch
            value={searchNumberLookup}
            options={numberOptions}
            style={{ width: 80 }}
            dropdownMatchSelectWidth={200}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSelectNumber(e.currentTarget.value)
                }
            }}
            onSelect={onSelectNumber}
            onSearch={onChangeNumberLookup}
            onChange={onChangeNumberLookup}
            onClear={onClearNumber}
            onClick={stopPropagation}
            notFoundContent={isLoadingLookup && <Spin />}
            filterOption={false}
            optionFilterProp='label'
            placeholder={t('billing.bill.number')}
            allowClear={true}
        />
    )

    const columns = [
        {
            title: t('billing.bill.date_of_taxing'),
            dataIndex: 'date_of_taxing',
            key: 'date_of_taxing',
            className: 'center',
            showSorterTooltip: false,
            width: 90,
            render: (dt: number) => appSetting?.renderDate(dt),
            sorter: (a, b) => sort_str(a.date_of_taxing, b.date_of_taxing)
        },
        {
            title: <div style={{marginRight: "1rem"}}>
                {FilterByNumber}
            </div>,
            dataIndex: 'number',
            key: 'number',
            showSorterTooltip: false,
            width: 120,
            sorter: (a, b) => sort_str(a.number, b.number),
            render: (text, record) => {
                return (
                    <Link to={`/billing/bill/edit/${record.id}`}>{text}</Link>
                )
            }
        },
        {
            title: <div style={{marginRight: "1rem"}}>
                {FilterByCompany}
            </div>,
            dataIndex: 'company_id',
            key: 'company_id',
            ellipsis: true,
            showSorterTooltip: false,
            sorter: (a, b) => SortComponent(a.searchableName, b.searchableName),
            render: (text, rec) => {
                return (
                    <Link to={`/billing/bill/edit/${rec.id}`}>{rec.company_name}</Link>
                )
            }
        },
        {
            title: t('billing.bill.total_without_vat'),
            dataIndex: 'total_without_vat',
            key: 'total_without_vat',
            className: 'right',
            showSorterTooltip: false,
            width: 100,
            render : (price: number) => appSetting?.renderPrice(price),
            sorter: (a, b) => a.total_without_vat - b.total_without_vat
        },
        {
            title: t('billing.bill.total_vat'),
            dataIndex: 'total_vat',
            key: 'total_vat',
            className: 'right',
            showSorterTooltip: false,
            width: 100,
            render: (p: number) => appSetting.renderPrice(p),
            sorter: (a, b) => a.total_vat - b.total_vat
        },
        {
            title: t('billing.bill.amount_due'),
            dataIndex: 'amount_due',
            key: 'amount_due',
            className: 'right',
            showSorterTooltip: false,
            width: 100,
            render: (p: number) => appSetting.renderPrice(p),
            sorter: (a, b) => a.amount_due - b.amount_due
        },
        {
            title: t('billing.bill.currency'),
            dataIndex: 'currency',
            showSorterTooltip: false,
            width: 100,
            key: 'currency',
            className: 'center',
            sorter: (a, b) => sort_str(a.currency, b.currency)
        },
        {
            title: t('billing.bill.date_of_maturity'),
            dataIndex: 'date_of_maturity',
            key: 'date_of_maturity',
            className: 'center',
            showSorterTooltip: false,
            width: 100,
            render: (dt: number) => appSetting?.renderDate(dt),
            sorter: (a, b) => sort_str(a.date_of_maturity, b.date_of_maturity)
        },
        {
            title: t('billing.bill.action'),
            key: 'action',
            dataIndex: 'action',
            width: 140,
            render: (_, record: BillModel) => (
                <Space size={1}>
                    {
                        (record.attachments && record.attachments.length) ? (
                                <Button title={t('billing.invoice.table.download')}
                                        icon={<FilePdfTwoTone />}
                                        size='small'
                                        type='text'
                                        className='actionButton'
                                        onClick={() => pdfPreview(record.id)}
                                />
                            )
                            :
                            (<Button title={t('billing.invoice.table.no_download')}
                                     icon={<FilePdfOutlined style={{color: 'lightgray'}} />}
                                     size='small'
                                     type='text'
                                     className='actionButton'
                            />)
                    }


                    {
                        isEditor && (
                            <Link to={`/billing/bill/edit/${record.id}`}>
                                <Button type='text' size='small'
                                        className='actionButton'
                                        icon={<EditTwoTone twoToneColor='green'/>}/>
                            </Link>
                        )
                    }
                    {
                        isDeleter && (
                            <Button type='text' danger size='small'
                                    className='actionButton'
                                    onClick={() => showConfirmDelete(record, t('billing.bill.confirm_delete'))}
                                    icon={<DeleteTwoTone twoToneColor='red'/>} />
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                setHistoryModelId(record.id)
                                record.number && setHistoryTitle(record.number)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone />}
                            className='actionButton'
                    />
                </Space>
            ),
        },
    ]

    if (!appSetting || Object.keys(appSetting).length === 0) {
        return (<Spin />)
    }

    if (!ready) {
        return (<Spin/>)
    }

    return (
        <>
            <Card className='billsPage'
                title={
                    <Row>
                        <Col span={12}>
                            <FileDoneOutlined /> &nbsp; {t('billing.bill.title')}
                        </Col>
                        <Col span={2} className='right' style={{padding: '2px'}}>{t('billing.bill.period_filter')}:&nbsp;</Col>
                        <Col span={10}>&nbsp;
                            <DateRange format={appSetting.date_picker_format} // not Moment formats!!
                                       initStart={UserStorage.GetInitRangeStr()[0]}
                                       initEnd={UserStorage.GetInitRangeStr()[1]}
                                       onChange={handleDateChange}
                            />
                        </Col>
                    </Row>
                }
                extra={
                    <>
                        {
                            isCreator && (
                                <Link to='/billing/bill/create'>
                                    <Button type='primary'>
                                        <PlusCircleOutlined/> {t('billing.bill.new_doc')}
                                    </Button>
                                </Link>
                            )
                        }
                        {
                            !isCreator && (
                                <Button type='primary' disabled={true}>
                                    <PlusCircleOutlined/> {t('billing.bill.new_doc')}
                                </Button>
                            )
                        }
                    </>
                }
                >

                <Table<BillModel>
                    className='BillsTable'
                    showHeader={true}
                    size='small'
                    bordered={true}
                    columns={columns}
                    scroll={{ x: 680 }}
                    dataSource={dataSource}
                    loading={isLoading}
                    rowKey='id'
                    pagination={false}
                    footer={() => Pager({
                        filename: 'fn',
                        total: pager.totalCount,
                        current: pager.page,
                        pageSize: pager.pageSize,
                        data: dataSource,
                        fetchRecords: fetchRecords
                    })}
                    onChange={(ev) => {
                        ev.pageSize && setPageSize(ev.pageSize)
                    }}
                />

            </Card>

            <PdfPreview
                    title={t('billing.invoice.pdf_preview')}
                    loading={previewLoading}
                    visible={previewVisible}
                    hide={() => {setPreviewVisible(false)}}
                    preview={preview}
                />

                <HistoryModal service='billing' model='Bill'
                              modelId={historyModelId}
                              title={historyTitle}
                              isModalVisible={isHistoryModalVisible}
                              setModalVisible={() => setHistoryModalVisible(false)}
                              modalRender={(modal) => (
                                  <Draggable bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                                      <div ref={draggleRef}>{modal}</div>
                                  </Draggable>
                              )}
                />
        </>
    )
}

export default BillsPage
