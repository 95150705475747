import {InventoryModel} from "../pages/inventory/inventory/models";
import {removeDiac} from "./fce";

export const sort_num = (a, b) => {if(!a) return -1; if(!b) return 1; return (a < b) ? -1 : 1}
export const sort_id = (a, b) => (a.id < b.id) ? -1 : 1
export const sort_numeric = (a, b) => {if(!a) return -1; if(!b) return 1; return (parseFloat(a) < parseFloat(b)) ? -1 : 1}
export const sort_number = (a, b) => {if(!a.number) return -1; if(!b.number) return 1;  return (a.number.toString().toLowerCase() > b.number.toString().toLowerCase()) ? -1 : 1;}
export const sort_str = (a, b) => {if(!a) return -1; if(!b) return 1;  return (removeDiac(a) < removeDiac(b)) ? -1 : 1;}
export const sort_date = (a, b) => {if(!a) return -1; if(!b) return 1;  return (a > b) ? -1 : 1;}
export const sort_name = (a, b) => {if(!a || !a.name) return -1; if(!b || !b.name) return 1;  return (removeDiac(a.name) < removeDiac(b.name)) ? -1 : 1;}
export const sort_desc = (a, b) => {if(!a || !a.description) return -1; if(!b || !b.description) return 1;  return (removeDiac(a.description) < removeDiac(b.description)) ? -1 : 1;}


export const sort_position = (a, b) => (a.position < b.position) ? -1 : 1
export const sort_hostname = (a, b) => {if(!a || !a.hostname) return -1; if(!b || !b.hostname) return 1;  return (a.hostname.toLowerCase() < b.hostname.toLowerCase()) ? -1 : 1;}
export const sort_host = (a, b) => {if(!a || !a.host) return -1; if(!b || !b.host) return 1;  return (a.host.toLowerCase() < b.host.toLowerCase()) ? -1 : 1;}
export const sort_searchable = (a, b) => (a.searchableName < b.searchableName) ? -1 : 1
export const sort_label = (a, b) => (removeDiac(a.label) < removeDiac(b.label)) ? -1 : 1
export const sort_title = (a, b) => (removeDiac(a.title) < removeDiac(b.title)) ? -1 : 1
export const sort_create = (a, b) => {if(!a) return -1; if(!b) return 1; return (parseFloat(a.created_at) > parseFloat(b.created_at)) ? -1 : 1}
export const sort_path = (a, b) => (a.path.toLowerCase() < b.path.toLowerCase()) ? -1 : 1
export const sort_iso = (a, b) => ((a.iso.toLowerCase()) < (b.iso.toLowerCase())) ? -1 : 1
export const sort_sid = (a, b) => (a.sid.toLowerCase() < b.sid.toLowerCase()) ? -1 : 1
export const sort_cidr = (a, b) => {if(!a || !a.cidr) return -1; if(!b || !b.cidr) return 1;  return (a.cidr.toLowerCase() < b.cidr.toLowerCase()) ? -1 : 1;}
export const sort_country_rate = (a, b) => ((a.country.toLowerCase() + a.rate.toString()) < (b.country.toLowerCase() + b.rate.toString())) ? -1 : 1
export const sort_version = (a, b) => {if(!a) return -1; if(!b) return 1;  return (a.version > b.version) ? -1 : 1;}
export const sort_taxing = (a, b) => {if(!a) return -1; if(!b) return 1;  return (a.date_of_taxing > b.date_of_taxing) ? -1 : 1;}
export const sort_rate = (a, b) => {if(!a) return -1; if(!b) return 1;  return (a.tax_rate < b.tax_rate) ? -1 : 1;}
export const sort_resource = (a, b) => (a.resource.toLowerCase() < b.resource.toLowerCase()) ? -1 : 1
export const sort_ident = (a, b) => {
    const ZCOM_ID = 1;
    if(!a) return -1; if(!b) return 1;
    if( a.id === ZCOM_ID && b.id > ZCOM_ID) {
        return -1
    }
    if( a.id > ZCOM_ID && b.id === ZCOM_ID) {
        return 1
    }
    if(!a.ident) return -1; if(!b.ident) return 1;
    return (a.ident.toLowerCase() < b.ident.toLowerCase()) ? -1 : 1
}
export const sort_subnet = (a, b) => {
    if( a.id === 1 && b.id === 2) {
        return -1
    }
    if( a.id === 2 && b.id === 1) {
        return 1
    }
    if( a.id === 1 || a.id === 2) {
        return -1
    }
    if( b.id === 1 || b.id === 2) {
        return 1
    }
    return (a.id < b.id) ? -1 : 1
}
export const sort_inventory = (a: InventoryModel | undefined, b: InventoryModel | undefined) => {
    if( !a ) {
        return -1
    }
    if( !b) {
        return 1
    }
    return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1
}