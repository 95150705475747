import React, {useEffect, useRef, useState} from 'react'
import {Card, Modal, message, Space, Table, Input, TableColumnsType, Tooltip, Tag, AutoComplete} from 'antd'
import {
    GlobalOutlined,
    LoadingOutlined,
    PlusCircleOutlined
} from '@ant-design/icons/lib/icons'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'common/models'
import {DnsServiceModel, DNSType, RemoveDnsRecordParams} from './models'
import Button from 'antd-button-color'
import DnsServiceForm from 'pages/dns/service/DnsServiceForm'
import getDnsServices from './actions/getDnsServices'
import updateDnsService from './actions/updateDnsService'
import createDnsService from './actions/createDnsService'
import deleteDnsService from './actions/deleteDnsService'
import './DnsServicePage.scss'
import PopConfirmZis from "../../../components/popconfirm/PopConfirmZis";
import {ColumnsType} from "antd/lib/table";
import {PAGING} from "../../../common/enums";
import {useLoggedUser} from "../../../helpers/loginUserHelper";
import {LoadingIndicator} from "../../../components";
import ErrorPage403 from "../../../components/Errors/ErrorPage403";
import useLogger from "../../../common/useLogger";
import usePageSize from "../../../common/usePageSize";
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {
    DeleteTwoTone,
    EditTwoTone, FileTextTwoTone, InfoCircleOutlined,
    InfoCircleTwoTone,
    PlusCircleTwoTone,
    PlusSquareTwoTone, WarningOutlined,
    WarningTwoTone
} from "@ant-design/icons";
import {removeDiac, stopPropagation} from "../../../common/fce";
import {useHistory} from "react-router";
import {DeleteDnsZoneParams, DnsRecordModel, DnsZone, DnsZoneModel} from "./models";
import deleteDnsZone from "./actions/deleteDnsZone";
import getDnsZones from "./actions/getDnsZones";
import {sort_name} from "../../../common/sorting";
import getDnsZone from "./actions/getDnsZone";
import setSoaTtl from "./actions/setSoaTtl";
import removeDnsRecord from "./actions/removeDnsRecord";
import DnsZoneForm from "./DnsZoneForm";
import DnsZoneExport from "./DnsZoneExport";
import getExportData from "./actions/getExportData";
import DnsRecordForm from "./DnsRecordForm";
import HistoryLog from "../../../components/History/HistoryLog";
import getHistoryLogs from "../../../components/History/actions/getHistoryLogs";
import SoaTtlForm from "./SoaTtlForm";
import DnsZoneSecSwitch from "./DnsZoneSecSwitch";
import DnsChecker from "./DnsChecker";


const renderDriver = (name: string) => {
    if (!name) {
        return ''
    }
    if (name.toLowerCase() === 'powerdns') {
        return (<Tag color="geekblue">{name}</Tag>)
    }
    else {
        return (<Tag color="orange">{name}</Tag>)
    }
}


const DnsServicePage = () => {
    const CONTROL_NAME = 'page_dns_servers'
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const {isLoading, isLoadingZone, isSavingZone, dnsServices,
           dnsZones, dnsRecords, dnsZone} = useSelector((state: AppState) => state.dnsservice)
    const {dns_services} = useSelector((state: AppState) => state.sidenav)

    const [isDnsViewer, setDnsViewer] = useState(false)
    const [isDnsCreator, setDnsCreator] = useState(false)
    const [isDnsEditor, setDnsEditor] = useState(false)
    const [isDnsDeleter, setDnsDeleter] = useState(false)
    const [isZoneCreator, setZoneCreator] = useState(false)
    const [isZoneEditor, setZoneEditor] = useState(false)
    const [isZoneDeleter, setZoneDeleter] = useState(false)

    const [dataToUpdate, setDataToUpdate] = useState<DnsServiceModel>()
    const [dataSource, setDataSource] = useState<DnsServiceModel[]>([])
    const [sourceFull, setSourceFull] = useState<DnsServiceModel[]>([])
    const [zonesData, setZonesData] = useState<DnsZone[]>([])
    const [recordsData, setRecordsData] = useState<DnsRecordModel[]>([])
    const [selectedRecord, setSelectedRecord] = useState<DnsRecordModel>()

    const [host, setHost] = useState('')
    const [zone, setZone] = useState('')
    const [selectedDnsServiceId, setSelectedDnsServiceId] = useState(0)
    const [selectedZone, setSelectedZone] = useState<string>('')
    const [isDnsModalVisible, setDnsModalVisible] = useState<boolean>(false)
    const [isZoneModalVisible, setZoneModalVisible] = useState<boolean>(false)
    const [isZoneExportModalVisible, setZoneExportModalVisible] = useState<boolean>(false)
    const [isDnsRecordModalVisible, setDnsRecordModalVisible] = useState<boolean>(false)
    const [isSoaModalVisible, setSoaModalVisible] = useState<boolean>(false)
    const [isCheckerModalVisible, setCheckerModalVisible] = useState(false)
    const [isHistoryModalVisible, setHistoryModalVisible] = useState(false)
    const [historyTitle, setHistoryTitle] = useState('')
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>()
    const [expandedZoneKeys, setExpandedZoneKeys] = useState<string[]>()
    const hideModal = () => setDnsModalVisible(false)
    const showModal = () => setDnsModalVisible(true)


// get settings and current user
    const loggedUser = useLoggedUser()
    if (!loggedUser || !loggedUser.isLoaded()) {
        // waiting..
        return (
            <div className="fullwidth-loading" >
                <LoadingIndicator/>
            </div>
        )
    }

    // required authorization
    if (!loggedUser.hasAccess(CONTROL_NAME)) {
        return <ErrorPage403 />
    }

    // settings
    const appSetting = loggedUser.getAppSettings()
    const SEARCH_MIN = appSetting.min_search_length

    // usage: logger(msg, obj=null)
    const logger = useLogger(appSetting, 'DnsServicePage')

    // history drag modal
    const [disabled, setDisabled] = useState(true)
    const draggleRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    }

    useEffect(() => {
        // trigger ONCE
        const access = loggedUser.hasAccess('page_dns_servers')
        setDnsViewer(access)
        setDnsCreator(loggedUser.hasAccess('page_dns_servers_create_button'))
        setDnsEditor(loggedUser.hasAccess('page_dns_servers_edit_button'))
        setDnsDeleter(loggedUser.hasAccess('page_dns_servers_delete_button'))
        setZoneCreator(loggedUser.hasAccess('page_dns_zone_create_button'))
        setZoneEditor(loggedUser.hasAccess('page_dns_zone_edit_button'))
        setZoneDeleter(loggedUser.hasAccess('page_dns_zone_delete_button'))
        if (!isLoading && (access)) {
            dispatch(getDnsServices())
        }
    }, [])

    useEffect(() => {
        // trigger from Side menu clicked
        if (!isLoading && (isDnsViewer)) {
            // reload
            dispatch(getDnsServices())
        }
    }, [dns_services])

    useEffect(() => {
        if (dnsServices) {
            setSourceFull(dnsServices.map( s => {
                return {...s,
                    searchableName: removeDiac(s.host),
                    driver_name: s.driver ? s.driver.name : ''
                }
            }))
        }
        refreshGrid()
    }, [dnsServices, host])

    useEffect(() => {
        refreshGridZone()
    }, [dnsZones, zone])

    useEffect(() => {
        refreshGridRecord()
    }, [dnsZone])

    useEffect(() => {
        refreshGrid()
    }, [sourceFull])


    const refreshGrid = () => {
        setDataSource(filtered())
    }

    const filtered = () => {
        // search for pattern
        if (!sourceFull) {
            return []
        }
        let qs: string[] = []
        let data = sourceFull
        if (host && host.length > SEARCH_MIN) {
            data = data.filter((s) => s.host && removeDiac(s.host).includes(removeDiac(host)))
            qs.push('host=' + removeDiac(host))
        }

        history.replace('/dns/servers?' + qs.join("&"))
        logger(`filtered sourceFull: ${sourceFull.length}, dataSource: ${data.length}`)
        return data
    }

    const refreshGridZone = () => {
        setZonesData(filteredZone().sort(sort_name))
    }

    const filteredZone = () => {
        // search for pattern
        if (!dnsZones) {
            return []
        }
        let qs: string[] = []
        let data = dnsZones
        if (zone && zone.length > SEARCH_MIN) {
            data = data.filter((z) => z.name && removeDiac(z.name).includes(removeDiac(zone)))
            qs.push('zone=' + removeDiac(host))
        }

        history.replace('/dns/servers?' + qs.join("&"))
        logger(`filtered dnsZones: ${dnsZones.length}, dataSource: ${data.length}`)
        return data
    }

    const refreshGridRecord = () => {
        setRecordsData(dnsRecords.sort(sort_name))
    }

    const onDnsRecordDelete = (params: RemoveDnsRecordParams) => {
        dispatch(removeDnsRecord(params, (isOk) => {
            dispatch(getDnsZone({service_id: params.id, domain: params.domain}))
                isOk && message.success(t('dnsPage.record_removed'))
            })
        )
    }

    const handleCreate = () => {
        setDataToUpdate(undefined)
        setDnsModalVisible(true)
    }

    const handleDelete = (id: number) => {
        dispatch(deleteDnsService(id, isOk => isOk && message.success(t('dnsPage.deleted'))))
    }

    const fetchHistory = (service: string, model: string, mid: number | string) => {
        setHistoryTitle(`${service} / ${model} / ${mid}`)
        dispatch(getHistoryLogs(service, model, mid))
    }

    const onFinish = (values: DnsServiceModel) => {
        dataToUpdate ? dispatch(updateDnsService(
                {...values, id: dataToUpdate.id,},
                isOk => {
                    if (isOk) {

                        message.success(t('dnsPage.updated'))
                    }
                }))
            : dispatch(createDnsService({...values,}, (isOk) => isOk && message.success(t('dnsPage.created')),),)
    }

    const dnsZoneDelete = (domain: string) => {
        const params: DeleteDnsZoneParams = {domain: domain, service_id: selectedDnsServiceId}
        dispatch(deleteDnsZone(params, suc => {
            if (suc) {
                message.success(t('ipSubnetPage.ip_deleted'))
            }
        }))
    }

    const handleExpandZone = (expanded, rec: DnsZone) => {
        const keys: string[] = [];
        if (expanded) {
            keys.push(rec.name)
            setExpandedZoneKeys(keys)
            dispatch(getDnsZone({service_id: selectedDnsServiceId, domain: rec.name}))
            setSelectedZone(rec.name)
        }
        else {
            setExpandedZoneKeys([])
            setSelectedZone('')
        }
    }

    const handleExpand = (expanded, rec: DnsServiceModel) => {
        const keys: number[] = [];
        if (expanded) {
            keys.push(rec.id)
            setExpandedRowKeys(keys)
            dispatch(getDnsZones(rec.id))
            setSelectedDnsServiceId(rec.id)
        }
        else {
            setExpandedRowKeys([])
            setSelectedDnsServiceId(0)
        }
    }

    const FilterByHost = (
        <AutoComplete
            placeholder={t('dnsPage.host')}
            style={{ width: '120px' }}
            value={host}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => { setHost(v) }}
        />
    )

    const FilterByZone = (
        <AutoComplete
            placeholder={t('dnsPage.zone')}
            style={{ width: '120px' }}
            value={zone}
            allowClear={true}
            onClick={stopPropagation}
            onKeyDown={(e) => {if (e.key === 'Enter') {e.stopPropagation()}}}
            onChange={(v) => { setZone(v) }}
        />
    )

    const btnAddZone = (selectedDnsServiceId > 0) ? (
        <Button title={t('general.add')}
                type='primary' size="small"
                disabled={!isZoneCreator}
                onClick={() => {
                    setZoneModalVisible(true)
                }}
                icon={<PlusCircleTwoTone/>}>{t('dnsPage.add_zone')}</Button>) : 'Action'

    const getEmptyRecord = () => {
        return {
            service_id: selectedDnsServiceId,
            domain: selectedZone,
            name: '',
            type: DNSType.A,
            ttl: 3600,
            content: '',
            is_new: true
        }
    }

    const btnAddRecord = (selectedDnsServiceId > 0) ? (
        <Button title={t('dnsPage.add_record')}
                type='primary' size="small"
                disabled={!isZoneEditor}
                onClick={() => {
                    setSelectedRecord(getEmptyRecord())
                    setDnsRecordModalVisible(true)}
        }
                icon={<PlusCircleTwoTone/>}>{t('dnsPage.add_record')}</Button>) : 'Action'

    const expandedZoneRender = () => {
        const zoneColumns: TableColumnsType<DnsRecordModel> = [
            {
                title: (<>
                    <span>Name</span>
                    <div style={{float: 'right'}}>
                        <span style={{fontWeight: 'normal', fontSize: '0.8em'}}>DNSSEC</span>&nbsp;
                        <DnsZoneSecSwitch zone={selectedZone} serviceId={selectedDnsServiceId} disabled={!isZoneEditor} />
                    </div></>),
                dataIndex: 'name',
                width: '40%',
                key: 'name' },
            {
                title: 'TTL',
                dataIndex: 'ttl',
                width: 80,
                key: 'ttl'
            },
            { title: 'Type', dataIndex: 'type', width: 80, key: 'type' },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                width: '200px',
                ellipsis: true,
            },
            {
                title: btnAddRecord,
                key: 'action',
                dataIndex: 'action',
                width: '80px',
                align: 'center',
                showSorterTooltip: false,
                render: (_, rec) => (
                        <Space size={1}>
                            {
                                isZoneEditor && rec.type != DNSType.SOA &&
                                <PopConfirmZis title={t('dnsPage.try_remove_record')} onConfirm={() => {
                                    const params: RemoveDnsRecordParams = {
                                        id: selectedDnsServiceId,
                                        domain: selectedZone,
                                        name: rec.name,
                                        type: rec.type.toString()
                                    }
                                    onDnsRecordDelete(params)
                                }
                            }
                                >
                                    <Button type='text' icon={<DeleteTwoTone twoToneColor='red' />} />
                                </PopConfirmZis>
                            }

                            {
                                isDnsEditor && rec.type === DNSType.SOA && (
                                    <Button title={t('general.update')}
                                            type='text' size='small'
                                            onClick={() => {
                                                setSelectedRecord({...rec,
                                                    service_id: selectedDnsServiceId,
                                                    domain: selectedZone
                                                })
                                                setSoaModalVisible(true)
                                            }}
                                            className='actionButton'
                                            icon={<EditTwoTone twoToneColor='green'/>}
                                    />
                                )
                            }
                        </Space>

                ),
            },
        ]

        return <>
            <Table columns={zoneColumns}
                      bordered
                      rowKey={(rec) => `${rec.type}.${rec.name}.${rec.content}`}
                      className='dnsRecordsTable'
                      dataSource={recordsData.sort((a) => (a.type === 'SOA' ? -1 : 1))}
                      loading={false}
                      pagination={false}
                      size='small'
        /></>
    }

    const expandedRowRender = () => {
        const nestedcolumns: TableColumnsType<DnsZone> = [
            { title: FilterByZone,
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => text
            },
            { title: btnAddZone,
                key: 'action',
                dataIndex: 'action',
                width: '140px',
                align: 'center',
                showSorterTooltip: false,
                render: (text: string, rec: DnsZone) => (
                    <Space size={1}  onClick={(e) => e.stopPropagation()}>
                        <Button title={t('dnsPage.zone_file')} size='small'
                                onClick={() => {
                                    // download zone file
                                    setSelectedZone(rec.id)
                                    dispatch(getExportData({service_id: selectedDnsServiceId, domain: rec.id}))
                                    logger('download zone file')
                                    setZoneExportModalVisible(true)
                                }}
                                className='actionButton'
                                icon={<FileTextTwoTone />}
                        />

                        { (rec.name) && isZoneDeleter && (
                            <PopConfirmZis title={t('ipSubnetPage.confirm_delete_ip')} onConfirm={() => dnsZoneDelete(rec.name)}>
                                <Button type="text" danger size="small"
                                        disabled={false}
                                        title={t('general.delete')}
                                        className='actionButton'
                                        icon={<DeleteTwoTone twoToneColor="red" />}
                                />
                            </PopConfirmZis>
                        )
                        }
                        <Button title={t('general.btnHistory')} size='small'
                                onClick={() => {
                                    fetchHistory('dns', 'Zone', rec.name)
                                    setHistoryModalVisible(true)
                                }}
                                className='actionButton'
                                icon={<InfoCircleTwoTone />}
                        />
                    </Space>)
            },
        ];

        return <>
            <Table columns={nestedcolumns}
                      dataSource={zonesData}
                      rowKey="name"
                      loading={isLoadingZone}
                      className='dnsZonesTable'
                      bordered={true}
                      size='small'
                      rowClassName={(rec) => { return 'free'}}
                      expandable={{
                          expandedRowRender: expandedZoneRender,
                          expandRowByClick: true,
                          expandedRowClassName: (rec, index, indent) => 'expandedRow',
                          expandedRowKeys: expandedZoneKeys
                      }}
                      onExpand={handleExpandZone}
                      onRow={(rec:DnsZone, rowIndex) => {
                          if (expandedZoneKeys && expandedZoneKeys.includes(rec.id)) {
                              return { className: 'expandedRow' };
                          }
                          return { className: '' }
                      }}
                      pagination={{
                          defaultPageSize: 25,
                          pageSizeOptions: PAGING,
                          showSizeChanger: false
                      }} />
        </>
    }

    const columns: ColumnsType<DnsServiceModel> = [
        {
            title: FilterByHost,
            dataIndex: 'host',
            key: 'host',
            width: '200px',
            align: 'left',
            fixed: 'left'
        },
        {
            title: t('dnsPage.driver'),
            dataIndex: 'driver_name',
            key: 'driver_name',
            render: (name: string, record: DnsServiceModel) => renderDriver(name)
        },
        {
            title: t('dnsPage.protocol'),
            dataIndex: 'protocol',
            key: 'protocol',
        },
        {
            title: t('dnsPage.port'),
            dataIndex: 'port',
            key: 'port'
        },
        {
            title: t('dnsPage.base_url'),
            dataIndex: 'base_url',
            key: 'base_url',
        },
        {
            title: (<div className='center'>Action</div>),
            key: 'action',
            dataIndex: 'action',
            width: '140px',
            align: 'right',
            render: (text: string, record: DnsServiceModel) => (
                <Space size={1}  onClick={(e) => e.stopPropagation()}>
                    {
                        isDnsViewer && (
                            <Button title={t('dnsPage.check_zones')}
                                    type='text' size='small'
                                    onClick={() => {
                                        // show dns-checker
                                        setSelectedDnsServiceId(record.id)
                                        setCheckerModalVisible(true)
                                    }}
                                    className='actionButton'
                                    icon={<WarningTwoTone twoToneColor='orange'/>}
                            />
                        )
                    }
                    {
                        isDnsEditor && (
                            <Button title={t('general.update')}
                                    type='text' size='small'
                                    onClick={() => {
                                        setDataToUpdate(record)
                                        showModal()
                                    }}
                                    className='actionButton'
                                    icon={<EditTwoTone twoToneColor='green'/>}
                            />
                        )
                    }

                    {
                        isDnsDeleter && (
                            <PopConfirmZis title={t('dnsPage.for_delete_dns')} onConfirm={() => handleDelete(record.id)}>
                                <Button title={t('general.delete')}
                                        type='text' danger size='small'
                                        className='actionButton'
                                        icon={<DeleteTwoTone twoToneColor='red'/>}
                                />
                            </PopConfirmZis>
                        )
                    }

                    <Button title={t('general.btnHistory')} size='small'
                            onClick={() => {
                                fetchHistory('dns', 'DnsService', record.id)
                                setHistoryModalVisible(true)
                            }}
                            icon={<InfoCircleTwoTone/>}
                            className='actionButton'
                    />
                </Space>
            ),
        }
    ]

    return (
        <>
            <Card
                loading={isLoading}
                className='DnsServicePage'
                title={<><GlobalOutlined/> &nbsp;{t('dnsPage.title')}</>}
                extra={
                    isDnsCreator && (
                        <Button type='primary' onClick={handleCreate}>
                            <PlusCircleOutlined/> {t('dnsPage.create_button')}
                        </Button>
                    )
                }
            >
                <Table<DnsServiceModel>
                    dataSource={dataSource}
                    rowKey='id'
                    columns={columns}
                    loading={isLoading}
                    className='dnsServicesTable'
                    expandable={{
                        expandedRowRender,
                        expandRowByClick: true,
                        expandedRowClassName: (rec, index, indent) => 'expandedRow',
                        expandedRowKeys: expandedRowKeys
                    }}
                    onExpand={handleExpand}
                    onRow={(rec:DnsServiceModel, rowIndex) => {
                        if (expandedRowKeys && expandedRowKeys.includes(rec.id)) {
                            return { className: 'expandedRowBold' };
                        }
                        return { className: '' };
                    }}
                    showHeader={true}
                    size='small'
                    style={{whiteSpace: 'pre'}}
                    scroll={{ x: 680 }}
                    bordered={true}
                    pagination={false}
                />

            </Card>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp; {dataToUpdate ? <>{t('dnsPage.update_title')}</> : <>{t('dnsPage.create_title')}</>}
                       </div>
                   }
                destroyOnClose
                style={{top: 20}}
                width={600}
                visible={isDnsModalVisible}
                onCancel={() => setDnsModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                footer={null}
                confirmLoading={true}
            >
                <DnsServiceForm dataToUpdate={dataToUpdate} onFinish={onFinish}/>
            </Modal>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.create_zone_title')}
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={800}
                   visible={isZoneModalVisible}
                   onCancel={() => setZoneModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <DnsZoneForm serviceId={selectedDnsServiceId} setZoneModalVisible={setZoneModalVisible}/>
            </Modal>


            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.zone_file')}
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={800}
                   visible={isZoneExportModalVisible}
                   onCancel={() => setZoneExportModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <DnsZoneExport serviceId={selectedDnsServiceId} zone={selectedZone}/>
            </Modal>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.record')}&nbsp;{t('dnsPage.for_domain')}: <Tag color='blue' style={{fontSize: '1em'}}><b>{selectedRecord?.domain}</b></Tag>
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={800}
                   visible={isDnsRecordModalVisible}
                   onCancel={() => setDnsRecordModalVisible(false)}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <DnsRecordForm record={selectedRecord} closeModal={() => {setDnsRecordModalVisible(false)}}/>
            </Modal>


            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                           onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                           onMouseOut={() => {setDisabled(true)}}
                           onFocus={() => {}}
                           onBlur={() => {}}
                       >
                           <GlobalOutlined/> &nbsp;{t('dnsPage.record')}&nbsp;{t('dnsPage.for_domain')}: <Tag color='blue' style={{fontSize: '1em'}}><b>{selectedRecord?.domain}</b></Tag>
                       </div>
                   }
                   destroyOnClose
                   style={{top: 20}}
                   width={600}
                   visible={isSoaModalVisible}
                   onCancel={() => setSoaModalVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}
                   confirmLoading={true}
            >
                <SoaTtlForm record={selectedRecord} serviceId={selectedDnsServiceId} closeModal={() => {setSoaModalVisible(false)}}/>
            </Modal>

            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                     onMouseOut={() => {setDisabled(true)}}
                     onFocus={() => {}}
                     onBlur={() => {}}
                >
                    <><WarningOutlined />&nbsp;{t('dnsPage.check_zones')}..&nbsp; {dnsServices.find(s => s.id===selectedDnsServiceId)?.host}</>
                </div>
            }
                   destroyOnClose
                   style={{top: 50}}
                   bodyStyle={{minHeight: 450, height: 600, padding: 2}}
                   width='60%'
                   className='checkerModal'
                   visible={isCheckerModalVisible}
                   onCancel={() => setCheckerModalVisible(false)}
                   maskClosable={false}
                   modalRender={(modal) => (
                       <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
                   footer={null}>
                <DnsChecker serviceId={selectedDnsServiceId} />
            </Modal>

            <Modal title={
                       <div style={{width: '100%', cursor: 'move'}}
                            onMouseOver={() => {if (disabled) { setDisabled(false)}}}
                            onMouseOut={() => {setDisabled(true)}}
                            onFocus={() => {}}
                            onBlur={() => {}}
                       >
                           <><InfoCircleOutlined />&nbsp;{t('general.history')}: {historyTitle}</>
                       </div>
                   }
                   destroyOnClose
                style={{top: 50}}
                bodyStyle={{height: '60%', minHeight: 450, padding: 2}}
                width='60%'
                className='historyLogModal'
                visible={isHistoryModalVisible}
                onCancel={() => setHistoryModalVisible(false)}
                maskClosable={false}
                modalRender={(modal) => (
                    <Draggable disabled={disabled} bounds={bounds} onStart={(ev, data) => onStart(ev, data)}>
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                footer={null}>

                <HistoryLog isModal={true} showTitle={false} />
            </Modal>
        </>
    )
}

export default DnsServicePage
