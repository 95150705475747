import {
  GetDnsZonesRequestAction,
  GetDnsZonesResponseAction,
  GetDnsZonesErrorAction,
} from '.'
import { protectedApiClient } from 'helpers/api'
import { handleApiErrorWithNotification } from 'helpers/errorHandling'
import { DnsZone } from '../models'

export default (
    service_id: number,
  cb?: (isSuccess: boolean) => void
) => {
  return (
    dispatch: (
      arg:
        | GetDnsZonesRequestAction
        | GetDnsZonesResponseAction
        | GetDnsZonesErrorAction
    ) => void
  ) => {
    const request = new GetDnsZonesRequestAction(service_id)
    dispatch(request)
    protectedApiClient
      .get<DnsZone[]>(`/server/service/${service_id}/dns-zones`)
      .then((response) => {
        dispatch(new GetDnsZonesResponseAction(request, response.data))
        cb && cb(true)
      })
      .catch((error) => {
        dispatch(new GetDnsZonesErrorAction(request, error))
        handleApiErrorWithNotification(error)
        cb && cb(false)
      })
  }
}
